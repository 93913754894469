import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { changePersonalCreditInfoAction } from '../../../actions';
import { customerIsAllowed, GenericErrors, getAllSimulationsByUser, handleException } from '../../../api';
import { selectPersonalCredit } from '../../../store';
import { Home } from './styles';
import { CircularLoading } from '../../../components/Loading';
import { CustomerLoanResponse } from '../../../api/generated/personal-loans';
import { returnToNative } from '../../../utils';
import { SimulationStatusEnum } from '../../../models';

const EntryPointPersonalLoans = (): JSX.Element => {
  const { currentQuote } = useSelector(selectPersonalCredit);
  const dispatch = useDispatch();
  const history = useHistory();

  const checkForOpenedSimulations = () => {
    getAllSimulationsByUser()
      .then(async response => {
        const customerAllowed = response?.length > 0 || (await customerIsAllowed());
        const simulations = response?.filter(simulation => simulation?.status !== SimulationStatusEnum.DISAPPROVED) || [];
        const hasSomeCalculatedSimulation = simulations.some(quote => quote?.valueLoan);
        const { firstName, document } =
          simulations.find(({ customerLoan }) => customerLoan?.firstName)?.customerLoan || ({} as CustomerLoanResponse);
        dispatch(
          changePersonalCreditInfoAction({
            customer: {
              firstName,
              document,
            },
            quotes: simulations,
            currentQuote: {
              ...currentQuote,
              quoteOptions: {
                ...currentQuote.quoteOptions,
                customerAllowed,
              },
            },
          }),
        );
        if (simulations.length === 0) {
          history.push('/start');
        } else if (simulations?.length > 0 && hasSomeCalculatedSimulation) {
          history.push('/cvg/afterSales');
        } else {
          history.push('/start');
        }
      })
      .catch(reason => {
        const errorApi = handleException(reason);
        if (errorApi.errorCode === GenericErrors.NOT_ALLOWED.errorCode) {
          dispatch(
            changePersonalCreditInfoAction({
              currentQuote: {
                ...currentQuote,
                quoteOptions: {
                  ...currentQuote.quoteOptions,
                  customerAllowed: false,
                },
              },
            }),
          );
          history.push('/start');
        } else {
          setTimeout(() => {
            returnToNative();
          }, 2000);
        }
      });
  };

  useEffect(() => {
    checkForOpenedSimulations();
  }, []);

  return (
    <Home style={{ justifyContent: 'center' }}>
      <CircularLoading thickness={3} size={80} />
    </Home>
  );
};

export default EntryPointPersonalLoans;
