import { useCallback, useState } from 'react';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import theme from '../../styles/theme';

import Modal from '../Modal';
import { Container, DescriptionLine, Label } from './styles';

type Props = {
  action: () => void;
  description: string;
  label: string;
  children: JSX.Element | JSX.Element[];
  buttonConfirmText?: string;
  oneButton?: boolean;
  onOpen?: () => void;
};

const WhiteButtonDescriptionModal = ({
  action,
  description,
  label,
  children,
  buttonConfirmText,
  oneButton,
  onOpen,
}: Props): JSX.Element => {
  const [isOpened, setIsOpened] = useState<boolean>(false);

  const openModal = useCallback(() => setIsOpened(prev => !prev), []);

  const handleClick = e => {
    const ripples = document.createElement('span');
    const buttonPosition = e.target.getBoundingClientRect();
    const top = Math.abs(buttonPosition.top - e.clientY);
    const left = Math.abs(buttonPosition.left - e.clientX);

    ripples.style.top = `${top}px`;
    ripples.style.left = `${left}px`;

    e.target.appendChild(ripples);
    setTimeout(() => {
      if (onOpen) onOpen();
      ripples.remove();

      return openModal();
    }, 300);
  };

  return (
    <>
      <Container aria-hidden="true" onClick={handleClick}>
        <div>
          <Label>{label}</Label>
          <DescriptionLine>{description}</DescriptionLine>
        </div>
        <KeyboardArrowRightIcon style={{ color: theme.colors.blueTypo, pointerEvents: 'none' }} />
      </Container>

      <Modal
        open={isOpened}
        onClose={setIsOpened}
        buttonDisabled={false}
        twoButtons
        oneButton={oneButton}
        onClick={action}
        confirmLabel={buttonConfirmText}
      >
        <>{children}</>
      </Modal>
    </>
  );
};

export default WhiteButtonDescriptionModal;
