import { carShield2, handAboveCoin, hourglass, hourglassInvert, manShield, pigCoin } from '../../assets';

type Props = {
  icon: string;
  text: string;
};

export type LoadingProps = {
  props: Props[];
  totalProgress: number;
};

export const loadingSimulation: LoadingProps = {
  props: [
    { text: 'Analisando os dados do veículo...', icon: carShield2 },
    { text: 'Analisando seus dados...', icon: manShield },
    { text: 'Buscando a melhor oferta de crédito para você...', icon: handAboveCoin },
    { text: 'Pronto! agora é só simular o valor desejado', icon: pigCoin },
  ],
  totalProgress: 50,
};

export const loadingCredit: LoadingProps = {
  props: [
    { text: 'Já enviamos seu pedido, agora é só aguardar', icon: hourglass },
    { text: 'O Banco Votorantim está analisando sua solicitacão de crédito', icon: hourglassInvert },
    { text: 'Esse processo pode demorar até 3 minutos', icon: hourglass },
  ],
  totalProgress: 18,
};

export const loadingFixText: LoadingProps = {
  props: [
    { text: 'Aguarde, estamos processando o envio dos seus documentos', icon: hourglass },
    { text: 'Aguarde, estamos processando o envio dos seus documentos', icon: hourglassInvert },
    { text: 'Aguarde, estamos processando o envio dos seus documentos', icon: hourglass },
  ],
  totalProgress: 18,
};

export const loadingOfferCarousel: LoadingProps = {
  props: [
    { text: 'Estamos buscando os melhores planos para você', icon: hourglass },
    { text: 'Buscando a melhor oferta de seguro', icon: hourglassInvert },
    { text: 'Aguarde já estamos finalizando! Esse processo pode levar de 1 a 2 minutos', icon: hourglass },
  ],
  totalProgress: 20,
};

export const loadingPaymentCheckout: LoadingProps = {
  props: [
    { text: 'Estamos processando seus dados para pagamento', icon: hourglass },
    { text: 'Aguarde mais um pouco, já estamos finalizando', icon: hourglassInvert },
    { text: 'Esse processo pode demorar até 2 minutos', icon: hourglass },
  ],
  totalProgress: 20,
};
