import styled from 'styled-components/macro';

import { pxToRem } from '../../utils';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueLight};
  padding: ${pxToRem(24)} ${pxToRem(32)};
`;

export const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Title = styled.p`
  font-family: Nunito;
  font-weight: 600;
  font-style: normal;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(24)};

  color: ${({ theme }) => theme.colors.tokenColorBlue200};
  mix-blend-mode: normal;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Price = styled.p`
  font-family: Nunito;
  font-weight: 700;
  font-style: normal;
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(24)};
  color: ${({ theme }) => theme.colors.blueTypo};

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
