import styled from 'styled-components/macro';

import { pxToRem } from '../../utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  background-color: ${({ theme }) => theme.colors.white};

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const Body = styled.div<{ noPaddingX?: boolean }>`
  width: 100%;
  margin: auto;
  height: 85vh;
  overflow-y: auto;
  ${p => (p.noPaddingX ? 'padding: 0' : `padding: 0 ${pxToRem(32)}`)};

  &::-webkit-scrollbar {
    display: none;
  }
`;
