/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BankAccountRequest } from '../models/BankAccountRequest';
import type { BankAccountResponse } from '../models/BankAccountResponse';
import { request as __request } from '../core/request';

export class BankAccountControllerService {

    /**
     * createBankAccount
     * @param request request
     * @param simulationId simulationId
     * @param xCustomerUuid x-customer-uuid
     * @param acceptLanguage Accept-Language
     * @returns BankAccountResponse OK
     * @returns any Created
     * @throws ApiError
     */
    public static async createBankAccountUsingPost(
        request: BankAccountRequest,
        simulationId: string,
        xCustomerUuid: string,
        acceptLanguage?: string,
    ): Promise<BankAccountResponse | any> {
        const result = await __request({
            method: 'POST',
            path: `/api/personal_loans/create/bank_account/simulation/${simulationId}`,
            headers: {
                'x-customer-uuid': xCustomerUuid,
                'Accept-Language': acceptLanguage,
            },
            body: request,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

}