import { FipeControllerService, OpenAPI, ValueRequestBody } from './generated/fipe';
import { FipeDomain, ValueResponseBody } from './models';

import { Domain } from '../models';
import { capitalizeEveryFirstLetter, getAuthCookies } from '../utils';

const { getBrandsUsingPost, getModelsUsingPost, getYearModelUsingPost, getValueUsingPost } = FipeControllerService;

OpenAPI.BASE = `${process.env.REACT_APP_API_BASE_URL}/insurance`;
OpenAPI.HEADERS = getAuthCookies();

const idReferenceTable = 274;
const idVehicleTypeCar = 1;

const convertFipeDomain = (listDomain: FipeDomain[] = [], minLength?: number): Domain[] => {
  return listDomain.map(value => ({
    id: value.value,
    name: capitalizeEveryFirstLetter(value.label, minLength, true),
  }));
};

export const getBrands = async (): Promise<Domain[]> => {
  const response: FipeDomain[] = await getBrandsUsingPost({
    codigoTabelaReferencia: idReferenceTable,
    codigoTipoVeiculo: 1,
  });
  return convertFipeDomain(response, 3);
};

export const getModelsByBrand = async (idBrand: string): Promise<Domain[]> => {
  const response = await getModelsUsingPost({
    codigoTabelaReferencia: idReferenceTable,
    codigoTipoVeiculo: idVehicleTypeCar,
    codigoMarca: +idBrand,
  });
  return convertFipeDomain(response.models);
};

export const getYearByModel = async (idBrand: string, idModel: string): Promise<Domain[]> => {
  const response: FipeDomain[] = await getYearModelUsingPost({
    codigoTabelaReferencia: idReferenceTable,
    codigoTipoVeiculo: idVehicleTypeCar,
    codigoMarca: +idBrand,
    codigoModelo: +idModel,
  });
  const filteredResponse = response.filter(({ value }) => {
    const arr = value.match(/([0-9]{4,5})-[0-9]/);
    if (!arr) return true;

    const validYear = new Date().getFullYear() + 2;
    return Number(arr[1]) <= validYear;
  });
  return convertFipeDomain(filteredResponse);
};

export const getValueAndFipeByVehicle = async (body: ValueRequestBody): Promise<ValueResponseBody> => {
  return getValueUsingPost({
    codigoTabelaReferencia: idReferenceTable,
    codigoTipoVeiculo: idVehicleTypeCar,
    codigoTipoCombustivel: 1,
    tipoConsulta: 'tradicional',
    ...body,
  });
};
