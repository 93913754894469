import { combineReducers } from 'redux';
import insuranceReducer from './insuranceReducer';
import cepReducer from './cepReducer';
import stepReducer from '../store/Steps/Steps.reducer';
import CustomerCarReducer from './CustomerCarReducer';
import PersonalLoansReducer from './personalLoansReducer';
import userReducer from './userReducer';

export default combineReducers({
  // Common:
  step: stepReducer,
  cepReducer,
  user: userReducer,

  // Specifics:
  client: insuranceReducer,
  customerCar: CustomerCarReducer,
  personalCredit: PersonalLoansReducer,
});
