import { memo } from 'react';
import styled from 'styled-components/macro';

import Close from '../Close';

const ContainerError = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.blueStrong};
  color: ${({ theme }) => theme.colors.yellowStars};
`;

const TemporaryError = () => {
  return (
    <ContainerError>
      <Close />
      <h2>Está com erro isso aqui!</h2>
      <span>Mande um Pix que ficaremos felizes ;)</span>
    </ContainerError>
  );
};

export default memo(TemporaryError);
