import styled from 'styled-components/macro';

import { pxToRem } from '../../utils';

export const ContainerAuthentication = styled.div`
  width: 100%;
`;

export const TitleAuthentication = styled.h2`
  font-family: Nunito;
  font-weight: 400;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(19)};

  color: ${({ theme }) => theme.colors.blueTypo};
`;

export const ContentInputAuthentication = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: ${pxToRem(16)} 0 ${pxToRem(8)};
`;

export const InputAuthentication = styled.input.attrs({
  type: 'text',
  // inputMode: 'numeric',
  maxLength: 1,
  mozactionhint: 'next',
})<{ error: boolean }>`
  width: ${pxToRem(40)};
  height: ${pxToRem(40)};

  font-family: Nunito;
  font-weight: 700;
  font-size: ${pxToRem(24)};
  line-height: ${pxToRem(33)};
  text-align: center;

  color: ${({ theme }) => theme.colors.blueTypo};
  border: 1px solid ${({ theme, error }) => (error ? theme.colors.red : `${theme.colors.blueTypo}33`)};
  border-radius: ${pxToRem(4)};

  padding: ${pxToRem(6)} ${pxToRem(12)};
`;

export const HelperAuthentication = styled.span`
  font-family: Nunito;
  font-weight: 600;
  font-size: ${pxToRem(12)};
  line-height: ${pxToRem(16)};

  color: ${({ theme }) => theme.colors.blueTypo};
  opacity: 0.5;
`;
