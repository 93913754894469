import { useCallback, useEffect, useState } from 'react';

import Modal from '../Modal';
import { formatNumber, isEmpty, isValidDate } from '../../utils';

import {
  Answer,
  AnswerFormatted,
  ButtonInputCover,
  CleanInput,
  Container,
  ContentInput,
  ContentModal,
  ExclamationTriangle,
  HelperText,
  Input,
  InputFormatted,
  InputMasked,
  Question,
  Title,
} from './styles';

type Props = {
  action: (value) => void;
  actionEvent?: () => void;
  onClickEvent?: () => void;
  tagAction?: () => void;
  tagModalShowAction?: () => void;
  modalClickAction?: () => void;
  answer: any;
  buttonLabel: string;
  placeholder: string;
  question: string;
  typeMask?: 'normalText' | 'numberMasked' | 'textMasked' | 'dateMasked' | 'cpfNewCarDriverMasked';
  inputFormat?: string;
  inputMask?: string;
  titleModal?: string;
  minLength?: number;
  maxLength?: number;
  prefix?: string;
  noModal?: boolean;
  helperText?: string;
  small?: boolean;
  upper?: boolean;
  afterSales?: boolean;
  placeholderModal?: string;
  disabled?: boolean;
  isValidFn?: (value: string) => boolean;
  invalidText?: string;
  transformTextFn?: (value: string) => string;
};

const InputCover = ({
  action,
  actionEvent,
  onClickEvent,
  tagAction,
  tagModalShowAction,
  modalClickAction,
  answer,
  buttonLabel,
  placeholder,
  question,
  typeMask = 'normalText',
  inputFormat,
  inputMask,
  titleModal,
  minLength = 1,
  maxLength,
  prefix,
  noModal,
  helperText,
  small = false,
  upper = false,
  afterSales,
  placeholderModal,
  disabled,
  isValidFn,
  invalidText,
  transformTextFn,
}: Props): JSX.Element => {
  const [showModal, setShowModal] = useState(false);
  const [inputValue, setInputValue] = useState(answer === '-' ? '' : answer);
  const [errorMessage, setErrorMessage] = useState<string | undefined>('');

  const stateIsEmpty = isEmpty(inputValue);
  const [isDisabled, setIsDisabled] = useState(!inputValue);

  const openModal = useCallback(() => {
    if (tagAction && !showModal) {
      tagAction();
    }
    if (!showModal && tagModalShowAction) {
      tagModalShowAction();
    }
    setShowModal(prev => !prev);
  }, []);

  const cleanState = useCallback(() => {
    setInputValue('');
  }, [action]);

  const handleKeyPress = event => {
    if (isDisabled) return;

    if (event.key === 'Enter' || event.which === 13) {
      setShowModal(false);
      action(inputValue);
    }
  };

  const handleChange = useCallback(
    value => {
      if (!value) {
        setInputValue(value);
        return;
      }

      if (maxLength && typeMask === 'normalText') {
        const valueFormatted = transformTextFn ? transformTextFn(value) : value;
        setInputValue(valueFormatted.substring(0, maxLength));
        return;
      }

      const [integer, decimal] = value.toString().split('.');
      if (maxLength && typeMask === 'numberMasked' && (integer.length > maxLength || decimal?.length > 2)) {
        setInputValue(`${integer.substring(0, maxLength)}.${decimal ? decimal.substring(0, 2) : '00'}`);
        return;
      }

      setInputValue(value);
    },
    [transformTextFn, maxLength],
  );

  const handleClick = e => {
    if (answer === '' || undefined) cleanState();
    const ripples = document.createElement('span');
    const buttonPosition = e.target.getBoundingClientRect();
    const top = Math.abs(buttonPosition.top - e.clientY);
    const left = Math.abs(buttonPosition.left - e.clientX);

    ripples.style.top = `${top}px`;
    ripples.style.left = `${left}px`;

    e.target.appendChild(ripples);

    setTimeout(() => {
      ripples.remove();

      if (noModal) return action(undefined);
      if (actionEvent) {
        return actionEvent();
      }
      if (onClickEvent) {
        return onClickEvent();
      }
      return openModal();
    }, 300);
  };

  useEffect(() => {
    if (typeMask === 'dateMasked') {
      const dateValidation = isValidDate(inputValue);
      const dateCustomValidation = isValidFn ? isValidFn(inputValue) : true;
      if (isValidFn) {
        setIsDisabled(!dateCustomValidation);
      } else {
        setIsDisabled(!dateValidation);
      }
      if (!dateValidation && inputValue?.trim().length === 10) {
        setErrorMessage('Data informada está inválida');
      } else if (dateValidation && !dateCustomValidation) {
        setErrorMessage(invalidText);
      } else {
        setErrorMessage(undefined);
      }
    } else if (isValidFn !== undefined) {
      const isValid: boolean = isValidFn(inputValue);
      setIsDisabled(!isValid);
      if (invalidText && inputValue?.trim().length === minLength) {
        setErrorMessage(isValid ? undefined : invalidText);
      } else {
        setErrorMessage(undefined);
      }
    } else {
      if (typeof inputValue === 'number') setIsDisabled(inputValue === 0);
      if (typeof inputValue === 'string') setIsDisabled(inputValue?.trim().length === 0);
    }
  }, [inputValue]);

  return (
    <>
      <Container>
        <div>
          <Question afterSales={afterSales}>{question}</Question>
          {(typeMask !== 'numberMasked' || stateIsEmpty) && (
            <Answer place={!answer ? placeholder : ''}>{answer || placeholder}</Answer>
          )}
          {typeMask === 'numberMasked' && prefix && !stateIsEmpty && !!answer && (
            <Answer>{formatNumber.currency(answer) || placeholder}</Answer>
          )}
          {typeMask === 'numberMasked' && !stateIsEmpty && !prefix && (
            <AnswerFormatted format={inputFormat} mask={inputMask} value={answer} placeholder={placeholder} />
          )}
        </div>
        <ButtonInputCover disabled={disabled} onClick={handleClick}>
          {answer && answer !== '-' && !afterSales ? 'Editar' : buttonLabel}
        </ButtonInputCover>
      </Container>
      <Modal
        open={showModal}
        onClose={setShowModal}
        buttonDisabled={isDisabled}
        onClick={() => (modalClickAction ? modalClickAction() : action(inputValue))}
      >
        <ContentModal>
          <Title>{titleModal || question}</Title>
          <ContentInput error={!!errorMessage}>
            {typeMask === 'normalText' && (
              <Input
                type="text"
                value={inputValue}
                placeholder={placeholderModal || placeholder}
                autoFocus
                maxLength={maxLength}
                onChange={e => handleChange(upper ? e.target.value.toUpperCase() : e.target.value)}
                onKeyDown={handleKeyPress}
                autoCapitalize="words"
                onBlur={evt => evt.target.focus()}
              />
            )}

            {typeMask === 'numberMasked' && (
              <InputFormatted
                displayType="input"
                format={inputFormat}
                mask={inputMask}
                value={Number(inputValue) || ''}
                placeholder={placeholderModal || placeholder}
                onValueChange={e => (prefix ? handleChange(e.floatValue) : handleChange(e.value))}
                autoFocus
                // maxLength={maxLength}
                onKeyDown={handleKeyPress}
                prefix={prefix}
                thousandSeparator={prefix ? '.' : false}
                decimalSeparator=","
                inputMode="numeric"
                onBlur={evt => evt.target.focus()}
              />
            )}

            {typeMask === 'cpfNewCarDriverMasked' && (
              <InputFormatted
                displayType="input"
                format={inputFormat}
                mask={inputMask}
                value={inputValue}
                placeholder={placeholderModal || placeholder}
                onValueChange={e => (prefix ? handleChange(e.floatValue) : handleChange(e.value))}
                autoFocus
                // maxLength={maxLength}
                onKeyDown={handleKeyPress}
                prefix={prefix}
                thousandSeparator={prefix ? '.' : false}
                decimalSeparator=","
                inputMode="numeric"
                onBlur={evt => evt.target.focus()}
              />
            )}

            {typeMask === 'textMasked' && (
              <InputMasked
                mask={inputFormat || 'Preencha a máscara de forma correta'}
                value={inputValue}
                maskPlaceholder={inputMask || ''}
                placeholder={placeholderModal || placeholder}
                onChange={e => handleChange(e.target.value.toUpperCase())}
                onKeyDown={handleKeyPress}
                autoFocus
                onBlur={evt => evt.target.focus()}
              />
            )}

            {typeMask === 'dateMasked' && (
              <InputFormatted
                displayType="input"
                format="##/##/####"
                value={inputValue || ''}
                placeholder={placeholderModal || placeholder}
                onValueChange={e => handleChange(e.formattedValue)}
                autoFocus
                onKeyDown={handleKeyPress}
                inputMode="numeric"
                type="tel"
                onBlur={evt => evt.target.focus()}
              />
            )}

            {errorMessage ? <ExclamationTriangle onClick={cleanState} /> : !stateIsEmpty && <CleanInput onClick={cleanState} />}
          </ContentInput>

          {errorMessage ? (
            <HelperText small error>
              {typeMask === 'dateMasked' ? errorMessage : invalidText || errorMessage}
            </HelperText>
          ) : (
            helperText && <HelperText small={small}>{helperText}</HelperText>
          )}
        </ContentModal>
      </Modal>
    </>
  );
};

export default InputCover;
