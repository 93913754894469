import styled, { keyframes } from 'styled-components/macro';
import { Button } from 'react-bootstrap';

import { SubTitle } from '../Modal/StaticModal/styles';
import { pxToRem } from '../../utils';
import { FormTitle } from '../styles';

const animate = keyframes`
  0% {
    width: 0px;
    height: 0px;
    opacity: 0.5;
  }
  100% {
    width: ${pxToRem(500)};
    height: ${pxToRem(500)};
    opacity: 0;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueLight};
  padding: ${pxToRem(20)} ${pxToRem(32)};
`;

export const TitleModal = styled(FormTitle)`
  margin: ${pxToRem(16)} 0 ${pxToRem(32)};
  line-height: ${pxToRem(32)};
`;

export const SubtitleModal = styled(SubTitle)`
  margin: 0;
`;

export const CoverageValue = styled.p`
  font-family: Nunito;
  font-size: ${pxToRem(24)};
  font-weight: 700;
  line-height: ${pxToRem(33)};
  color: ${({ theme }) => theme.colors.blueStrong};
  text-align: left;
  width: 100%;
`;

export const CoverageValueLabel = styled(SubTitle)`
  text-align: left;
  margin: ${pxToRem(32)} 0 ${pxToRem(16)};
`;

export const SliderWrapper = styled.div`
  width: 100%;
`;

export const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TittleTagWrapper = styled.div`
  display: inline-flex;
  margin-bottom: ${pxToRem(4)};
`;

export const TagWrapper = styled.div`
  margin-left: ${pxToRem(6)};
  font-size: ${pxToRem(12)};
`;

export const Description = styled.p`
  font-family: Nunito;
  font-weight: 400;
  font-style: normal;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(19)};
  color: ${({ theme }) => theme.colors.blueTypo};
  margin-bottom: 0;
`;
export const SeeMore = styled(Description)`
  font-weight: 700;
  margin-top: ${pxToRem(6)};
  color: ${({ theme }) => theme.colors.blueStrong};
`;

export const TitleCoverage = styled.p`
  font-family: Nunito;
  font-weight: 700;
  font-style: normal;
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(24)};
  color: ${({ theme }) => theme.colors.blueTypo};
  margin-bottom: 0;
`;

export const ButtonInputCover = styled(Button).attrs({
  bsPrefix: 'semBtn',
})`
  font-family: Nunito;
  font-weight: 700;
  font-style: normal;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(19)};

  color: ${({ theme }) => theme.colors.blueStrong};
  background-color: white;

  min-width: ${pxToRem(105)};
  height: ${pxToRem(40)};
  border: 2px solid ${({ theme }) => theme.colors.blueLight};
  box-sizing: border-box;
  border-radius: ${pxToRem(16)};
  overflow: hidden;
  position: relative;

  span {
    position: absolute;
    background-color: #000;
    transform: translate(-50%, -50%);
    pointer-events: none;
    border-radius: 50%;
    animation: ${animate} 1s linear infinite;
  }
`;

export const ContentModal = styled.div`
  width: 100%;
  height: ${pxToRem(98)};

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Input = styled.input`
  width: 100%;

  font-family: Nunito;
  font-weight: 700;
  font-style: normal;
  font-size: ${pxToRem(24)};
  line-height: ${pxToRem(33)};

  color: ${({ theme }) => theme.colors.blueTypo};

  border: none;
  border-bottom: 2px solid ${({ theme }) => theme.colors.blueTypo}33;

  ::placeholder {
    color: ${({ theme }) => theme.colors.blueGrayLight};
  }

  &:focus {
    outline: none;
  }
`;

export const WarningInstallments = styled.div`
  background-color: rgba(255, 96, 0, 0.1);
  border: none;
  color: #ff6000;
  padding: 8px 16px;
  align-self: start;
  text-align: center;
  border-radius: 16px;
`;
