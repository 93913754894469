import { normalizeString } from './string';

export const filterBySeparateWords = (input: string, itemDescription: string): boolean => {
  const normalizedInput = normalizeString(input);
  const normalizedItemDescription = normalizeString(itemDescription);
  const words = normalizedInput.split(' ');
  const searchArg = words.length === 1 ? words[0] : `${words.join('.*')}|${words.reverse().join('.*')}`;
  return new RegExp(searchArg, 'i').test(normalizedItemDescription);
};

export const filterByFullString = (input: string, itemDescription: string): boolean => {
  const normalizedInput = normalizeString(input);
  const normalizedItemDescription = normalizeString(itemDescription);
  return normalizedItemDescription.includes(normalizedInput);
};

export const sortArray = (array: any[] | undefined, field: string, direction: 'ASC' | 'DESC'): any[] => {
  if (!array) {
    return [];
  }
  return array.sort((a, b) => {
    if (a[field] < b[field]) {
      return direction === 'ASC' ? -1 : 1;
    }
    if (a[field] > b[field]) {
      return direction === 'ASC' ? 1 : -1;
    }
    return 0;
  });
};

export const sortArrayWithNumbers = (array: any[], field: string, maxLength: number): any[] => {
  const regex = /\D+/g;
  return array.sort((a, b) => {
    const aValue = `${a[field].replace(regex, '')}`.padStart(maxLength, '0');
    const bValue = `${b[field].replace(regex, '')}`.padStart(maxLength, '0');

    if (aValue < bValue) {
      return -1;
    }
    return aValue > bValue ? 1 : 0;
  });
};

export const humanFormatedJoin = (array: any[] | undefined, property?: string, separator = ', ', lastSeparator = 'e'): string => {
  if (!array || array.length === 0) return '';
  const revisedArray = property ? array.map(item => item[property]) : array;
  const joinArray = revisedArray?.join(separator) || '';
  const regex = new RegExp(`${separator}([^,]*)$`);
  return joinArray.replace(regex, ` ${lastSeparator} $1`);
};

export const pluralSuffix = (array: any[]): string => {
  if (!array || array.length < 2) return '';
  return 's';
};
