import styled, { keyframes } from 'styled-components/macro';

import { ReactComponent as CardAtmSVG } from '../../assets/icons/insurance/payment/cardAtm.svg';
import { pxToRem } from '../../utils';

const animate = keyframes`
  0% {
    width: 0px;
    height: 0px;
    opacity: 0.5;
  }
  100% {
    width: ${pxToRem(500)};
    height: ${pxToRem(500)};
    opacity: 0;
  }
`;

export const Container = styled.button<{
  icon?: boolean;
  noPaddingX?: boolean;
}>`
  width: 100%;
  max-height: ${pxToRem(112)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueLight};
  padding: ${pxToRem(24)} ${({ noPaddingX }) => pxToRem(noPaddingX ? 0 : 32)};

  overflow: hidden;
  position: relative;

  img {
    width: ${pxToRem(24)};
    height: ${pxToRem(24)};
    pointer-events: none;
  }

  div {
    width: ${({ icon }) => pxToRem(icon ? 215 : 280)};
    pointer-events: none;
  }

  span {
    position: absolute;
    background-color: #000;
    transform: translate(-50%, -50%);
    pointer-events: none;
    border-radius: 50%;
    animation: ${animate} 1s linear infinite;
  }
`;

export const Label = styled.p`
  font-family: Nunito;
  font-weight: 600;
  font-style: normal;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(24)};
  text-align: left;

  color: ${({ theme }) => theme.colors.tokenColorBlue200};

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const DescriptionLine = styled(Label)`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.blueTypo};
`;

export const DescriptionLineAlert = styled(DescriptionLine)`
  font-weight: 700;
  font-size: ${pxToRem(16)};
  color: ${({ theme }) => theme.colors.blueStrong};
`;

export const CardAtm = styled(CardAtmSVG).attrs({
  width: pxToRem(24),
  height: pxToRem(24),
})`
  path {
    fill: ${({ theme }) => theme.colors.blueStrong};
  }
`;
