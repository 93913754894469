import AbsoluteWrapper from '../AbsoluteWrapper';
import { Card, Container } from './styles';

interface DefaultViewInsuranceProps {
  children: JSX.Element;
}

const DefaultViewInsurance = ({ children }: DefaultViewInsuranceProps): JSX.Element => (
  <AbsoluteWrapper>
    <Container>
      <Card>{children}</Card>
    </Container>
  </AbsoluteWrapper>
);

export default DefaultViewInsurance;
