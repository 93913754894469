import styled from 'styled-components/macro';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.blueStrong};
  min-height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: none;
`;

export const Card = styled.div`
  h3 {
    color: ${({ theme }) => theme.colors.white};
  }
`;
