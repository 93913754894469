import { Offer } from '../../models/insurance/offer';
import Mapfre from '../../assets/icons/insurance/brands/mapfre.svg';
import TokyoMarine from '../../assets/icons/insurance/brands/tokyo-marine.svg';

export const OffersMockFullCoverage: Offer[] = [
  {
    company: 'Mapfre',
    image: Mapfre,
    planName: { id: 'full', name: 'Plano completo até' },
    cupom: 1500,
    offerValue: 3944.17,
    iofValue: 167.98,
    validityBegin: '15/08/2021',
    validityEnd: '14/08/2022',
    installmentsNumber: 10,
    installmentsValue: 244.41,
    paymentOptions: [],
    coverages: [
      {
        price: 64000,
        description: 'para colisão',
      },
      {
        price: 5000,
        description: 'para danos morais',
      },
      {
        price: 60000,
        description: 'em danos materiais',
      },
      {
        price: 100000,
        description: 'em danos corporais',
      },
    ],
  },
  {
    company: 'TokyoMarine',
    image: TokyoMarine,
    planName: { id: 'full', name: 'Plano completo até' },
    cupom: 1500,
    offerValue: 3944.17,
    iofValue: 167.98,
    validityBegin: '15/08/2021',
    validityEnd: '14/08/2022',
    installmentsNumber: 8,
    installmentsValue: 244.41,
    paymentOptions: [],
    coverages: [
      {
        price: 64000,
        description: 'para colisão',
      },
      {
        price: 5000,
        description: 'para danos morais',
      },
      {
        price: 60000,
        description: 'em danos materiais',
      },
      {
        price: 100000,
        description: 'em danos corporais',
      },
    ],
  },
];

export const OffersMockBasicCoverage: Offer[] = [
  {
    company: 'Mapfre',
    image: Mapfre,
    planName: { id: 'basic', name: 'Plano básico até' },
    cupom: 1500,
    offerValue: 3944.17,
    iofValue: 167.98,
    validityBegin: '15/08/2021',
    validityEnd: '14/08/2022',
    installmentsNumber: 12,
    installmentsValue: 244.41,
    paymentOptions: [],
    coverages: [
      {
        price: 64000,
        description: 'para colisão',
      },
      {
        price: 5000,
        description: 'para danos morais',
      },
      {
        price: 60000,
        description: 'em danos materiais',
      },
      {
        price: 100000,
        description: 'em danos corporais',
      },
    ],
  },
  {
    company: 'TokyoMarine',
    image: TokyoMarine,
    planName: { id: 'basic', name: 'Plano básico até' },
    cupom: 1500,
    offerValue: 3944.17,
    iofValue: 167.98,
    validityBegin: '15/08/2021',
    validityEnd: '14/08/2022',
    installmentsNumber: 9,
    installmentsValue: 244.41,
    paymentOptions: [],
    coverages: [
      {
        price: 64000,
        description: 'para colisão',
      },
      {
        price: 5000,
        description: 'para danos morais',
      },
      {
        price: 60000,
        description: 'em danos materiais',
      },
      {
        price: 100000,
        description: 'em danos corporais',
      },
    ],
  },
];

export const OffersMock: Array<Offer> = [
  {
    contractUuid: '2b9bf9cb-4231-4263-8b4f-acbcc7b2034a',
    company: 'MAPFRE Seguros',
    image: 'https://mapfre.jpg',
    planName: { id: 'basic', name: 'basic' },
    coverages: [
      {
        insuredAmount: 46752,
        basic: true,
        premium: 1914.88,
        uuid: '62857eb2-53f9-4816-b69e-44b3cb91b7dc',
        description: 'Casco - Colisão, Incêndio, Roubo e Furto',
        fipeText: '100% da FIPE',
      },
      {
        insuredAmount: 5000,
        basic: true,
        premium: 12.23,
        uuid: '829e4248-1293-47a5-ba09-230bffffd456',
        description: 'RCF - Danos Morais / Estéticos',
      },
      {
        insuredAmount: 50000,
        basic: true,
        premium: 523.86,
        uuid: '2987967f-89da-4569-8531-9b76b7939fe0',
        description: 'RCF - Danos Materiais',
      },
      {
        insuredAmount: 100000,
        basic: true,
        premium: 68.8,
        uuid: '4d22effa-627f-4cc8-915a-1aff0d78df63',
        description: 'RCF - Danos Corporais',
      },
      {
        insuredAmount: 100000,
        basic: false,
        premium: 1000,
        uuid: 'f48fe585-e82e-47cb-9575-c637bac02275',
        description: 'RCF - Objetos Transportados',
      },
      {
        insuredAmount: 0,
        basic: false,
        premium: 0,
        uuid: 'dfbbea3c-2c86-4f0d-b43e-e88ec24ab548',
        description: 'Assistência AutoMais 250 km',
      },
      {
        insuredAmount: 0,
        basic: false,
        premium: 0,
        uuid: '951aef2f-1692-4bf1-8df4-631a4bd52ea6',
        description: 'Assistência básica',
      },
    ],
    paymentOptions: [
      {
        installments: 12,
        fees: 0,
        feesPercents: 0,
        idMethodPaymentCompany: 21,
        idOptionPayment: 19,
        iof: 185.96,
        iofPercents: 0.07,
        methodDetails: '',
        paymentMethod: 'credit_card',
        periodicityCharges: 'mensal',
        recurrent: 1,
        valueInstallments: 270.6,
        valueTotal: 3247.2,
        variant: 'padrao',
      },
    ],
  },
  {
    contractUuid: 'f22a0e8a-1f4e-4365-8699-e65924c60ecd',
    company: 'MAPFRE Seguros',
    image: 'https://mapfre.jpg',
    planName: { id: 'full', name: 'Plano completo até' },
    coverages: [
      {
        insuredAmount: 46752,
        basic: true,
        premium: 1914.88,
        uuid: '62857eb2-53f9-4816-b69e-44b3cb91b7dc',
        description: 'Casco - Colisão, Incêndio, Roubo e Furto',
        fipeText: '100% da FIPE',
      },
      {
        insuredAmount: 5000,
        basic: true,
        premium: 12.23,
        uuid: '829e4248-1293-47a5-ba09-230bffffd456',
        description: 'RCF - Danos Morais / Estéticos',
      },
      {
        insuredAmount: 50000,
        basic: true,
        premium: 523.86,
        uuid: '2987967f-89da-4569-8531-9b76b7939fe0',
        description: 'RCF - Danos Materiais',
      },
      {
        insuredAmount: 100000,
        basic: true,
        premium: 68.8,
        uuid: '4d22effa-627f-4cc8-915a-1aff0d78df63',
        description: 'RCF - Danos Corporais',
      },
      {
        insuredAmount: 0,
        basic: true,
        premium: 0,
        uuid: 'f48fe585-e82e-47cb-9575-c637bac02275',
        description: 'RCF - Objetos Transportados',
      },
      {
        insuredAmount: 0,
        basic: false,
        premium: 0,
        uuid: 'dfbbea3c-2c86-4f0d-b43e-e88ec24ab548',
        description: 'Assistência AutoMais 250 km',
      },
      {
        insuredAmount: 0,
        basic: false,
        premium: 0,
        uuid: '951aef2f-1692-4bf1-8df4-631a4bd52ea6',
        description: 'Assistência básica',
      },
    ],
    paymentOptions: [
      {
        installments: 10,
        fees: 0,
        feesPercents: 0,
        idMethodPaymentCompany: 21,
        idOptionPayment: 19,
        iof: 185.96,
        iofPercents: 0.07,
        methodDetails: '',
        paymentMethod: 'credit_card',
        periodicityCharges: 'mensal',
        recurrent: 1,
        valueInstallments: 270.573,
        valueTotal: 2705.73,
        variant: 'padrao',
      },
    ],
  },
];
