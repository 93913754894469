import ReactDOM from 'react-dom';

interface PortalProps {
  children: JSX.Element;
}

const PortalModal = ({ children }: PortalProps): JSX.Element | null => {
  const portal = document.getElementById('modal-root');

  return portal && ReactDOM.createPortal(children, portal);
};

export default PortalModal;
