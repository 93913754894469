import { memo } from 'react';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import theme from '../../styles/theme';
import { CardAtm, Container, DescriptionLine, DescriptionLineAlert, Label } from './styles';

type Props = {
  action: () => void;
  description: string[];
  label: string;
  icon?: string;
  noPaddingX?: boolean;
};

const WhiteButtonPayment = ({ action, description, label, icon, noPaddingX = false }: Props): JSX.Element => {
  const handleClick = e => {
    const ripples = document.createElement('span');
    const buttonPosition = e.target.getBoundingClientRect();
    const top = Math.abs(buttonPosition.top - e.clientY);
    const left = Math.abs(buttonPosition.left - e.clientX);

    ripples.style.top = `${top}px`;
    ripples.style.left = `${left}px`;

    e.target.appendChild(ripples);

    setTimeout(() => {
      ripples.remove();

      return action();
    }, 300);
  };

  const lines = description.map((line, index) => {
    if (index === 0) {
      return <DescriptionLineAlert key={`line-${String(index)}-${line.substring(0, 5)}`}>{line}</DescriptionLineAlert>;
    }

    return <DescriptionLine key={`line-${String(index)}-${line.substring(0, 5)}`}>{line}</DescriptionLine>;
  });

  return (
    <Container aria-hidden="true" onClick={handleClick} icon={!!icon} noPaddingX={noPaddingX}>
      {icon?.includes('cardAtm') ? (
        <CardAtm aria-label="icon from button" />
      ) : (
        <img src={icon} alt="icon from button" loading="lazy" />
      )}
      <div>
        <Label>{label}</Label>
        {lines}
      </div>
      <KeyboardArrowRightIcon style={{ color: theme.colors.blueTypo }} />
    </Container>
  );
};

export default memo(WhiteButtonPayment);
