import NumberFormat, { NumberFormatProps } from 'react-number-format';

interface Props {
  value?: number;
}

const NumberFormatTyped = NumberFormat as unknown as (props: NumberFormatProps) => JSX.Element;

const Currency = ({ value = 0 }: Props): JSX.Element => (
  <NumberFormatTyped
    value={value}
    displayType="text"
    prefix="R$ "
    thousandSeparator="."
    decimalSeparator=","
    decimalScale={2}
    fixedDecimalScale
  />
);

export default Currency;
