/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AcceptLoanRequest } from '../models/AcceptLoanRequest';
import type { AcceptLoanResponse } from '../models/AcceptLoanResponse';
import { request as __request } from '../core/request';

export class AcceptLoanControllerService {

    /**
     * save
     * @param product product
     * @param request request
     * @param simulationQuoteId simulationQuoteId
     * @param xCustomerUuid x-customer-uuid
     * @param acceptLanguage Accept-Language
     * @returns AcceptLoanResponse OK
     * @returns any Created
     * @throws ApiError
     */
    public static async saveUsingPost(
        product: 'CP' | 'CVG',
        request: AcceptLoanRequest,
        simulationQuoteId: string,
        xCustomerUuid: string,
        acceptLanguage?: string,
    ): Promise<AcceptLoanResponse | any> {
        const result = await __request({
            method: 'POST',
            path: `/api/personal_loans/create/accept-loan/product/${product}/${simulationQuoteId}`,
            headers: {
                'x-customer-uuid': xCustomerUuid,
                'Accept-Language': acceptLanguage,
            },
            body: request,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * update
     * @param product product
     * @param request request
     * @param simulationQuoteId simulationQuoteId
     * @param xCustomerUuid x-customer-uuid
     * @param acceptLanguage Accept-Language
     * @returns AcceptLoanResponse OK
     * @returns any Created
     * @throws ApiError
     */
    public static async updateUsingPut(
        product: 'CP' | 'CVG',
        request: AcceptLoanRequest,
        simulationQuoteId: string,
        xCustomerUuid: string,
        acceptLanguage?: string,
    ): Promise<AcceptLoanResponse | any> {
        const result = await __request({
            method: 'PUT',
            path: `/api/personal_loans/create/accept-loan/product/${product}/${simulationQuoteId}`,
            headers: {
                'x-customer-uuid': xCustomerUuid,
                'Accept-Language': acceptLanguage,
            },
            body: request,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

}