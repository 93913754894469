import { memo } from 'react';
import { Checkbox } from '@material-ui/core';
import { Container, Label } from './styles';

type CheckboxInsuranceProps = {
  label: string;
  value: boolean;
  handleChange: (newValue) => void;
  styleClass?: 'alertLabel';
  disabled?: boolean;
  haveToInvert?: boolean;
};

const CheckboxInsurance = (props: CheckboxInsuranceProps): JSX.Element => {
  const { handleChange, label, value, disabled = false, styleClass, haveToInvert = false } = props;
  return (
    <Container aria-hidden="true" onClick={handleChange} disabled={disabled} className={haveToInvert ? 'haveToInvert' : ''}>
      {!haveToInvert && <Label className={`${styleClass}${disabled && ' disabled'}`}>{label}</Label>}
      <Checkbox checked={value} disabled={disabled} color={disabled ? 'primary' : 'secondary'} />
      {haveToInvert && <Label className={`${styleClass}${disabled && ' disabled'}`}>{label}</Label>}
    </Container>
  );
};

export default memo(CheckboxInsurance);
