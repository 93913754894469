/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { request as __request } from '../core/request';

export class RiskItemReadControllerService {

    /**
     * @param xCustomerUuid
     * @param licensePlate
     * @param acceptLanguage
     * @returns any OK
     * @throws ApiError
     */
    public static async findVehicleByVLicense(
        xCustomerUuid: string,
        licensePlate: string,
        acceptLanguage?: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/insurance/risk_item/${licensePlate}`,
            headers: {
                'x-customer-uuid': xCustomerUuid,
                'Accept-Language': acceptLanguage,
            },
        });
        return result.body;
    }

    /**
     * @param xCustomerUuid
     * @param licensePlate
     * @param insuranceCompany
     * @param acceptLanguage
     * @returns any OK
     * @throws ApiError
     */
    public static async findCoveragesRiskItem(
        xCustomerUuid: string,
        licensePlate: string,
        insuranceCompany: string,
        acceptLanguage?: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/insurance/risk_item/coverage/${licensePlate}/${insuranceCompany}`,
            headers: {
                'x-customer-uuid': xCustomerUuid,
                'Accept-Language': acceptLanguage,
            },
        });
        return result.body;
    }

}