import styled from 'styled-components/macro';
import { pxToRem } from '../../utils';

export const Container = styled.div`
  margin-top: 10%;

  @media (max-width: 360px) {
    margin-top: 0;
  }
`;

export const Col = styled.div`
  display: block;
  margin: 0 ${pxToRem(32)};
`;

export const ImageDiv = styled.div`
  p {
    font-weight: 700;
    padding-bottom: 0.5rem;
    text-align: center;
  }
`;

export const ImageSlide = styled.img`
  height: ${pxToRem(110)};
  max-width: fit-content;
  margin-bottom: ${pxToRem(32)};
`;

export const Title = styled.h3`
  font: bold ${pxToRem(24)} / ${pxToRem(32)} Nunito;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  margin-bottom: ${pxToRem(16)};

  &:first-letter {
    text-transform: uppercase;
  }
`;

export const Subtitle = styled.p`
  font: normal ${pxToRem(16)} / ${pxToRem(22)} Nunito;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  margin-bottom: ${pxToRem(70)};
  white-space: pre-wrap;
  min-height: ${pxToRem(66)};
`;
