import { memo } from 'react';

import { AnswerIcon, ButtonInputCover, Container, QuestionIcon } from './styles';

type Props = {
  action: () => void;
  buttonLabel: string;
  placeholder: string;
  question: string;
  icon: string;
};

const InputCoverIcon = ({ action, buttonLabel, placeholder, question, icon }: Props): JSX.Element => {
  const handleClick = e => {
    const ripples = document.createElement('span');
    const buttonPosition = e.target.getBoundingClientRect();
    const top = Math.abs(buttonPosition.top - e.clientY);
    const left = Math.abs(buttonPosition.left - e.clientX);

    ripples.style.top = `${top}px`;
    ripples.style.left = `${left}px`;

    e.target.appendChild(ripples);

    setTimeout(() => {
      ripples.remove();

      return action();
    }, 300);
  };

  return (
    <Container>
      <div>
        <span>
          <img src={icon} alt="Ícone correspondente à execução" loading="lazy" />
        </span>
        <div>
          <QuestionIcon>{question}</QuestionIcon>
          <AnswerIcon>{placeholder}</AnswerIcon>
        </div>
      </div>
      <ButtonInputCover onClick={handleClick}>{buttonLabel}</ButtonInputCover>
    </Container>
  );
};

export default memo(InputCoverIcon);
