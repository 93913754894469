import { ErrorApi, handleException } from '../api';
import { ApiError } from '../api/generated/personal-loans';

export type RetryProps = {
  label: string;
  value: number;
  fnRetry: (value?: any) => void;
  goBack?: () => void;
  reason: ApiError;
  errorLength?: number;
  setState?: (value?: any) => void;
  setError: (value: React.SetStateAction<ErrorApi | undefined>) => void;
};

export type PrepareCatch = {
  reason: ApiError;
  label: string;
  value: number;
  errorList: Array<string>;
  fnRetry: () => void;
  goBack: () => void;
  setLoading: (value: boolean) => void;
  setError: (value: React.SetStateAction<ErrorApi | undefined>) => void;
};

export const retry = (arrayRetry: RetryProps[]) => {
  arrayRetry.map(({ fnRetry, value }) => value === 0 && fnRetry());
};

export const newValueArrayRetry = (newRetryValues: RetryProps, arrayRetry: RetryProps[]) => {
  const newRetry = arrayRetry.filter(({ label }) => label !== newRetryValues.label);
  newRetry.push(newRetryValues);

  return newRetry;
};

export const catchError = (props: RetryProps, arrayRetry?: RetryProps[]) => {
  const { reason, errorLength = 0, setState, setError, goBack } = props;
  const newItem = newValueArrayRetry(props, arrayRetry || []);
  if (setState) {
    setState(newItem);
  }
  if (errorLength > 2) {
    setError(handleException(reason, goBack));
    return;
  }
  setError(handleException(reason, () => retry(newItem)));
};

export const prepareCatch = (props: PrepareCatch) => {
  const { errorList, fnRetry, goBack, label, reason, setError, setLoading, value } = props;
  setLoading(false);
  setError(undefined);
  if (errorList.length > 2) {
    errorList.length = 0;
    setError(handleException(reason, () => goBack()));
    return;
  }

  errorList.push(`newError at ${new Date().toLocaleTimeString('pt-br')}`);
  const body = {
    reason,
    label,
    value,
    fnRetry,
    goBack: () => goBack(),
    setError,
    errorLength: errorList.length,
  };
  catchError(body);
};
