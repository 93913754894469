export const isLocalMachine = () => {
  return window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' || window.location.hostname === '';
};
export const isCygnusEnv = () => {
  return (
    `${process.env.REACT_APP_API_BASE_URL}`.indexOf(`${process.env.REACT_APP_API_BASE_URL_CYGNUS}`) !== -1 || isLocalMachine()
  );
};
export const isDevEnv = () => {
  return isCygnusEnv() || `${process.env.REACT_APP_API_BASE_URL}`.indexOf('rdeai') !== -1;
};
export const isHmlEnv = () => {
  return isCygnusEnv() || `${process.env.REACT_APP_API_BASE_URL}`.indexOf('hmleai') !== -1;
};
export const isProdEnv = () => {
  return !isDevEnv() && !isHmlEnv() && !isCygnusEnv();
};
