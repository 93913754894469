import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Paper, withStyles } from '@material-ui/core';

import { ContainerTabView } from './styles';

export interface TabViewProps {
  items: ItemTab[];
  noMarginTop?: boolean;
  handleChange?: (newValue) => void;
}

export interface ItemTab {
  label: string;
  content: JSX.Element;
}

interface TabPanelProps {
  children: React.ReactNode;
  index: any;
  value: any;
}
const CustomTab = withStyles({
  root: {
    textTransform: 'none',
    borderBottom: '1px solid #e6f0fd',
    WebkitOverflowScrolling: 'touch', // Add iOS momentum scrolling
    color: '#002063',
  },
  indicator: {
    backgroundColor: '#0069E7',
  },
})(Tabs) as typeof Tabs;

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tab-${index}`}
      style={{
        height: '100%',
        maxHeight: '77.5vh',
      }}
    >
      {value === index && children}
    </div>
  );
};

const TabView = ({ items, noMarginTop, handleChange }: TabViewProps) => {
  const [value, setValue] = React.useState(0);

  const handleTabChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
    setValue(newValue);
    if (handleChange) {
      handleChange(newValue);
    }
  };

  return (
    <>
      <ContainerTabView noMarginTop={noMarginTop}>
        {items.length > 1 && (
          <Paper elevation={0} square>
            <CustomTab value={value} onChange={handleTabChange} translate="yes">
              {items.map(item => (
                <Tab key={`tab${item.label}`} label={item.label} />
              ))}
            </CustomTab>
          </Paper>
        )}
      </ContainerTabView>
      {items.map((item, index) => (
        <TabPanel value={value} index={index} key={`tabpanel${item.label}`}>
          {item.content}
        </TabPanel>
      ))}
    </>
  );
};

export default TabView;
