import { memo } from 'react';
import { CarouselItem, CarouselItemProps } from 'reactstrap';

import { pxToRem } from '../../utils';
import { Col, Container, ImageDiv, ImageSlide, Subtitle, Title } from './styles';

type SlideProps = CarouselItemProps<HTMLDivElement> & {
  item: {
    image: string;
    title: string;
    subtitle: string;
    linkToModal?: () => void;
  };
};

const Slides = ({ item, ...rest }: SlideProps): JSX.Element => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <CarouselItem {...rest}>
    <Container>
      <Col className="block">
        <ImageDiv>
          <p>
            <ImageSlide height={110} src={item.image} alt="icone renovação" loading="lazy" />{' '}
          </p>
        </ImageDiv>

        <div>
          <Title>{item.title}</Title>
        </div>

        <Subtitle style={{ marginBottom: item?.linkToModal && pxToRem(46) }}>
          {item.subtitle}
          {item?.linkToModal && item.linkToModal()}
        </Subtitle>
      </Col>
    </Container>
  </CarouselItem>
);

export default memo(Slides);
