import styled, { keyframes } from 'styled-components/macro';

import { pxToRem } from '../../utils';

const animate = keyframes`
  0% {
    width: 0px;
    height: 0px;
    opacity: 0.5;
  }
  100% {
    width: ${pxToRem(500)};
    height: ${pxToRem(500)};
    opacity: 0;
  }
`;

export const Container = styled.button`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: ${pxToRem(112)};
  border: none;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueLight};
  padding: ${pxToRem(24)} ${pxToRem(32)};

  overflow: hidden;
  position: relative;

  div {
    pointer-events: none;
  }

  span {
    position: absolute;
    background-color: #000;
    transform: translate(-50%, -50%);
    pointer-events: none;
    border-radius: 50%;
    animation: ${animate} 1s linear infinite;
  }
`;

export const Label = styled.p<{ secondaryColor?: boolean }>`
  font-family: Nunito;
  font-size: ${pxToRem(16)};
  font-style: normal;
  font-weight: 600;
  line-height: ${pxToRem(16)};
  text-align: left;
  color: ${p => (p.secondaryColor ? ({ theme }) => theme.colors.blueTypo : ({ theme }) => theme.colors.blueStrong)};
  margin-bottom: ${pxToRem(12)};

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  pointer-events: none;
`;

export const DescriptionLine = styled.p`
  font-family: Nunito;
  font-weight: 400;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(19)};
  text-align: left;
  color: ${({ theme }) => theme.colors.blue};

  margin-bottom: ${pxToRem(6)};
  margin-right: 0;
  padding: 0;
  pointer-events: none;
`;
