/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { VehicleInspectionResponse } from '../models/VehicleInspectionResponse';
import { request as __request } from '../core/request';

export class VehicleInspectionReadControllerService {

    /**
     * getVehicleInspection
     * @param simulationId simulationId
     * @returns VehicleInspectionResponse OK
     * @throws ApiError
     */
    public static async getVehicleInspectionUsingGet(
        simulationId: string,
    ): Promise<VehicleInspectionResponse> {
        const result = await __request({
            method: 'GET',
            path: `/api/personal_loans/vehicle-inspection/${simulationId}`,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

}