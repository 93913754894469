/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomerDocumentRequest } from '../models/CustomerDocumentRequest';
import type { CustomerDocumentResponse } from '../models/CustomerDocumentResponse';
import { request as __request } from '../core/request';

export class CustomerDocumentControllerService {

    /**
     * saveDocument
     * @param customerLoanId customerLoanId
     * @param request request
     * @param xCustomerUuid x-customer-uuid
     * @returns CustomerDocumentResponse OK
     * @returns any Created
     * @throws ApiError
     */
    public static async saveDocumentUsingPost(
        customerLoanId: string,
        request: CustomerDocumentRequest,
        xCustomerUuid: string,
    ): Promise<CustomerDocumentResponse | any> {
        const result = await __request({
            method: 'POST',
            path: `/api/personal_loans/create/customer/document/${customerLoanId}`,
            headers: {
                'x-customer-uuid': xCustomerUuid,
            },
            body: request,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * updateDocument
     * @param customerLoanId customerLoanId
     * @param request request
     * @param xCustomerUuid x-customer-uuid
     * @returns CustomerDocumentResponse OK
     * @returns any Created
     * @throws ApiError
     */
    public static async updateDocumentUsingPut(
        customerLoanId: string,
        request: CustomerDocumentRequest,
        xCustomerUuid: string,
    ): Promise<CustomerDocumentResponse | any> {
        const result = await __request({
            method: 'PUT',
            path: `/api/personal_loans/create/customer/document/${customerLoanId}`,
            headers: {
                'x-customer-uuid': xCustomerUuid,
            },
            body: request,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

}