import styled from 'styled-components/macro';
import { Button } from '../../../components';
import { pxToRem } from '../../../utils';

export const ButtonWithMargin = styled(Button)`
  margin-top: ${pxToRem(16)};
`;

export const Home = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  background: white;
`;

export const ButtonsWrapper = styled.div`
  padding: 0 ${pxToRem(32)};
  width: 100%;
  display: flex;
  flex-direction: column;
  //padding: 0 ${pxToRem(32)};
`;

export const Image = styled.img`
  max-width: fit-content;
`;
