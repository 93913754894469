import { InsuranceEntryPoint } from './insurance';
import { KnowingTheCustomerHome } from './ktc';
import { PreferToPay } from './payment';
import { HomeSurvey } from './survey';

import EntryPointPersonalLoans from '../pages/CpCvg/EntryPointPersonalLoans';

export const Home = {
  CONHECENDO_O_CLIENTE: KnowingTheCustomerHome,
  SEGURO_AUTO: InsuranceEntryPoint,
  CP_CVG: EntryPointPersonalLoans,
  AFTER_SALES: InsuranceEntryPoint,
  SURVEY: HomeSurvey,
  PAYMENT: PreferToPay,
};

export const HOME = [
  { path: '/conhecendoOCliente', component: KnowingTheCustomerHome },
  { path: '/seguroAuto', component: InsuranceEntryPoint },
  { path: '/cpCvg', component: EntryPointPersonalLoans },
  { path: '/afterSales', component: InsuranceEntryPoint },
  { path: '/survey', component: HomeSurvey },
];
