import { memo } from 'react';

import { ButtonInputCover, Container } from '../InputCover/styles';
import { ImgBrand } from '../styles';
import { Content, Line, MasterCard, Title, Wallet } from './styles';

type Props = {
  line: string;
  title: string;
  action?: () => void;
  buttonLabel?: string;
  icon?: string;
};

const InputPayment = ({ line, title, action, buttonLabel = 'Editar', icon }: Props) => {
  return (
    <Container>
      <Content>
        {icon && icon !== 'masterCard' && icon !== 'wallet' && <ImgBrand src={icon} alt="icon from card brand" loading="lazy" />}
        {icon === 'masterCard' && <MasterCard aria-label="icon from card brand" />}
        {icon === 'wallet' && <Wallet aria-label="icon from wallet" />}
        <div>
          <Title>{title}</Title>
          <Line>{line}</Line>
        </div>
      </Content>

      <ButtonInputCover onClick={action}>{buttonLabel}</ButtonInputCover>
    </Container>
  );
};

export default memo(InputPayment);
