import { memo } from 'react';

import Button from '../Button';
import { Container, Text, Title } from './styles';

type Props = {
  action: () => void;
  title: string;
  text: string;
  icon?: string;
  buttonLabel?: string;
};

const StaticBody = ({ action, text, title, buttonLabel, icon }: Props) => (
  <Container>
    {icon && <img src={icon} alt="Ícone correspondente à ação" loading="lazy" />}
    <Title>{title}</Title>
    <Text>{text}</Text>
    <Button noMargin background="white" color="blueStrong" onClick={action}>
      {buttonLabel || 'Continuar'}
    </Button>
  </Container>
);

export default memo(StaticBody);
