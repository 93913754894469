import { ErrorApi } from '../../api';

import { ErrorModal, HeaderForm, Loading } from '..';

import { Body, Container } from './styles';

interface LayoutFormProps {
  children: JSX.Element | JSX.Element[];
  noPaddingX?: boolean;
  stepper?: 'yellow' | 'top' | 'none';
  colorbackground?: 'blueTypo' | 'white';
  backAction?: () => void;
  isLoading?: boolean;
  isError?: ErrorApi;
  actionPrimaryButton?: () => void;
}

const LayoutFormNoAbsolute = ({
  children,
  noPaddingX,
  stepper = 'yellow',
  colorbackground = 'white',
  backAction,
  isLoading = false,
  isError,
  actionPrimaryButton,
}: LayoutFormProps): JSX.Element => {
  return (
    <Container>
      <HeaderForm stepper={stepper} colorbackground={colorbackground} backAction={backAction} />
      <Body noPaddingX={noPaddingX}>
        {children}

        {isLoading && !isError && <Loading />}
        {isError && <ErrorModal actionPrimaryButton={actionPrimaryButton} errorDetails={isError} />}
      </Body>
    </Container>
  );
};

export default LayoutFormNoAbsolute;
