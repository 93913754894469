/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { request as __request } from '../core/request';

export class ProposalReadControllerService {
  /**
   * @param xCustomerUuid
   * @param acceptLanguage
   * @returns any OK
   * @throws ApiError
   */
  public static async findCurrentProposal(xCustomerUuid: string, acceptLanguage?: string): Promise<any> {
    const result = await __request({
      method: 'GET',
      path: `/insurance/proposal`,
      headers: {
        'x-customer-uuid': xCustomerUuid,
        'Accept-Language': acceptLanguage,
      },
    });
    return result.body;
  }

  /**
   * @param xCustomerUuid
   * @param acceptLanguage
   * @returns any OK
   * @throws ApiError
   */
  public static async sendProposalLead(xCustomerUuid: string, requestBody: any, acceptLanguage?: string): Promise<any> {
    const result = await __request({
      method: 'POST',
      path: `/insurance/result_quote/proposal`,
      headers: {
        'x-customer-uuid': xCustomerUuid,
        'Accept-Language': acceptLanguage,
      },
      body: requestBody,
    });
    return result.body;
  }
}
