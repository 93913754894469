interface Props {
  children: React.ReactNode;
  className?: string;
  id?: string;
}

const AbsoluteWrapper = ({ children, className, id }: Props): JSX.Element => (
  <div className={`position-absolute w-100 ${className ?? ''}`} id={id}>
    {children}
  </div>
);

export default AbsoluteWrapper;
