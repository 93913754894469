/* eslint-disable react/jsx-props-no-spreading */
import { ButtonHTMLAttributes } from 'react';
import theme from '../../styles/theme';

import { StyledButton, Wrapper } from './styles';

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  background?: 'white' | 'yellowStars' | 'blueStrong' | 'greyBackground' | 'transparent';
  color?: 'white' | 'blueTypo' | 'blueStrong' | 'red';
  maxWidth?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  paddingY?: number;
  paddingX?: number;
  noMargin?: boolean;
  onClick?: () => void;
  children: string;
  square?: boolean;
};

const Button = ({
  children,
  color = 'white',
  background = 'blueStrong',
  fullWidth,
  maxWidth,
  disabled,
  paddingY,
  paddingX,
  noMargin,
  onClick,
  square,
  className,
  ...rest
}: ButtonProps): JSX.Element => {
  const handleClick = e => {
    const ripples = document.createElement('span');
    const buttonPosition = e.target.getBoundingClientRect();
    const top = Math.abs(buttonPosition.top - e.clientY);
    const left = Math.abs(buttonPosition.left - e.clientX);

    ripples.style.top = `${top}px`;
    ripples.style.left = `${left}px`;

    e.target.appendChild(ripples);

    setTimeout(() => {
      ripples.remove();

      return onClick && onClick();
    }, 300);
  };

  return (
    <Wrapper noMargin={noMargin} fullWidth={fullWidth || square} paddingX={paddingX} paddingY={paddingY} className={className}>
      <StyledButton
        type="button"
        square={square}
        disabled={disabled}
        aria-label={children}
        aria-disabled={disabled}
        style={{
          color: disabled ? theme.colors.textDisable : theme.colors[color],
          backgroundColor: disabled ? theme.colors.greyBackground : theme.colors[background],
          maxWidth,
        }}
        onClick={handleClick}
        className={className}
        {...rest}
      >
        {children}
      </StyledButton>
    </Wrapper>
  );
};

export default Button;
