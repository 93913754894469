import { lazy } from 'react';

/* Knowing The Customer */
const AcceptBonus = lazy(() => import('../pages/KnowingTheCustomer/AcceptBonus500'));
const CarPlateKTC = lazy(() => import('../pages/KnowingTheCustomer/CarPlate'));
const CarBrand = lazy(() => import('../pages/KnowingTheCustomer/CarBrand'));
const CarModel = lazy(() => import('../pages/KnowingTheCustomer/CarModel'));
const BonusReward = lazy(() => import('../pages/KnowingTheCustomer/BonusReward'));
const CarYear = lazy(() => import('../pages/KnowingTheCustomer/CarYear'));
export const KnowingTheCustomerHome = lazy(() => import('../pages/KnowingTheCustomer/MockHomeApp'));
const AcceptBonus1000 = lazy(() => import('../pages/KnowingTheCustomer/AcceptBonus1000'));
const HasInsurance = lazy(() => import('../pages/KnowingTheCustomer/HasInsurance'));
const WhyNotHasInsurance = lazy(() => import('../pages/KnowingTheCustomer/WhyNotHasInsurance'));
const InsuranceCompany = lazy(() => import('../pages/KnowingTheCustomer/InsuranceCompany'));
const InsuranceDueDate = lazy(() => import('../pages/KnowingTheCustomer/InsuranceDueDate'));

export const KTC = [
  // 500km
  { path: '/', component: KnowingTheCustomerHome },
  { path: '/accept-bonus-500', component: AcceptBonus },
  { path: '/carplate', component: CarPlateKTC },
  { path: '/car-brand', component: CarBrand },
  { path: '/car-model', component: CarModel },
  { path: '/car-year', component: CarYear },
  { path: '/bonus-reward', component: BonusReward },

  // 1000km
  { path: '/accept-bonus-1000', component: AcceptBonus1000 },
  { path: '/has-insurance', component: HasInsurance },
  { path: '/why-not-insurance', component: WhyNotHasInsurance },
  { path: '/insurance-company', component: InsuranceCompany },
  { path: '/insurance-due-date', component: InsuranceDueDate },
];
