import styled from 'styled-components/macro';

import { pxToRem } from '../../utils';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueLight};
  width: 100%;
  height: ${pxToRem(73)};
`;

export const Label = styled.p`
  font-family: Nunito;
  font-weight: 600;
  font-style: normal;
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(24)};
  text-align: left;

  color: ${({ theme }) => theme.colors.blueTypo};
`;
