import { lazy } from 'react';

/* After Sales */
const ActivateInsurance = lazy(() => import('../pages/Insurance/AfterSales/ActivateInsurance'));
const ConsultInsurance = lazy(() => import('../pages/Insurance/AfterSales/ConsultInsurance'));
const ContinueSimulation = lazy(() => import('../pages/Insurance/AfterSales/ContinueSimulation'));
const HomeAfterSales = lazy(() => import('../pages/Insurance/AfterSales/HomeAfterSales'));
const InsuranceCanceled = lazy(() => import('../pages/Insurance/AfterSales/InsuranceCanceled'));
const PaymentHistory = lazy(() => import('../pages/Insurance/AfterSales/PaymentHistory'));
const StatusPaying = lazy(() => import('../pages/Insurance/AfterSales/StatusPaying'));
const ZeroState = lazy(() => import('../pages/Insurance/AfterSales/ZeroState'));

export const AFTER_SALES = [
  { path: '/afterSales/home', component: HomeAfterSales },
  { path: '/afterSales/zero', component: ZeroState },
  { path: '/afterSales/activateInsurance', component: ActivateInsurance },
  { path: '/afterSales/consultInsurance', component: ConsultInsurance },
  { path: '/afterSales/continueSimulation', component: ContinueSimulation },
  { path: '/afterSales/insuranceCanceled', component: InsuranceCanceled },
  { path: '/afterSales/paymentHistory', component: PaymentHistory },
  { path: '/afterSales/statusPaying', component: StatusPaying },
];
