const INITIAL_STATE = {
  currentStep: 0,
  totalSteps: 1,
  occupation: 0,
  whoDrives: 0,
  fuelType: 0,
  rgNumber: 0,
  insuranceCoverage: 0,
  offerCarousel: 0,
  brand: undefined,
};

export type StepProps = {
  currentStep: number;
  totalSteps: number;
  occupation?: number;
  whoDrives?: number;
  fuelType?: number;
  rgNumber?: number;
  insuranceCoverage?: number;
  offerCarousel?: number;
  brand?: 'bvHeader' | 'mapfreHeader' | 'tokyoHeader' | 'questionCircle' | 'wallet';
};

export default function stepsManager(state = INITIAL_STATE, action: { type: string; payload: StepProps }) {
  switch (action.type) {
    case 'CHANGE_STEP':
      return { ...state, ...action.payload };

    case 'SUM_STEP':
      return {
        ...state,
        currentStep: state.currentStep + Number(action.payload) || 1,
      };

    case 'SUB_STEP':
      return {
        ...state,
        currentStep: state.currentStep - 1,
      };

    case 'CUR_STEP':
      return {
        ...state,
        currentStep: Number(action.payload),
      };

    case 'OCC_STEP':
      return {
        ...state,
        occupation: Number(action.payload),
      };

    case 'WHO_STEP':
      return {
        ...state,
        whoDrives: Number(action.payload),
      };

    case 'FUE_STEP':
      return {
        ...state,
        fuelType: Number(action.payload),
      };

    case 'RGN_STEP':
      return {
        ...state,
        rgNumber: Number(action.payload),
      };

    case 'ICO_STEP':
      return {
        ...state,
        insuranceCoverage: Number(action.payload),
      };

    case 'OFF_Step':
      return {
        ...state,
        offerCarousel: Number(action.payload),
      };

    case 'BRAND_STEP':
      return {
        ...state,
        brand: action.payload,
      };
    default:
      return state;
  }
}
