import { UPDATE_INFO } from '../actions/actionTypes';
import { KnowingTheCustomerModel } from '../models/knowingTheCustomer/knowingTheCustomerModel';

export interface CustomerCarProps {
  customer?: KnowingTheCustomerModel;
}

const INITIAL_STATE: CustomerCarProps = {
  customer: {
    plate: '',
  },
};

const CustomerCarReducer = (state = INITIAL_STATE, action: { type: string; payload: CustomerCarProps }): CustomerCarProps => {
  switch (action.type) {
    case UPDATE_INFO:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default CustomerCarReducer;
