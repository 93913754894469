export const rootPalette = {
  blue: '#003863',
  blueGrayLight: '#B3C4D0',
  blueLight: '#E6F0FD',
  blueStrong: '#0069E7',
  blueStrongOpacity10: '#0069E71A',
  blueTypo: '#002063',
  blueTypo2: '#0045A5',
  brandBlue400: '#001f63',

  green: '#00AC4D',
  greyBackground: '#EEF1F4',

  orange: '#FF6000',

  red: '#E00035',

  success: '#00892E',

  tokenColorBlue200: '#596E9A',
  tokEnColorBlue100: '#E6F0FD',
  textDisable: '#ACB5BF',

  white: '#FFFFFF',
  white10percent: '#FFFFFF1A',

  yellowStars: '#FFD000',

  transparent: 'transparent',
};
