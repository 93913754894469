/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { request as __request } from '../core/request';

export class InsuranceHolderControllerService {

    /**
     * @param xCustomerUuid
     * @param quoteId
     * @param insuranceHolderId
     * @param requestBody
     * @param acceptLanguage
     * @returns any OK
     * @throws ApiError
     */
    public static async update2(
        xCustomerUuid: string,
        quoteId: string,
        insuranceHolderId: string,
        requestBody: any,
        acceptLanguage?: string,
    ): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/insurance/holder/${insuranceHolderId}/quote/${quoteId}`,
            headers: {
                'x-customer-uuid': xCustomerUuid,
                'Accept-Language': acceptLanguage,
            },
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param xCustomerUuid
     * @param quoteId
     * @param insuranceHolderId
     * @param acceptLanguage
     * @returns any OK
     * @throws ApiError
     */
    public static async delete1(
        xCustomerUuid: string,
        quoteId: string,
        insuranceHolderId: string,
        acceptLanguage?: string,
    ): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/insurance/holder/${insuranceHolderId}/quote/${quoteId}`,
            headers: {
                'x-customer-uuid': xCustomerUuid,
                'Accept-Language': acceptLanguage,
            },
        });
        return result.body;
    }

    /**
     * @param xCustomerUuid
     * @param quoteId
     * @param requestBody
     * @param acceptLanguage
     * @returns any OK
     * @throws ApiError
     */
    public static async save5(
        xCustomerUuid: string,
        quoteId: string,
        requestBody: any,
        acceptLanguage?: string,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/insurance/holder/quote/${quoteId}`,
            headers: {
                'x-customer-uuid': xCustomerUuid,
                'Accept-Language': acceptLanguage,
            },
            body: requestBody,
        });
        return result.body;
    }

}