import { changeInsuranceAction } from '../actions';
import { CreditCard, ErrorApi, getBalance, handleException } from '../api';
import {
  AddressHolderResponse,
  CoverageValueResponse,
  InsuranceHolderResponse,
  ProposalResponse,
  QuoteResponse,
} from '../api/generated/insurance';
import { formatDate } from './formatDate';
import { Contract, Coverage, Documents, PaymentInstrument, PaymentOption, ProposalStoreModel, QuoteStoreModel } from '../models';
import { brandChange } from '../store';

type Props = {
  quote: QuoteStoreModel;
  setLoadingFetch: (value: boolean) => void;
  setError: (value: ErrorApi) => void;
  dispatch: (value: any) => void;
  redirectToUrl: (value: string) => void;
};

type SaveContractPropsToRedux = {
  currentQuote?: QuoteStoreModel;
  dispatch: (value: any) => void;

  contract?: Contract;
  paymentInstrument?: PaymentInstrument;
  creditCard?: CreditCard;
};

const formatDateIso = (isoDate: string) => Number(isoDate.replace(/[/-]+/gm, '').replace(/^([0-9]{8}).+/gm, '$1'));

const hasUnderTwentySix = (birthDate: Date | string | undefined) => {
  if (!birthDate) return false;
  const formattedToday = formatDateIso(new Date().toISOString());
  const formattedBirthDate = formatDateIso(new Date(birthDate).toISOString());
  const calcYears = formattedToday - formattedBirthDate;
  return calcYears < 260000;
};

export const getCoverages = (coverageValues?: CoverageValueResponse[]): Coverage[] => {
  if (!coverageValues || coverageValues.length === 0) return [];
  const filteredCoverages = coverageValues?.filter(({ insuredAmount }) => insuredAmount && insuredAmount > 0);
  if (!filteredCoverages) return [];

  const mappedCoverages = filteredCoverages.map(({ basic, description = '', fipeText, insuredAmount, premium, uuid }) => ({
    basic,
    description,
    fipeText,
    insuredAmount,
    premium,
    uuid,
  }));

  return mappedCoverages;
};

const getRG = (value?: InsuranceHolderResponse) => {
  if (!value || !value?.documents || value?.documents?.length === 0) return {} as Documents;

  return {
    rg: value?.documents[0].number,
    rgIssueDate: formatDate.toPTBRDayJs(value?.documents[0].issueDate),
    id: value?.documents[0].id,
    issuingAgency: {
      id: value?.documents[0].issuingAgency,
      name: value?.documents[0].issuingAgencyDescription,
    },
  } as Documents;
};

export const convertToStoreModel = (quotes: QuoteResponse[]): QuoteStoreModel[] => {
  return quotes?.map((quote: QuoteResponse) => {
    const {
      ci,
      date,
      drivers,
      expirationDate,
      id,
      insuranceHolder,
      resultQuoteId = '',
      riskAddress,
      status,
      underageDriver = false,
      vehicle,
    } = quote;
    const driver = drivers && drivers[0];
    const address = insuranceHolder?.addresses ? insuranceHolder?.addresses[0] : ({} as AddressHolderResponse);
    const phone = insuranceHolder?.phones && insuranceHolder?.phones[0];
    const rg = getRG(insuranceHolder);
    const clientIsMainDriver = driver?.document === insuranceHolder?.document;
    const expireAt = expirationDate && new Date(expirationDate);

    return {
      quoteId: id,
      createdAt: date ? new Date(date) : new Date(),
      expireAt,
      status,
      client: {
        id: insuranceHolder?.id,
        name: insuranceHolder?.fullName,
        firstName: insuranceHolder?.firstName,
        birthDate: formatDate.toPTBRDayJs(insuranceHolder?.birthDate),
        gender: { id: insuranceHolder?.gender || '', name: insuranceHolder?.genderDescription },
        maritalStatus: { id: insuranceHolder?.maritalStatus || '', name: insuranceHolder?.maritalStatusDescription },
        underTwentySix: hasUnderTwentySix(insuranceHolder?.birthDate),
        document: {
          cpf: insuranceHolder?.document,
          ...rg,
        },
        pep: quote?.pep || false,
        incomeTier: {
          id: insuranceHolder?.incomeTier || '',
          name: insuranceHolder?.incomeTierDescription,
        },
        occupation: {
          id: insuranceHolder?.profession || '',
          name: insuranceHolder?.professionDescription,
        },
        address: {
          ...address,
          id: address?.id,
          city: { id: address?.city || '', name: address?.city },
          state: { id: address?.district || '', name: address?.district },
          street: address?.street,
          streetNumber: address?.number,
          zipcode: address?.zip,
          country: 'Brasil',
        },
        phone,
        email: insuranceHolder?.email,
      },
      driver: {
        name: driver?.name,
        birthDate: formatDate.toPTBRDayJs(driver?.birthDate),
        gender: { id: driver?.gender || '', name: driver?.genderDescription },
        maritalStatus: { id: driver?.maritalStatus || '', name: driver?.maritalStatusDescription },
        document: { cpf: driver?.document },
        underTwentySix: hasUnderTwentySix(driver?.birthDate),
        id: driver?.id,
        relationHolder: {
          id: driver?.relationalType || '',
          name: driver?.relationalTypeDescription,
        },
      },
      insurance: {
        ciCodePreviousInsurance: ci,
        driverUnder26: underageDriver,
        clientIsMainDriver,
        contract: {
          contractId: resultQuoteId,
        },
      },
      car: {
        id: vehicle?.id,
        plate: vehicle?.licensePlate,
        chassisNumber: vehicle?.vin,
        armored: vehicle?.armored,
        auction: vehicle?.auction,
        taxExempt: vehicle?.taxExempt === 4 ? false : Boolean(vehicle?.taxExempt),
        restampedChassis: vehicle?.restampedChassis,
        fuelType: vehicle?.fuelType?.toUpperCase() as 'DIESEL' | 'GASOLINA' | 'FLEX',
        model: {
          brand: { id: vehicle?.brandCode || '', name: vehicle?.brand },
          name: { id: vehicle?.modelVersion || '', name: vehicle?.modelVersion },
        },
        manufactureYear: vehicle?.manufactureYear,
        modelYear: vehicle?.modelYear,
        vehicleNew: quote?.vehicleNew || false,
        valueVehicle: Number(vehicle?.valueVehicle || 0),
        fipeCode: vehicle?.fipeCode,
        renew: quote?.renew || false,
        overNightAddress: {
          id: riskAddress?.id,
          city: { id: riskAddress?.city || '', name: riskAddress?.city },
          state: { id: riskAddress?.district || '', name: riskAddress?.district },
          street: riskAddress?.street,
          streetNumber: riskAddress?.number,
          zipcode: riskAddress?.zip,
          country: 'Brasil',
        },
      },
    } as QuoteStoreModel;
  });
};

export const convertProposalToStoreModel = (proposal: ProposalResponse): ProposalStoreModel => {
  const { vehicleResponse, ...restOfProposal } = proposal;
  return {
    ...restOfProposal,
    car: {
      id: vehicleResponse?.id,
      armored: vehicleResponse?.armored,
      plate: vehicleResponse?.licensePlate,
      manufactureYear: vehicleResponse?.manufactureYear,
      modelYear: vehicleResponse?.modelYear,
      restampedChassis: vehicleResponse?.restampedChassis,
      fipeCode: vehicleResponse?.fipeCode,
      auction: vehicleResponse?.auction,
      taxExempt: vehicleResponse?.taxExempt === 4 ? false : Boolean(vehicleResponse?.taxExempt),
      model: {
        brand: { id: vehicleResponse?.brandCode || '', name: vehicleResponse?.brand },
        name: { id: vehicleResponse?.modelVersion || '', name: vehicleResponse?.modelVersion },
      },
      chassisNumber: vehicleResponse?.vin,
    },
  } as ProposalStoreModel;
};

type GetBrandToReduxProps = {
  currentQuote?: QuoteStoreModel;
  dispatch: (value: any) => void;
};

const brands = {
  bv: 'bvHeader',
  mapfre: 'mapfreHeader',
  tokyo: 'tokyoHeader',
};

export const getBrandToRedux = (props: GetBrandToReduxProps) => {
  const { currentQuote, dispatch } = props;

  if (currentQuote?.insurance?.contract?.insuranceCompany) {
    const { insuranceCompany } = currentQuote?.insurance?.contract;
    dispatch(brandChange(brands[insuranceCompany]));
  }
};

const redirectToPayment = (props: Props) => {
  const { quote, redirectToUrl } = props;
  if (quote.insurance?.contract?.paymentInstrument?.id) {
    redirectToUrl(`/payment/summary/${quote.insurance?.contract.paymentInstrument.installments}`);
    return;
  }

  redirectToUrl('/payment');
};

export const getValueBalance = (props: Props) => {
  const { quote, setLoadingFetch, setError, dispatch } = props;
  if (quote.insurance?.balance) {
    redirectToPayment(props);
    return;
  }

  setLoadingFetch(true);
  getBalance()
    .then(({ current }) => {
      const newBalance = current / 100;
      dispatch(changeInsuranceAction({ currentQuote: { ...quote, insurance: { ...quote.insurance, balance: newBalance } } }));
      redirectToPayment(props);
    })
    .catch(reason => {
      setLoadingFetch(false);
      setError(handleException(reason));
    });
};

// const prepareContract = (props: Props) => {
//   const { quote, setLoadingFetch, setError, dispatch, redirectToUrl } = props;
//   if (quote.insurance?.contract && quote.insurance.balance) {
//     redirectToPayment(props);
//     return;
//   }

//   setLoadingFetch(true);
//   getContract(quote.insurance?.contract?.contractId || '')
//     .then(res => {
//       const contract = {
//         codePlan: res.codePlan,
//         comission: res.comission,
//         contractId: res.contractId,
//         contractUuid: res.contractUuid,
//         coverageValues: getCoverages(res.coverageValues),
//         customerId: res.customerId,
//         dateCalculation: formatDate.toPTBRDayJs(res.dateCalculation),
//         deductible: res.deductible,
//         dueDate: formatDate.toPTBRDayJs(res.dueDate),
//         fromDate: formatDate.toPTBRDayJs(res.fromDate),
//         id: res.id,
//         insuranceCompany: res.insuranceCompany,
//         paymentOptions: res.paymentOptions,
//         paymentInstrument: res.paymentInstrument,
//         productId: res.productId,
//         quoteId: res.quoteId,
//         thumbnail: res.thumbnail,
//       } as Contract;

//       getValueBalance({
//         quote: { ...quote, insurance: { ...quote.insurance, contract: { ...quote.insurance?.contract, ...contract } } },
//         setLoadingFetch,
//         setError,
//         dispatch,
//         redirectToUrl,
//       });
//     })
//     .catch(reason => {
//       setLoadingFetch(false);
//       setError(handleException(reason));
//     });
// };

export const decideToUrl = (props: Props) => {
  const { quote, redirectToUrl } = props;

  // if (quote.insurance?.contract?.contractId) {
  //   prepareContract({ quote, setLoadingFetch, setError, dispatch, redirectToUrl });
  //   return;
  // }
  if (quote.insurance?.ciCodePreviousInsurance) {
    redirectToUrl('/ci-code');
    return;
  }
  if (quote.car?.overNightAddress?.zipcode) {
    redirectToUrl('/new-car-address');
    return;
  }
  if (quote.car?.plate && !quote.car?.model?.name?.id) {
    redirectToUrl('/car-details');
    return;
  }
  if (quote.car?.plate && quote.car?.model?.name?.id) {
    redirectToUrl('/carplatedetails');
    return;
  }
  if (quote.client?.name) {
    redirectToUrl('/renovation');
    return;
  }
  redirectToUrl('/renovation');
};

type GetMaxPaymentOptionProps = {
  method: 'credit_card' | 'direct_debit';
  paymentOptions: PaymentOption[] | undefined;
};
export const getMaxPaymentOption = (props: GetMaxPaymentOptionProps): PaymentOption => {
  const { method, paymentOptions } = props;
  if (!paymentOptions || paymentOptions.length === 0) return {};

  const filteredPayments = paymentOptions.filter(({ paymentMethod }) => paymentMethod === method);
  if (!filteredPayments) return {};

  const installments = filteredPayments.map(({ installments }) => installments || 0);
  if (!installments) return {};

  const maxValue = Math.max.apply(null, installments);
  const indexMaxValue = filteredPayments.findIndex(({ installments }) => installments === maxValue);

  return filteredPayments[indexMaxValue];
};

export const saveContractPropsToRedux = (props: SaveContractPropsToRedux) => {
  if (!props.currentQuote) return;

  const { currentQuote, dispatch, creditCard, contract, paymentInstrument } = props;
  dispatch(
    changeInsuranceAction({
      currentQuote: {
        ...currentQuote,
        insurance: {
          ...currentQuote?.insurance,
          contract: {
            ...currentQuote?.insurance?.contract,
            ...contract,
            paymentInstrument: paymentInstrument || currentQuote?.insurance?.contract?.paymentInstrument,
          },
          creditCard: creditCard || currentQuote?.insurance?.creditCard,
        },
      },
    }),
  );
};
