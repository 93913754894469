import styled, { keyframes } from 'styled-components/macro';
import { Button } from 'react-bootstrap';
import { FaRegTimesCircle } from 'react-icons/fa';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import InputMask from 'react-input-mask';

import { ReactComponent as ExclamationSVG } from '../../assets/icons/exclamationTriangle.svg';
import { pxToRem } from '../../utils';

const NumberFormatTyped = NumberFormat as unknown as (props: NumberFormatProps) => JSX.Element;

const animate = keyframes`
  0% {
    width: 0px;
    height: 0px;
    opacity: 0.5;
  }
  100% {
    width: ${pxToRem(500)};
    height: ${pxToRem(500)};
    opacity: 0;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueLight};
  padding: ${pxToRem(24)} ${pxToRem(32)};

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const Question = styled.p<{ afterSales?: boolean }>`
  font-family: Nunito;
  font-weight: 600;
  font-style: normal;
  font-size: ${pxToRem(14)};
  line-height: ${({ afterSales }) => pxToRem(afterSales ? 24 : 16)};

  color: ${({ theme, afterSales }) => (afterSales ? theme.colors.tokenColorBlue200 : theme.colors.blue)};
  mix-blend-mode: normal;
  opacity: ${({ afterSales }) => (afterSales ? 1 : 0.65)};

  margin-bottom: ${pxToRem(5)};
`;

export const Answer = styled.p<{ place?: string }>`
  font-family: Nunito;
  font-weight: 700;
  font-style: normal;
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(24)};
  color: ${({ theme, place }) => (place ? theme.colors.blueGrayLight : theme.colors.blueTypo)};
  margin-bottom: 0;
`;

export const AnswerFormatted = styled(NumberFormatTyped).attrs({
  displayType: 'text',
})`
  font-family: Nunito;
  font-weight: 700;
  font-style: normal;
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(24)};
  color: ${({ theme }) => theme.colors.blueTypo};
  margin-bottom: 0;

  &::placeholder {
    color: ${({ theme }) => theme.colors.blueGrayLight};
  }
`;

export const AnswerMasked = styled(InputMask)`
  font-family: Nunito;
  font-weight: 700;
  font-style: normal;
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(24)};
  color: ${({ theme }) => theme.colors.blueTypo};
  margin-bottom: 0;

  &::placeholder {
    color: ${({ theme }) => theme.colors.blueGrayLight};
  }
`;

export const ButtonInputCover = styled(Button).attrs({ bsPrefix: 'semBtn' })<{ disabled?: boolean }>`
  font-family: Nunito;
  font-weight: 700;
  font-style: normal;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(19)};
  color: ${p => (p.disabled ? ({ theme }) => theme.colors.textDisable : ({ theme }) => theme.colors.blueStrong)};
  background: white;

  min-width: ${pxToRem(87)};
  height: ${pxToRem(40)};
  border: 2px solid ${({ theme }) => theme.colors.blueLight};
  box-sizing: border-box;
  border-radius: ${pxToRem(16)};
  overflow: hidden;
  position: relative;

  span {
    position: absolute;
    background-color: #000;
    transform: translate(-50%, -50%);
    pointer-events: none;
    border-radius: 50%;
    animation: ${animate} 1s linear infinite;
  }
`;

export const ContentModal = styled.div`
  width: 100%;
  padding: 0 ${pxToRem(32)};

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Title = styled.p`
  font-family: Nunito;
  font-weight: 400;
  font-style: normal;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(19)};

  color: ${({ theme }) => theme.colors.blue};

  margin-bottom: ${pxToRem(16)};
`;

export const ContentInput = styled.div<{ error?: boolean }>`
  width: 100%;
  border-bottom: 2px solid ${({ theme, error }) => (error ? theme.colors.red : `${theme.colors.blueTypo}33`)};
`;

export const Input = styled.input`
  width: 100%;

  font-family: Nunito;
  font-weight: 700;
  font-style: normal;
  font-size: ${pxToRem(24)};
  line-height: ${pxToRem(33)};

  color: ${({ theme }) => theme.colors.blueTypo};

  border: none;

  &::placeholder {
    color: ${({ theme }) => theme.colors.blueGrayLight};
  }

  &:focus {
    outline: none;
  }
`;

export const InputFormatted = styled(NumberFormatTyped)`
  width: 100%;

  font-family: Nunito;
  font-weight: 700;
  font-style: normal;
  font-size: ${pxToRem(24)};
  line-height: ${pxToRem(33)};

  color: ${({ theme }) => theme.colors.blueTypo};

  border: none;

  ::placeholder {
    color: ${({ theme }) => theme.colors.blueGrayLight};
  }

  &:focus {
    outline: none;
  }
`;

export const InputMasked = styled(InputMask)`
  width: 100%;

  font-family: Nunito;
  font-weight: 700;
  font-style: normal;
  font-size: ${pxToRem(24)};
  line-height: ${pxToRem(33)};

  color: ${({ theme, disabled }) => (disabled ? `${theme.colors.blueTypo}1A` : theme.colors.blueTypo)};

  border: none;

  ::placeholder {
    color: ${({ theme }) => theme.colors.blueGrayLight};
  }

  &:focus {
    outline: none;
  }
`;

export const HelperText = styled.span<{ small?: boolean; error?: boolean }>`
  font-family: Nunito;
  font-weight: 600;
  font-style: normal;
  font-size: ${({ small }) => pxToRem(small ? 12 : 14)};
  line-height: ${pxToRem(19)};

  color: ${({ theme, error }) => theme.colors[error ? 'red' : 'blue']};
  opacity: ${({ error }) => (error ? 1 : 0.5)};
  margin-top: ${pxToRem(8)};
`;

export const CleanInput = styled(FaRegTimesCircle)`
  color: ${({ theme }) => theme.colors.blueGrayLight};
  width: ${pxToRem(24)};
  height: ${pxToRem(24)};
`;

export const ExclamationTriangle = styled(ExclamationSVG).attrs({
  width: pxToRem(24),
  height: pxToRem(24),
})`
  path {
    fill: ${({ theme }) => theme.colors.red};
  }
`;
