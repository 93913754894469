import { memo, useState } from 'react';

import BasicModal from '../BasicModal';
import { ContainerErrorModal, TagError, TextError, TitleError } from './styles';
import { ErrorApi } from '../../../api/error/ErrorApi';
import { parser } from '../../../utils';

type ErrorModalProps = {
  errorDetails: ErrorApi;
  primaryButtonLabel?: string;
  secondButtonLabel?: string;
  actionPrimaryButton?: () => void;
  actionSecondButton?: () => void;
};

const ErrorModal = ({
  errorDetails,
  primaryButtonLabel,
  actionPrimaryButton,
  secondButtonLabel,
  actionSecondButton,
}: ErrorModalProps) => {
  const [showModal, setShowModal] = useState(true);

  const handleClose = () => setShowModal(prev => !prev);

  return (
    <BasicModal
      open={showModal}
      onClose={handleClose}
      onClick={() => (actionPrimaryButton ? actionPrimaryButton() : handleClose())}
      primaryButton={primaryButtonLabel || 'Tentar novamente'}
      secondButton={secondButtonLabel}
      secondaryClick={actionSecondButton}
      overlay={errorDetails.retryAction || actionPrimaryButton}
    >
      <ContainerErrorModal>
        {errorDetails.errorCode ? (
          <TagError>{`[código interno ${errorDetails.errorCode}]`}</TagError>
        ) : (
          <TagError>{`[código do erro ${errorDetails.httpStatus}]`}</TagError>
        )}
        <TitleError>{errorDetails.title}</TitleError>
        <TextError style={{ whiteSpace: 'pre-wrap' }}>{parser(errorDetails.text || '')}</TextError>
      </ContainerErrorModal>
    </BasicModal>
  );
};

export default memo(ErrorModal);
