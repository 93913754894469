import { memo, useState } from 'react';
import { useDispatch } from 'react-redux';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import theme from '../../styles/theme';
import { Domain } from '../../models';

import { brandChange } from '../../store/Steps/Steps.actions';

import Modal from '../Modal';
import { Item, ItemContainer, Title, TitleWrapper } from '../Modal/SelectModal/styles';
import { FormSubtitle } from '../styles';
import { Container, ContentWhiteButtonModal, Label, Text, TitleModal } from './styles';

type WhiteButtonProps = {
  label: string;
  action: () => void;
  onOpen?: () => void;
  onClick?: () => void;
  items: Domain[];
  icon?: string;
  titleModal?: string;
  buttonLabel?: string;
  text?: string;
  twoButtons?: boolean;
  phone?: string;
};

const WhiteButtonModal = (props: WhiteButtonProps): JSX.Element => {
  const { label, action, onOpen, onClick, items, icon, titleModal, buttonLabel, text, twoButtons = false, phone = '' } = props;
  const dispatch = useDispatch();
  const [isOpened, setIsOpened] = useState(false);

  const openModal = () => setIsOpened(prev => !prev);

  const handleClick = e => {
    const [id] = e.target.id.split('#_');
    const ripples = document.createElement('span');
    const buttonPosition = e.target.getBoundingClientRect();
    const top = Math.abs(buttonPosition.top - e.clientY);
    const left = Math.abs(buttonPosition.left - e.clientX);

    ripples.style.top = `${top}px`;
    ripples.style.left = `${left}px`;

    e.target.appendChild(ripples);

    setTimeout(() => {
      ripples.remove();

      if ((isOpened || items.length === 1) && !buttonLabel) {
        action();
        return dispatch(brandChange(id || items[0].id));
      }
      if (onOpen) onOpen();
      return openModal();
    }, 300);
  };

  return (
    <>
      <Container aria-hidden="true" onClick={handleClick}>
        <div>
          <div>
            {icon && <img src={icon} alt={`Ícone da opção ${label}`} loading="lazy" />}
            <Label icon={!!icon} text={!!text}>
              {label}
            </Label>
          </div>
          {text && <Text>{text}</Text>}
        </div>
        <KeyboardArrowRightIcon style={{ color: theme.colors.blueTypo }} />
      </Container>

      <Modal
        open={isOpened}
        onClose={setIsOpened}
        onClick={() => {
          if (onClick) onClick();
          openModal();
        }}
        isHidden={!buttonLabel}
        oneButton={!!buttonLabel && !twoButtons}
        confirmLabel={buttonLabel}
        twoButtons={twoButtons}
        phone={phone}
      >
        <>
          {!buttonLabel && (
            <ContentWhiteButtonModal>
              <TitleWrapper typeModal="select">
                <Title>{titleModal || label}</Title>
              </TitleWrapper>

              {items.map(({ id, name }) => (
                <ItemContainer key={String(id)} id={`${id}#_${name}`} onClick={handleClick}>
                  <Item
                    title={`Item ${name}`}
                    id={`${id}-${name}`}
                    aria-label={`Item contendo a opção ${name}`}
                    onClick={handleClick}
                  >
                    {name}
                  </Item>
                </ItemContainer>
              ))}
            </ContentWhiteButtonModal>
          )}

          {buttonLabel && (
            <>
              <TitleModal>{titleModal}</TitleModal>
              <FormSubtitle style={{ padding: '0 32px' }}>{items[0].name}</FormSubtitle>
            </>
          )}
        </>
      </Modal>
    </>
  );
};

export default memo(WhiteButtonModal);
