import { UPDATE_INFO_USER } from '../actions/actionTypes';
import { UserModel } from '../models/UserModel';

const INITIAL_STATE: UserModel = {
  customerId: '',
  xAuthorization: '',
};

const userReducer = (state = INITIAL_STATE, action: { type: string; payload: UserModel }): UserModel => {
  switch (action.type) {
    case UPDATE_INFO_USER:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default userReducer;
