/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SimulationQuoteResponse } from '../models/SimulationQuoteResponse';
import { request as __request } from '../core/request';

export class SimulationQuoteReadControllerService {

    /**
     * findAllByCustomerUuid
     * @param xCustomerUuid x-customer-uuid
     * @param acceptLanguage Accept-Language
     * @returns SimulationQuoteResponse OK
     * @throws ApiError
     */
    public static async findAllByCustomerUuidUsingGet(
        xCustomerUuid: string,
        acceptLanguage?: string,
    ): Promise<Array<SimulationQuoteResponse>> {
        const result = await __request({
            method: 'GET',
            path: `/api/personal_loans/simulation_quote`,
            headers: {
                'x-customer-uuid': xCustomerUuid,
                'Accept-Language': acceptLanguage,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * findAllByCustomerAndStatus
     * @param status status
     * @param xCustomerUuid x-customer-uuid
     * @param acceptLanguage Accept-Language
     * @returns SimulationQuoteResponse OK
     * @throws ApiError
     */
    public static async findAllByCustomerAndStatusUsingGet(
        status: string,
        xCustomerUuid: string,
        acceptLanguage?: string,
    ): Promise<Array<SimulationQuoteResponse>> {
        const result = await __request({
            method: 'GET',
            path: `/api/personal_loans/simulation/status/${status}`,
            headers: {
                'x-customer-uuid': xCustomerUuid,
                'Accept-Language': acceptLanguage,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * findByCustomerWithStatus
     * @param product product
     * @param status status
     * @param xCustomerUuid x-customer-uuid
     * @param acceptLanguage Accept-Language
     * @returns SimulationQuoteResponse OK
     * @throws ApiError
     */
    public static async findByCustomerWithStatusUsingGet(
        product: 'CP' | 'CVG',
        status: string,
        xCustomerUuid: string,
        acceptLanguage?: string,
    ): Promise<SimulationQuoteResponse> {
        const result = await __request({
            method: 'GET',
            path: `/api/personal_loans/simulation_quote/product/${product}/status/${status}`,
            headers: {
                'x-customer-uuid': xCustomerUuid,
                'Accept-Language': acceptLanguage,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * findByQuoteId
     * @param quoteId quoteId
     * @param acceptLanguage Accept-Language
     * @returns SimulationQuoteResponse OK
     * @throws ApiError
     */
    public static async findByQuoteIdUsingGet(
        quoteId: string,
        acceptLanguage?: string,
    ): Promise<SimulationQuoteResponse> {
        const result = await __request({
            method: 'GET',
            path: `/api/personal_loans/simulation_quote/${quoteId}`,
            headers: {
                'Accept-Language': acceptLanguage,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

}