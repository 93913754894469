import styled from 'styled-components/macro';

import { LayoutFormContent, PageBottom } from '../styles';
import { pxToRem } from '../../utils';

export const Container = styled(LayoutFormContent)`
  padding: 0 ${pxToRem(24)};
  background-color: ${({ theme }) => theme.colors.brandBlue400};
`;

export const Content = styled.div`
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;

  padding: ${pxToRem(110)} ${pxToRem(32)} 0;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  div {
    width: ${pxToRem(48)};
    height: ${pxToRem(53)};
  }
`;

export const IconSvg = styled.img`
  margin-bottom: ${pxToRem(24)};
  width: fit-content;
  height: fit-content;
  max-width: ${pxToRem(48)};
  max-height: ${pxToRem(53)};
  object-fit: fill;
`;

export const Bottom = styled(PageBottom)`
  padding: 0 ${pxToRem(24)};
`;
