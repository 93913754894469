import { useMemo, useState } from 'react';

import { Domain } from '../../models/domain';
import { filterByFullString, filterBySeparateWords } from '../../utils/arrayOperations';

import { WhiteButton } from '../index';
import { CleanInput } from '../InputCover/styles';
import { Container, ContainerSearch, Content, InputSearch, ItemsWrapper, SearchIcon } from './styles';

type Props = {
  action: (value) => void;
  items: Domain[];
  isSearch?: boolean;
  placeSearch?: string;
  searchBySeparateWords?: boolean;
  priorityList?: number[];
};

const SelectList = ({
  action,
  items,
  isSearch = false,
  placeSearch = 'Buscar por...',
  searchBySeparateWords = true,
  priorityList,
}: Props): JSX.Element => {
  const [inputSearch, setInputSearch] = useState('');

  const filteredSearch = useMemo(() => {
    const filteredList = items?.filter(({ name = '' }) =>
      searchBySeparateWords ? filterBySeparateWords(inputSearch, name) : filterByFullString(inputSearch, name),
    );
    if (priorityList && !inputSearch) {
      const priorityListFiltered = filteredList.filter(a => priorityList.includes(Number(a.id)));
      const priorityListOrdered = priorityListFiltered.reduce((previousValue, currentValue, currentIndex, array) => {
        if (+currentValue.id === priorityList[currentIndex]) {
          if (!previousValue) return [currentValue];
          return [...previousValue, currentValue];
        }

        const item = array.find(item => +item.id === priorityList[currentIndex]) as Domain;
        if (!previousValue) return [item];
        return [...previousValue, item];
      }, [] as Domain[]);
      const nonPriorityList = filteredList.filter(a => !priorityList.includes(Number(a.id)));
      return [...priorityListOrdered, ...nonPriorityList];
    }
    return filteredList;
  }, [inputSearch, items]);

  return (
    <>
      <Container>
        <Content>
          {isSearch && items.length > 5 && (
            <ContainerSearch>
              <InputSearch
                type="text"
                name={`search-${inputSearch}`}
                id={`search-${inputSearch}`}
                value={inputSearch}
                placeholder={placeSearch}
                aria-label={placeSearch}
                onChange={ev => setInputSearch(ev.target.value.replace(/(\*|\\|\(|\)|\+|\?|\|)/, ''))}
              />
              {!inputSearch ? <SearchIcon /> : <CleanInput onClick={() => setInputSearch('')} />}
            </ContainerSearch>
          )}
        </Content>

        <ItemsWrapper alignToTop={isSearch}>
          {filteredSearch.map(item => {
            if (!item?.id) return <></>;

            return <WhiteButton label={item.name || ''} action={() => action(item)} key={String(item.id)} />;
          })}
        </ItemsWrapper>
      </Container>
    </>
  );
};

export default SelectList;
