export const sizes = {
  mainHeaderHeight: '120px',

  /* ----SIZES WITH BASE 16PX----*/
  size1: '0.1rem',
  size2: '0.125rem',
  size3: '0.157rem',
  size4: '0.188rem',
  size5: '0.196rem',
  size6: '0.236rem',
  size7: '0.438rem',
  size8: '0.5rem',
  size10: '0.625rem',
  size11: '0.688rem',
  size12: '0.75rem',
  size13: '0.813rem',
  size14: '0.875rem',
  size15: '0.938rem',
  size16: '1rem',
  size17: '1.063rem',
  size18: '1.125rem',
  size19: '1.188rem',
  size20: '1.25rem',
  size21: '1.313rem',
  size22: '1.375rem',
  size23: '1.438rem',
  size24: '1.5rem',
  size25: '1.563rem',
  size26: '1.625rem',
  size27: '1.688rem',
  size28: '1.75rem',
  size29: '1.813rem',
  size30: '1.875rem',
  size31: '1.938rem',
  size32: '2rem',
  size33: '2.063rem',
  size34: '2.125rem',
  size35: '2.188rem',
  size36: '2.25rem',
  size40: '2.5rem',
  size44: '2.75rem',
  size48: '3rem',
  size50: '3.125rem',
  size51: '3.1875rem',
  size52: '3.25rem',
  size56: '3.5rem',
  size69: '4.3125rem',
  size70: '4.375rem',
  size98: '6.125rem',
  size224: '14rem',
  size285: '17.8125rem',
  size296: '18.5rem',
  size300: '18.75rem',
  size360: '22.5rem',
};
