import styled from 'styled-components/macro';

import { pxToRem } from '../../utils';

export const Line = styled.p`
  font-family: Nunito;
  font-weight: 400;
  font-style: normal;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(19)};
  color: ${({ theme }) => theme.colors.blueTypo};
  margin-bottom: 0;

  strong {
    font-weight: 700;
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(24)};
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueLight};
  padding: ${pxToRem(24)} ${pxToRem(32)};

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
