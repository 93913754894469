import { useEffect, useMemo, useState } from 'react';

import { Domain } from '../../../models/domain';
import { isEmpty, filterByFullString, filterBySeparateWords } from '../../../utils';

import {
  CheckInput,
  ContainerSearch,
  Content,
  ContentSearch,
  ContentYears,
  InputSearch,
  Item,
  ItemContainer,
  SearchIcon,
  Title,
  TitleWrapper,
} from './styles';
import Modal from '..';
import { Answer, ButtonInputCover, CleanInput, Container, Question } from '../../InputCover/styles';
import { BubbleButton } from '../../styles';

interface Props {
  action: (value) => void;
  tagAction?: () => void;
  tagModalShowAction?: () => void;
  answer: any;
  buttonLabel: string;
  items: Domain[];
  placeholder: string;
  title: string;
  titleModal?: string;
  isSearch?: boolean;
  placeSearch?: string;
  typeModal: 'select' | 'bubble';
  searchBySeparateWords?: boolean;
  disabled?: boolean;
  priorityList?: string[];
  priorityListKey?: 'id' | 'name';
}

const SelectModal = ({
  action,
  tagAction,
  tagModalShowAction,
  answer,
  buttonLabel,
  items,
  placeholder,
  title,
  titleModal,
  isSearch = false,
  placeSearch = 'Buscar por...',
  typeModal,
  searchBySeparateWords = true,
  disabled,
  priorityList,
  priorityListKey = 'id',
}: Props): JSX.Element => {
  const [isOpened, setIsOpened] = useState(false);
  const [inputValue, setInputValue] = useState(answer);
  const [inputSearch, setInputSearch] = useState('');

  const stateIsEmpty = isEmpty(inputValue);

  const openModal = () => {
    if (tagAction && !isOpened) {
      tagAction();
    }
    if (tagModalShowAction && !isOpened) {
      tagModalShowAction();
    }
    setIsOpened(prev => !prev);
  };

  const handleClick = ev => {
    const ripples = document.createElement('span');
    const buttonPosition = ev.target.getBoundingClientRect();
    const top = Math.abs(buttonPosition.top - ev.clientY);
    const left = Math.abs(buttonPosition.left - ev.clientX);

    ripples.style.top = `${top}px`;
    ripples.style.left = `${left}px`;

    ev.target.appendChild(ripples);

    if (disabled) return;
    setTimeout(() => {
      ripples.remove();
      setInputSearch('');
      return openModal();
    }, 300);
  };

  const handleChange = event => {
    const [id, name] = event.target.id.split('#_');

    if (inputValue === name) {
      setInputValue('');
      return action({
        id: '',
        name: '',
      });
    }

    setInputValue(name);

    action({
      id,
      name,
    });
    return handleClick(event);
  };

  const filteredSearch = useMemo(() => {
    const filteredList = items?.filter(({ name = '' }) =>
      searchBySeparateWords ? filterBySeparateWords(inputSearch, name) : filterByFullString(inputSearch, name),
    );
    if (priorityList && !inputSearch) {
      const priorityListFiltered = filteredList.filter(a => {
        const comparative = priorityListKey === 'name' && a?.name ? a.name : a?.id;
        return priorityList.includes(comparative);
      });
      const priorityListOrdered = priorityListFiltered.reduce((previousValue, currentValue, currentIndex, array) => {
        const comparative = priorityListKey === 'name' && currentValue?.name ? currentValue.name : currentValue?.id;

        if (comparative === priorityList[currentIndex]) {
          if (!previousValue) return [currentValue];
          return [...previousValue, currentValue];
        }

        const item = array.find(item => item.id === priorityList[currentIndex]) as Domain;
        if (!previousValue) return [item];
        return [...previousValue, item];
      }, [] as Domain[]);
      const nonPriorityList = filteredList.filter(a => !priorityList.includes(a.id));
      return [...priorityListOrdered, ...nonPriorityList];
    }
    return filteredList;
  }, [inputSearch, items]);

  useEffect(() => setInputValue(answer), [answer]);

  return (
    <>
      <Container>
        <div>
          <Question>{title}</Question>
          <Answer place={!answer ? placeholder : ''}>{answer || placeholder}</Answer>
        </div>
        <ButtonInputCover disabled={disabled} onClick={handleClick}>
          {answer ? 'Editar' : buttonLabel}
        </ButtonInputCover>
      </Container>

      <Modal open={isOpened} onClose={setIsOpened} buttonDisabled={stateIsEmpty} isHidden>
        <Content qtdItems={typeModal === 'bubble' ? 0 : items.length}>
          <TitleWrapper typeModal={typeModal}>
            <Title>{titleModal || title}</Title>
          </TitleWrapper>

          {(isSearch || items.length > 10) && typeModal !== 'bubble' && (
            <ContentSearch>
              <ContainerSearch>
                <InputSearch
                  type="text"
                  name={`search-${inputSearch}`}
                  id={`search-${inputSearch}`}
                  value={inputSearch}
                  placeholder={placeSearch}
                  aria-label={placeSearch}
                  onChange={ev => setInputSearch(ev.target.value.replace(/(\*|\\|\(|\)|\+|\?|\|)/, ''))}
                />
                {!inputSearch ? <SearchIcon /> : <CleanInput onClick={() => setInputSearch('')} />}
              </ContainerSearch>
            </ContentSearch>
          )}

          {typeModal === 'select' &&
            filteredSearch.map((item, index) => {
              if (!item?.id) return <></>;

              const { id, name } = item;
              return (
                <ItemContainer
                  key={`${id.toString()}${index.toString()}`}
                  selected={inputValue === name}
                  id={`${id.toString()}#_${name}`}
                  onClick={handleChange}
                >
                  <Item
                    title={`Item ${name}`}
                    id={`${id}-${name}`}
                    aria-label={`Item contendo a opção ${name}`}
                    onClick={handleChange}
                  >
                    {name}
                  </Item>
                  {inputValue === name && <CheckInput />}
                </ItemContainer>
              );
            })}

          {typeModal === 'bubble' && (
            <ContentYears>
              {items.map(({ id, name }) => (
                <BubbleButton key={id.toString()} id={`${id}#_${name}`} onClick={handleChange} selected={inputValue === name}>
                  {name}
                </BubbleButton>
              ))}
            </ContentYears>
          )}
        </Content>
      </Modal>
    </>
  );
};

export default SelectModal;
