import styled from 'styled-components/macro';

import { pxToRem } from '../../../utils';

export const ContainerErrorModal = styled.div`
  width: 100%;
  padding: 0 ${pxToRem(32)};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const TagError = styled.span`
  font-family: Nunito;
  font-weight: 600;
  font-style: normal;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(19)};

  color: ${({ theme }) => theme.colors.blue};
  opacity: 0.5;
  margin-bottom: ${pxToRem(8)};
  text-transform: lowercase;
`;

export const TitleError = styled.h1`
  font-family: Nunito;
  font-weight: 700;
  font-style: normal;
  font-size: ${pxToRem(24)};
  line-height: ${pxToRem(33)};

  color: ${({ theme }) => theme.colors.blueStrong};
  text-transform: lowercase;
`;

export const TextError = styled.p`
  font-family: Nunito;
  font-weight: 600;
  font-style: normal;
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(19)};

  color: ${({ theme }) => theme.colors.blue};
  opacity: 0.7;
  margin: ${pxToRem(16)} 0;

  a,
  a:visited {
    color: ${({ theme }) => theme.colors.blueStrong};
  }
`;
