export const pureNumber = {
  cpf: (value: string): string => {
    const regex = /([0-9]{3}).([0-9]{3}).([0-9]{3})-([0-9]{2})/;
    const subst = '$1$2$3$4';

    return value.replace(regex, subst);
  },
  ciCode: (value: string): string => {
    const regex = /^([A-Z][0-9]).([0-9][A-Z][0-9]).([A-Z]{2}[0-9]).([0-9]{3}).([0-9]{3})/;
    const subst = `$1$2$3$4$5`;

    const result = value.replace(regex, subst);

    return result;
  },
  rg: (value: string): string => {
    const regex = /([0-9]{2}).([0-9]{3}).([0-9]{3})-([0-9A-Z]{1})/;
    const subst = `$1$2$3$4`;

    return value.replace(regex, subst);
  },
  currency: (value: string): string => {
    return value
      .replace(/^[A-Z]\W /, '')
      .replace(/[.]/, '')
      .replace(/[,]/, '.');
  },
  zipCode: (value: string | undefined): string => {
    if (!value) return '';
    return value.replace(/[^0-9]/gi, '');
  },
};
