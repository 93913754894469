/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { request as __request } from '../core/request';

export class QuoteReadControllerService {

    /**
     * @param xCustomerUuid
     * @param quoteId
     * @param acceptLanguage
     * @returns any OK
     * @throws ApiError
     */
    public static async findById(
        xCustomerUuid: string,
        quoteId: string,
        acceptLanguage?: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/insurance/${quoteId}`,
            headers: {
                'x-customer-uuid': xCustomerUuid,
                'Accept-Language': acceptLanguage,
            },
        });
        return result.body;
    }

    /**
     * @param xCustomerUuid
     * @param status
     * @param acceptLanguage
     * @returns any OK
     * @throws ApiError
     */
    public static async findByCustomerWithStatus(
        xCustomerUuid: string,
        status: string,
        acceptLanguage?: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/insurance/status/${status}`,
            headers: {
                'x-customer-uuid': xCustomerUuid,
                'Accept-Language': acceptLanguage,
            },
        });
        return result.body;
    }

    /**
     * @param xCustomerUuid
     * @param acceptLanguage
     * @returns any OK
     * @throws ApiError
     */
    public static async findByCustomerId(
        xCustomerUuid: string,
        acceptLanguage?: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/insurance/customer`,
            headers: {
                'x-customer-uuid': xCustomerUuid,
                'Accept-Language': acceptLanguage,
            },
        });
        return result.body;
    }

}