/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { request as __request } from '../core/request';

export class PaymentInstrumentControllerService {

    /**
     * @param xCustomerUuid
     * @param resultQuoteId
     * @param requestBody
     * @param acceptLanguage
     * @returns any OK
     * @throws ApiError
     */
    public static async update1(
        xCustomerUuid: string,
        resultQuoteId: string,
        requestBody: any,
        acceptLanguage?: string,
    ): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/insurance/payment_instrument/result_quote/${resultQuoteId}`,
            headers: {
                'x-customer-uuid': xCustomerUuid,
                'Accept-Language': acceptLanguage,
            },
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param xCustomerUuid
     * @param resultQuoteId
     * @param requestBody
     * @param acceptLanguage
     * @returns any OK
     * @throws ApiError
     */
    public static async save4(
        xCustomerUuid: string,
        resultQuoteId: string,
        requestBody: any,
        acceptLanguage?: string,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/insurance/create/payment_instrument/result_quote/${resultQuoteId}`,
            headers: {
                'x-customer-uuid': xCustomerUuid,
                'Accept-Language': acceptLanguage,
            },
            body: requestBody,
        });
        return result.body;
    }

}