/* eslint-disable react/jsx-props-no-spreading */
import { memo, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import { Domain } from '../../models';
import { callDialer, isEmpty } from '../../utils';

import { Container, Label } from './styles';
import Button from '../Button';

interface Props {
  items: Domain[];
  handleChange: (newValue) => void;
  tagAction?: () => void;
}

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 24,
    height: 24,
    boxShadow: 'inset 0 0 0 1px #0069e7, inset 0 -1px 0 #0069e7',
    backgroundColor: '#fff',
    '$root.Mui-focusVisible &': {
      outline: '2px auto #0069E7',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#fff',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#0069E7',
    '&:before': {
      display: 'block',
      width: 24,
      height: 24,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#0069E7',
    },
  },
});

// Inspired by blueprintjs
const StyledRadio = (props: RadioProps) => {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
};

const RadioBox = ({ items, handleChange, tagAction }: Props) => {
  const [value, setValue] = useState('');

  const setValueRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    handleChange((event.target as HTMLInputElement).value);
  };

  return (
    <>
      <FormControl component="fieldset">
        <RadioGroup value={value} aria-label="insurances" name="customized-radios" onChange={setValueRadio}>
          {items.map(({ id, name }) => (
            <Container key={`insurance${id}`}>
              <Label>{name}</Label>
              <FormControlLabel value={id} control={<StyledRadio />} label="" />
            </Container>
          ))}
        </RadioGroup>
      </FormControl>
      <a>
        <Button
          paddingY={24}
          disabled={isEmpty(value)}
          onClick={() => {
            if (value) callDialer(value);
            if (tagAction) tagAction();
          }}
        >
          Ligar e contratar
        </Button>
      </a>
    </>
  );
};

export default memo(RadioBox);
