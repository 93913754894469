import styled, { keyframes } from 'styled-components/macro';

import { pxToRem } from '../../utils';

const animate = keyframes`
  0% {
    width: 0px;
    height: 0px;
    opacity: 0.5;
  }
  100% {
    width: ${pxToRem(500)};
    height: ${pxToRem(500)};
    opacity: 0;
  }
`;

export const Container = styled.button<{
  icon?: boolean;
  noPaddingX?: boolean;
}>`
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: ${pxToRem(112)};
  border: none;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueLight};
  padding: ${pxToRem(24)} ${({ noPaddingX }) => pxToRem(noPaddingX ? 0 : 32)};

  overflow: hidden;
  position: relative;

  .imgBackgroud {
    background-color: ${({ theme }) => theme.colors.greyBackground};
    border: 1px solid ${({ theme }) => theme.colors.greyBackground};
    border-radius: ${pxToRem(8)};
    width: ${pxToRem(40)};
    height: ${pxToRem(40)};
    pointer-events: none;
  }

  div {
    width: ${({ icon }) => pxToRem(icon ? 215 : 280)};
    pointer-events: none;
  }

  span {
    position: absolute;
    background-color: #000;

    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    pointer-events: none;
    border-radius: 50%;

    -webkit-animation: ${animate} 1s linear infinite;
    animation: ${animate} 1s linear infinite;
  }

  &.blueButtonDescription {
    margin: 0 ${pxToRem(32)};
    background: transparent;
    border: 2px solid #6f8fa7;
    border-radius: ${pxToRem(24)};
    width: auto;
    padding: 0 ${pxToRem(24)};
    margin-bottom: ${pxToRem(16)};
  }
`;

export const ContainerWithInfo = styled.div<{ noPaddingX?: boolean }>`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueLight};
  padding: 0 ${({ noPaddingX }) => pxToRem(noPaddingX ? 0 : 32)};

  button {
    border-bottom: 0;
    padding-bottom: ${pxToRem(12)};
    min-height: fit-content;
  }

  button + div {
    margin-bottom: 24px;
  }
`;

export const Label = styled.p<{ secondaryColor?: boolean }>`
  font-family: Nunito;
  font-size: ${pxToRem(16)};
  font-style: normal;
  font-weight: 600;
  line-height: ${pxToRem(22)};
  text-align: left;
  color: ${p => (p.secondaryColor ? ({ theme }) => theme.colors.blueTypo : ({ theme }) => theme.colors.blueStrong)};
  margin-bottom: ${pxToRem(12)};
  &.blueButtonDescription {
    color: #ffffff;
    font-size: ${pxToRem(14)};
  }
`;

export const DescriptionLine = styled.p<{ icon?: boolean }>`
  font-family: Nunito;
  font-weight: 400;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(19)};
  color: ${({ theme, icon }) => (icon ? theme.colors.blueTypo : theme.colors.blue)};
  margin-bottom: ${({ icon }) => pxToRem(icon ? 0 : 6)};
  margin-right: 0;
  padding: 0;
  text-align: left;
  max-width: 95%;
  &.blueButtonDescription {
    color: #ffd000;
    font-size: ${pxToRem(24)};
    font-weight: bold;
  }
`;

export const DescriptionLineAlert = styled(DescriptionLine)`
  font-weight: 600;
  letter-spacing: ${pxToRem(-0.4375)};
  color: ${({ theme }) => theme.colors.orange};
`;

export const ButtonLink = styled.p`
  font-family: Nunito;
  font-size: ${pxToRem(14)};
  font-weight: 700;
  line-height: ${pxToRem(19)};
  color: ${({ theme }) => theme.colors.blueStrong};
  text-align: left;
`;
