import { ADD_CLIENT } from '../actions';
import { CarInfoInsurance, ClientInfoInsurance, InsuranceInfo, ProposalStoreModel, QuoteStoreModel } from '../models';

export type InsuranceModel = {
  proposal?: ProposalStoreModel;
  quotes?: QuoteStoreModel[];
  currentQuote?: QuoteStoreModel;
  insurance?: string;
  insurances?: string[];
};

const INITIAL_STATE: InsuranceModel = {
  proposal: {} as ProposalStoreModel,
  quotes: [],
  currentQuote: {
    quoteId: '',
    client: {} as ClientInfoInsurance,
    insurance: {} as InsuranceInfo,
    car: {} as CarInfoInsurance,
  },
};

const insuranceReducer = (state = INITIAL_STATE, action: { type: string; payload: InsuranceModel }): InsuranceModel => {
  switch (action.type) {
    case ADD_CLIENT:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default insuranceReducer;
