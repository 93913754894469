import styled from 'styled-components/macro';

import { pxToRem } from '../../utils';

export const Container = styled.button`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: ${pxToRem(12)} ${pxToRem(32)};
  border: none;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueLight};

  &.haveToInvert {
    padding: ${pxToRem(12)} 0;
    justify-content: flex-start;
  }
`;

export const Label = styled.p`
  font-family: Nunito;
  font-size: ${pxToRem(16)};
  font-style: normal;
  font-weight: 600;
  line-height: ${pxToRem(16)};
  letter-spacing: ${pxToRem(-0.4375)};
  text-align: left;
  color: ${({ theme }) => theme.colors.blueTypo};
  margin-top: revert;

  &.alertLabel {
    color: ${({ theme }) => theme.colors.orange} !important;
  }

  &.disabled {
    color: ${({ theme }) => theme.colors.blueTypo}1F;
  }
`;
