import dayjs from 'dayjs';

export const addZero = (value: number) => (value <= 9 ? `0${value}` : value);

export const formatDate = {
  toDateOnlyISO: (value: Date | undefined | string) => {
    if (!value) return '';
    if (typeof value === 'string') {
      return value.split(' ')[0].split('/').reverse().join('-');
    }
    return new Date(value).toISOString().split('T')[0];
  },
  toRequest: (value: Date | undefined | string) => {
    if (!value) return '';
    if (typeof value === 'string') {
      return value.split(' ')[0].split('/').reverse().join('-');
    }
    return new Date(value).toLocaleString().substring(0, 10);
  },
  toPTBR: (value: Date | undefined | string) => {
    if (!value) return '';
    if (typeof value === 'string') {
      if (value.match(/-/gm)?.length === 2) {
        const valueSplited = value.split(' ')[0].split('-');
        return valueSplited[0].length === 4 ? valueSplited.reverse().join('/') : valueSplited.join('/');
      }

      return value.split(' ')[0].split('/').reverse().join('/');
    }
    return new Date(value).toLocaleDateString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit' });
  },
  toPTBRDayJs: (value: Date | undefined | string) => {
    if (!value) return '';
    if (typeof value === 'string') {
      return dayjs(formatDate.toDateOnlyISO(value)).format('DD/MM/YYYY');
    }
    return new Date(value).toLocaleDateString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit' });
  },
  ISOtoDate: (stringDate: string | undefined): Date | undefined => {
    if (!stringDate) return undefined;
    return dayjs(stringDate, 'YYYY-MM-DD').toDate();
  },
  PTBRtoDate: (stringDate: string | undefined): Date | undefined => {
    if (!stringDate) return undefined;
    return dayjs(stringDate, 'DD/MM/YYYY').toDate();
  },
  dateInFull: (value: string | Date | undefined) => {
    if (!value) return '';

    const data = typeof value === 'string' ? new Date(value) : value;
    return data.toLocaleString('pt-BR', { year: 'numeric', month: 'short', day: '2-digit' });
  },
};

export const isDateAfter = (referenceDate: string | undefined, targetDateString?: string | undefined) => {
  if (referenceDate?.trim().length !== 10) return false;

  const referenceFormatted = formatDate.toDateOnlyISO(referenceDate);
  const targetFormatted = formatDate.toDateOnlyISO(targetDateString ?? new Date());
  const targetDate = dayjs(targetFormatted);
  return targetDate.isAfter(referenceFormatted, 'd');
};

export const isDateBefore = (referenceDate: string | undefined, targetDateString?: string | undefined) => {
  if (referenceDate?.trim().length !== 10) return false;

  const limitYear = dayjs().subtract(100, 'y').format('YYYY-MM-DD');
  const referenceFormatted = formatDate.toDateOnlyISO(referenceDate ?? new Date());
  const referenceIsLessThanTarget = referenceFormatted.substring(0, 4) < limitYear.substring(0, 4);
  if (referenceIsLessThanTarget) return false;

  const targetFormatted = formatDate.toDateOnlyISO(targetDateString || new Date());
  const targetDate = dayjs(targetFormatted);

  return targetDate.isBefore(referenceFormatted, 'd');
};

export const getNextWorkingDay = () => {
  let nextDay = dayjs().add(1, 'day');
  while (nextDay.day() === 0 || nextDay.day() === 6) {
    nextDay = nextDay.add(1, 'day');
  }
  return nextDay;
};
