/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { request as __request } from '../core/request';

export class CoverageQuoteControllerService {

    /**
     * @param xCustomerUuid
     * @param quoteId
     * @param requestBody
     * @param acceptLanguage
     * @returns any OK
     * @throws ApiError
     */
    public static async saveCoverage(
        xCustomerUuid: string,
        quoteId: string,
        requestBody: any,
        acceptLanguage?: string,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/insurance/create/coverage/quote/${quoteId}`,
            headers: {
                'x-customer-uuid': xCustomerUuid,
                'Accept-Language': acceptLanguage,
            },
            body: requestBody,
        });
        return result.body;
    }

}