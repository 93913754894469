import { memo, useCallback, useState } from 'react';

import { ReactComponent as AngleDown } from '../../assets/icons/insurance/payment/angle-down.svg';
import { ReactComponent as AngleUp } from '../../assets/icons/insurance/payment/angle-up.svg';
import { DetailSignProps, DoubleLineProps, SimpleLineProps, SummaryProps } from '../../data';
import theme from '../../styles/theme';
import { formatNumber, pxToRem } from '../../utils';

import InfoLine from '../InfoLine';
import { ContainerAccordion, ContentTitle, ContentValues, Text, TextTotal, Title, Value, ValueTotal } from './styles';

type Props = {
  summaries: SummaryProps[] | DetailSignProps[];
  title: string;
  total?: string;
  isOpen?: boolean;
  simpleLines?: SimpleLineProps[];
  doubleLines?: DoubleLineProps[];
  onClick?: () => void;
};

const AccordionPayment = ({ title, summaries, total, isOpen, simpleLines, doubleLines, onClick }: Props): JSX.Element => {
  const [show, setShow] = useState(isOpen ?? true);

  const toggleShow = () => {
    if (onClick) onClick();
    setShow(!show);
  };

  const isPercent = useCallback((value: string | number) => `${Number(value).toFixed(2)}%`, []);
  const isNumber = useCallback((value: string | number) => Number(value), []);
  const renderValue = useCallback((value: string | number, valueType?: string) => {
    if (valueType === 'PERCENT') return isPercent(value);
    if (valueType === 'NUMBER') return isNumber(value);
    return formatNumber.currency(value);
  }, []);

  return (
    <ContainerAccordion show={show}>
      <ContentTitle onClick={toggleShow}>
        <Title>{title}</Title>
        {show ? (
          <AngleUp style={{ width: pxToRem(32), height: pxToRem(32), color: theme.colors.blueTypo }} />
        ) : (
          <AngleDown style={{ width: pxToRem(32), height: pxToRem(32), color: theme.colors.blueTypo }} />
        )}
      </ContentTitle>
      {show &&
        summaries.map(({ text, value, valueType }: SummaryProps | DetailSignProps) => (
          <ContentValues key={`${text}${valueType}`}>
            <Text>{text}</Text>
            <Value deduction={valueType === 'DEDUCTION'}>{renderValue(value, valueType)}</Value>
          </ContentValues>
        ))}

      {show && total && (
        <ContentValues>
          <TextTotal>Vai pagar</TextTotal>
          <ValueTotal>{`${total}/mês`}</ValueTotal>
        </ContentValues>
      )}

      {show && (simpleLines || doubleLines) && <InfoLine simpleLines={simpleLines} doubleLines={doubleLines} />}
    </ContainerAccordion>
  );
};

export default memo(AccordionPayment);
