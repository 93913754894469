import preval from 'preval.macro';
import { BridgeModel, LoanTypeEnum } from '../models';
import { NativeRoutes } from '../data';
import store from '../store/store';
import { isDevEnv } from './environment';

declare const window: Window &
  typeof globalThis & {
    ReactNativeWebView: any;
    JSBridge: any;
    webkit: any;
  };

let customerId = '';

const getCustomerId = () => {
  if (!customerId) {
    customerId = store?.getState()?.user?.customerId as string;
  }
  return customerId;
};

const setCustomerId = (payload: BridgeModel): BridgeModel => {
  const payloadWithCustomerId: BridgeModel = { ...payload };

  if (payload.action === 'analytics') {
    if (payloadWithCustomerId?.data) {
      payloadWithCustomerId.data.parameters.clientId = getCustomerId();
    } else {
      payloadWithCustomerId.data = {
        parameters: {
          clientId: customerId,
        },
      };
    }
  }
  return payloadWithCustomerId;
};

export const sendMessageToNative = (payload: BridgeModel) => {
  setCustomerId(payload);
  const json = JSON.stringify(payload, null, 4);
  console.log(`mensagem enviada para o App Nativo: ${json}`);

  const { JSBridge, ReactNativeWebView, webkit } = window;
  if (!JSBridge && !ReactNativeWebView && webkit) {
    webkit.messageHandlers.observer.postMessage(json);
  }

  if (JSBridge && !webkit && !ReactNativeWebView) {
    if (payload.action === 'close') {
      JSBridge.closePWA(json);
    } else if (payload.action === 'analytics') {
      JSBridge.analytics(json);
    } else if (payload.action === 'finish') {
      JSBridge.finish(json);
    } else if (payload.action === 'navigation') {
      JSBridge.navigate(json);
    } else if (payload.action === 'clipboard') {
      JSBridge.clipboard(json);
    } else if (payload.action === 'dialer') {
      JSBridge.dialer(json);
    }
  }

  if (!JSBridge && !webkit && ReactNativeWebView) {
    ReactNativeWebView.postMessage(json);
  }
};

export const returnToNative = (action?: 'finish') => {
  sendMessageToNative({ action: action || 'close' });
  if (isDevEnv()) return;
  window.close();
};

export const callDialer = (telephoneNumber = '') => {
  const parameters: any = { content: telephoneNumber.replace(/\D/g, '') };
  sendMessageToNative({ action: 'dialer', data: { parameters } });
};

export const copyToClipboard = (content = '') => {
  const parameters: any = { content };
  sendMessageToNative({ action: 'clipboard', data: { parameters } });
};

export const returnToNativeWithNavigation = (route?: NativeRoutes | string, external?: boolean) => {
  if (external) window.open(route);
  const parameters: any = external ? { url: route } : { target: route };
  sendMessageToNative({ action: 'navigation', data: { parameters } });
};

export const sendTagMessageToNative = (event: string, parametersMap?: Map<string, any>) => {
  const eventType = event.toLowerCase().substring(0, 40);
  const parameters = {};
  parametersMap?.forEach((value: boolean, key: string) => {
    parameters[key] = value;
  });
  const tagMessage: BridgeModel = { action: 'analytics', data: { eventType, parameters } };
  sendMessageToNative(tagMessage);
};

export type ITagProps = {
  product: 'insur' | 'kyc' | 'cp' | LoanTypeEnum;
  value: string;
  suffix?: string;
};

export const tag: ITagProps = {
  product: 'insur',
  value: 'main',
};

export const sendChoose = ({ product, value, suffix }: ITagProps, parametersMap?: Map<string, any>) => {
  const eventType = `choose_${product}_${value}${suffix || ''}`;
  sendTagMessageToNative(eventType, parametersMap);
};

export const sendShow = ({ product, value, suffix }: ITagProps) => {
  const eventType = `show_${product}_${value}${suffix || ''}`;
  sendTagMessageToNative(eventType);
};

export const sendTap = ({ product, value, suffix }: ITagProps) => {
  const eventType = `tap_${product}_${value}${suffix || ''}`;
  sendTagMessageToNative(eventType);
};

export const sendTapAhead = ({ product, value, suffix }: ITagProps) => {
  const eventType = `tap_ahead_${product}_${value}${suffix || ''}`;
  sendTagMessageToNative(eventType);
};

export const sendTapBack = ({ product, value, suffix }: ITagProps) => {
  const eventType = `tap_back_${product}_${value}${suffix || ''}`;
  sendTagMessageToNative(eventType);
};

export const sendTapClose = ({ product, value, suffix }: ITagProps) => {
  const eventType = `tap_close_${product}_${value}${suffix || ''}`;
  sendTagMessageToNative(eventType);
};

export const sendTapEdit = ({ product, value, suffix }: ITagProps) => {
  const eventType = `tap_edit_${product}_${value}${suffix || ''}`;
  sendTagMessageToNative(eventType);
};

export const isIphoneAgent = (): boolean => {
  const { userAgent } = window.navigator;
  return userAgent.includes('iPhone') || userAgent.includes('Mac OS');
};

export const getBuildTime = (): string => {
  return preval`module.exports = new Date().toLocaleString('pt-BR');`;
};
