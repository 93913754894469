import { lazy } from 'react';

/* Payment */
export const PreferToPay = lazy(() => import('../pages/Insurance/Payment/PreferToPay'));
const HowManyTimes = lazy(() => import('../pages/Insurance/Payment/HowManyTimes'));
const Summary = lazy(() => import('../pages/Insurance/Payment/Summary'));
const FormPayment = lazy(() => import('../pages/Insurance/Payment/FormPayment'));
const Benefits = lazy(() => import('../pages/Insurance/Payment/Benefits'));

export const PAYMENT = [
  { path: '/payment', component: PreferToPay },
  { path: '/payment/howManyTimes', component: HowManyTimes },
  { path: '/payment/summary', component: Summary },
  { path: '/payment/summary/:payment', component: Summary },
  { path: '/payment/formPayment', component: FormPayment },
  { path: '/payment/benefits', component: Benefits },
];
