import { UPDATE_INFO_USER } from './actionTypes';
import { UserModel } from '../models';

interface ActionProps {
  type: string;
  payload: UserModel;
}

export const changeUserAction = (user: UserModel): ActionProps => {
  return {
    type: UPDATE_INFO_USER,
    payload: user,
  };
};
