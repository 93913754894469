export const capitalizeFirstLetter = (string = '') => {
  return ['/', '-'].includes(string.charAt(0))
    ? string.charAt(0) + string.charAt(1).toUpperCase() + string.toLowerCase().slice(2)
    : string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
};

export const capitalizeEveryFirstLetter = (string = '', minLengthToCapitalize?: number, truncLength = false) => {
  const words = string.split(/(\s|\s+|\/[a-zA-Z]{2,8}|-[a-zA-Z]{2,8})/gm);

  const capitalizedWord = words
    .filter(word => !!word.trim())
    .map(value => {
      if (truncLength && minLengthToCapitalize && value.length <= minLengthToCapitalize) {
        return value;
      }
      if (minLengthToCapitalize && value.length <= minLengthToCapitalize) {
        return value.toLowerCase();
      }
      if (value.match(/(S\/A|S\.A)/gm)) return value.toUpperCase();
      return capitalizeFirstLetter(value.replace(/(\s|\s+)/, '').trimEnd()).replace(/(\s|\s+)/, '');
    });

  return capitalizedWord.join(' ').trimEnd();
};

export const hideMiddleEmail = (value: string | undefined): string => {
  if (!value) return '';
  return value.replace(/(^\S{4})(\S+)(@\S+\.\S+$)/, '$1***$3');
};

export const normalizeString = (value: string | undefined): string => {
  if (!value) return '';
  return value
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
};

export const removeSpecialCharacters = (value: string | undefined): string => {
  if (!value) return '';
  return value.replace(/[^a-z0-9]/gi, '');
};

export const maskedEmail = (value?: string) => {
  if (!value) return '';

  const regex = /(^\w{3})(.*)+@/g;
  const matchValue = value.match(regex) || [''];
  const minusThreeFirstLetters = matchValue[0].substring(3);
  const { length } = minusThreeFirstLetters;
  const replacedValue = '@'.padStart(length, '*');
  const prepareValue = value.replace(minusThreeFirstLetters, replacedValue);

  return prepareValue;
};
