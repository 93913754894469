/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreditOfferRequest } from '../models/CreditOfferRequest';
import type { CreditOfferResponse } from '../models/CreditOfferResponse';
import type { SimulationQuoteRequest } from '../models/SimulationQuoteRequest';
import type { SimulationQuoteResponse } from '../models/SimulationQuoteResponse';
import { request as __request } from '../core/request';

export class SimulationQuoteControllerService {

    /**
     * creditOfferCP
     * @param request request
     * @param acceptLanguage Accept-Language
     * @returns CreditOfferResponse OK
     * @returns any Created
     * @throws ApiError
     */
    public static async creditOfferCpUsingPost(
        request: CreditOfferRequest,
        acceptLanguage?: string,
    ): Promise<CreditOfferResponse | any> {
        const result = await __request({
            method: 'POST',
            path: `/api/personal_loans/create/offer-credit/product/CP`,
            headers: {
                'Accept-Language': acceptLanguage,
            },
            body: request,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * creditOfferCVG
     * @param request request
     * @param acceptLanguage Accept-Language
     * @returns CreditOfferResponse OK
     * @returns any Created
     * @throws ApiError
     */
    public static async creditOfferCvgUsingPost(
        request: CreditOfferRequest,
        acceptLanguage?: string,
    ): Promise<CreditOfferResponse | any> {
        const result = await __request({
            method: 'POST',
            path: `/api/personal_loans/create/offer-credit/product/CVG`,
            headers: {
                'Accept-Language': acceptLanguage,
            },
            body: request,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * createQuote
     * @param product product
     * @param quotePartnerId quotePartnerId
     * @param xCustomerUuid x-customer-uuid
     * @param acceptLanguage Accept-Language
     * @returns SimulationQuoteResponse OK
     * @returns any Created
     * @throws ApiError
     */
    public static async createQuoteUsingPost(
        product: string,
        quotePartnerId: string,
        xCustomerUuid: string,
        acceptLanguage?: string,
    ): Promise<SimulationQuoteResponse | any> {
        const result = await __request({
            method: 'POST',
            path: `/api/personal_loans/simulation_quote/create/${product}/${quotePartnerId}`,
            headers: {
                'x-customer-uuid': xCustomerUuid,
                'Accept-Language': acceptLanguage,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * updateQuote
     * @param request request
     * @param simulationQuoteId simulationQuoteId
     * @param xCustomerUuid x-customer-uuid
     * @param acceptLanguage Accept-Language
     * @returns SimulationQuoteResponse OK
     * @returns any Created
     * @throws ApiError
     */
    public static async updateQuoteUsingPut(
        request: SimulationQuoteRequest,
        simulationQuoteId: string,
        xCustomerUuid: string,
        acceptLanguage?: string,
    ): Promise<SimulationQuoteResponse | any> {
        const result = await __request({
            method: 'PUT',
            path: `/api/personal_loans/simulation_quote/${simulationQuoteId}`,
            headers: {
                'x-customer-uuid': xCustomerUuid,
                'Accept-Language': acceptLanguage,
            },
            body: request,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

}