/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { VehicleRequest } from '../models/VehicleRequest';
import type { VehicleResponse } from '../models/VehicleResponse';
import { request as __request } from '../core/request';

export class VehicleControllerService {

    /**
     * saveVehicle
     * @param request request
     * @param simulationQuoteId simulationQuoteId
     * @param xCustomerUuid x-customer-uuid
     * @param acceptLanguage Accept-Language
     * @returns VehicleResponse OK
     * @returns any Created
     * @throws ApiError
     */
    public static async saveVehicleUsingPost(
        request: VehicleRequest,
        simulationQuoteId: string,
        xCustomerUuid: string,
        acceptLanguage?: string,
    ): Promise<VehicleResponse | any> {
        const result = await __request({
            method: 'POST',
            path: `/api/personal_loans/create/vehicle/simulation/${simulationQuoteId}`,
            headers: {
                'x-customer-uuid': xCustomerUuid,
                'Accept-Language': acceptLanguage,
            },
            body: request,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * updateVehicle
     * @param request request
     * @param simulationQuoteId simulationQuoteId
     * @param vehicleId vehicleId
     * @param xCustomerUuid x-customer-uuid
     * @param acceptLanguage Accept-Language
     * @returns VehicleResponse OK
     * @returns any Created
     * @throws ApiError
     */
    public static async updateVehicleUsingPut(
        request: VehicleRequest,
        simulationQuoteId: string,
        vehicleId: string,
        xCustomerUuid: string,
        acceptLanguage?: string,
    ): Promise<VehicleResponse | any> {
        const result = await __request({
            method: 'PUT',
            path: `/api/personal_loans/vehicle/${vehicleId}/simulation/${simulationQuoteId}`,
            headers: {
                'x-customer-uuid': xCustomerUuid,
                'Accept-Language': acceptLanguage,
            },
            body: request,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

}