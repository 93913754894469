import styled from 'styled-components/macro';
import { Button } from 'react-bootstrap';
import { pxToRem } from '../../utils/pxToRem';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueLight};
  border-top: 1px solid ${({ theme }) => theme.colors.blueLight};
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  padding: ${pxToRem(5)} ${pxToRem(32)};
  margin: ${pxToRem(0)};
  margin-top: ${pxToRem(24)};
  overflow: auto;
`;

export const ButtonInline = styled(Button)`
  min-width: ${pxToRem(64)};
  color: ${({ theme }) => theme.colors.blueStrong};
  background: ${({ theme }) => theme.colors.white};
  height: ${pxToRem(40)};
  border: 2px solid ${({ theme }) => theme.colors.blueLight};
  border-radius: ${pxToRem(29)};
  padding: ${pxToRem(6)} ${pxToRem(12)};
  margin-right: ${pxToRem(5)};
  overflow: hidden;
  position: relative;
  &:focus,
  &:hover,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):active {
    color: ${({ theme }) => theme.colors.white} !important;
    background-color: ${({ theme }) => theme.colors.blueStrong} !important;
    border-color: ${({ theme }) => theme.colors.blueLight} !important;
  }
`;

export const Warning = styled.p`
  font-family: Nunito;
  font-size: ${pxToRem(12)};
  font-weight: 400;
  line-height: ${pxToRem(16)};
  color: ${({ theme }) => theme.colors.blueTypo};
  text-align: left;
  width: 100%;
  padding: ${pxToRem(32)} ${pxToRem(32)} ${pxToRem(24)};
`;
export const ValueLabel = styled.p`
  font-family: Nunito;
  font-size: ${pxToRem(24)};
  font-weight: 700;
  line-height: ${pxToRem(33)};
  color: ${({ theme }) => theme.colors.blueTypo};
  text-align: left;
  width: 100%;
`;

export const Title = styled.p`
  font-family: Nunito;
  font-weight: 400;
  font-style: normal;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(19.1)};
  color: ${({ theme }) => theme.colors.blueTypo};
  margin-top: ${pxToRem(24)};
`;

export const Description = styled.p`
  font-family: Nunito;
  font-weight: 400;
  font-style: normal;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(19.1)};
  color: ${({ theme }) => theme.colors.blueTypo};
`;
