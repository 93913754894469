import { ResultQuoteResponse } from '../../api/generated/personal-loans';
import { Domain } from '../domain';

export type Loan = {
  loanType?: LoanTypeEnum;
  preApproved?: boolean;
  paymentType?: PaymentTypeEnum;
  wishLoanValue?: number;
  maximumPreApprovedValue?: number;
  installmentPlan?: Domain;
  maximumInstallmentsNumber?: number;
  installmentValue?: number;
  totalValueWithInterest?: number;
  installmentMonthlyInterest?: number;
  installmentAnualyInterest?: number;
  valuePercentCetMonth?: number;
  valuePercentCetAnnual?: number;
  installmentDueDayOfMonth?: number;
  installmentFirstPaymentDate?: Date;
  resultQuote?: ResultQuoteResponse;
};

// eslint-disable-next-line no-shadow
export enum LoanTypeEnum {
  CP = 'CP',
  CVG = 'CVG',
  ALL = 'ALL',
}

export const stringToLoanTypeEnum = (loanType = ''): LoanTypeEnum | undefined => {
  if (loanType.toUpperCase() === LoanTypeEnum.CP.toString()) {
    return LoanTypeEnum.CP;
  }
  if (loanType.toUpperCase() === LoanTypeEnum.CVG.toString()) {
    return LoanTypeEnum.CVG;
  }
  return undefined;
};

// eslint-disable-next-line no-shadow
export enum PaymentTypeEnum {
  BILLET = '0',
  AUTOMATIC_DEBIT = '1',
}
