import styled from 'styled-components/macro';
import { ReactComponent as CheckedSVG } from '../../assets/icons/checked.svg';
import { ReactComponent as WarningSVG } from '../../assets/icons/warning.svg';
import { pxToRem } from '../../utils';

export const Dot = styled.div<{ activated?: boolean; isContract?: boolean }>`
  height: ${pxToRem(21)};
  width: ${pxToRem(21)};
  font-weight: bold;
  color: ${({ activated, isContract }) => {
    if (activated && isContract) return '#FF6000';
    return !activated ? '#7D9AB3' : '#0045a5';
  }};
  border-style: solid;
  border-width: 1px;
  border-color: ${({ activated, isContract }) => {
    if (activated && isContract) return '#FF6000';
    return !activated ? '#7D9AB3' : '#0045a5';
  }};
  border-radius: ${pxToRem(20)};
  background-color: #ffffff;
  text-align: center;
  border-radius: 50%;
  margin: auto;
  z-index: 3;
  font-size: ${pxToRem(13)};
`;

export const Checked = styled(CheckedSVG).attrs({
  height: pxToRem(21),
  width: pxToRem(21),
})`
  path {
    fill: #00892e;
  }
`;
export const DotChecked = styled(Checked)`
  background-color: #ffffff;
  z-index: 3;
  border-radius: 50%;
`;

export const Warning = styled(WarningSVG).attrs({
  height: pxToRem(21),
  width: pxToRem(21),
})`
  path {
    fill: #ff6000;
  }
`;
export const DotWarning = styled(Warning)`
  background-color: #ffffff;
  z-index: 3;
  border-radius: 50%;
`;

export const StepperWrapper = styled.div`
  margin: ${pxToRem(25)} 0 ${pxToRem(20)};
  display: flex;
  justify-content: space-between;
`;

export const StepperItem = styled.div<{ checked?: boolean; active?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  &::before,
  &::after {
    position: absolute;
    content: '';
    border-bottom: 5px solid #b3c4d0;
    width: 100%;
    top: ${pxToRem(9)};
    left: -50%;
    z-index: 2;
  }

  &::after {
    left: 50%;
    border-color: ${({ checked }) => {
      return checked ? '#00892e' : '#b3c4d0';
    }};
  }

  &::before {
    border-color: ${({ checked, active }) => {
      return checked || active ? '#00892e' : '#b3c4d0';
    }};
  }

  &.first::before {
    content: none;
  }

  &.last::after {
    content: none;
  }

  &.checked {
    background-color: blue;
  }

  @media (min-width: 540px) {
    flex: 1;
  }
`;

export const StepCounter = styled.div`
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${pxToRem(40)};
  height: ${pxToRem(40)};
  border-radius: 50%;
  background: #ccc;
  margin-bottom: ${pxToRem(6)};
`;

export const StepName = styled.div<{ activated?: boolean; checked?: boolean; warning?: boolean }>`
  font-weight: bold;
  color: ${({ checked, activated, warning }) => {
    if (checked) {
      return '#00892e';
    }
    if (activated && warning) {
      return '#ff6000';
    }
    return activated ? '#0045a5' : '#7D9AB3';
  }};
`;
