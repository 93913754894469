import styled from 'styled-components/macro';

import { pxToRem } from '../../utils';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  height: ${pxToRem(73)};
  label.MuiFormControlLabel-root {
    margin-right: ${pxToRem(8)};
  }
`;

export const Label = styled.p`
  font-family: Nunito;
  font-weight: 600;
  font-style: normal;
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(24)};
  padding-bottom: ${pxToRem(6)};
  text-align: left;
  color: ${({ theme }) => theme.colors.blueTypo};
`;

export const FormControlLabel = styled.label`
  font-family: Nunito;
  font-weight: 600;
  font-style: normal;
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(24)};
  padding-bottom: ${pxToRem(6)};
  text-align: left;
  color: ${({ theme }) => theme.colors.blueTypo};
`;
