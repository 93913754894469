import styled from 'styled-components/macro';

import { Answer, Question } from '../../InputCover/styles';
import { FormSubtitle, FormTitle } from '../../styles';
import { pxToRem } from '../../../utils';

export const Title = styled(FormTitle)`
  margin: ${pxToRem(8)} ${pxToRem(32)};
`;

export const SubTitle = styled(FormSubtitle)`
  margin: 0 ${pxToRem(32)};
`;

export const Image = styled.img`
  width: 100%;
  max-height: ${pxToRem(172)};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${pxToRem(24)} auto;
`;

export const QuestionStatic = styled(Question)`
  font-weight: bold;
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(24)};
  opacity: 1;
  color: ${({ theme }) => theme.colors.blueTypo};
  order: 0;
`;

export const AnswerStatic = styled(Answer)`
  font-weight: normal;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(24)};
  color: ${({ theme }) => theme.colors.blueTypo};
  order: 1;
`;
