import styled from 'styled-components/macro';

import { ReactComponent as BvHeaderSVG } from '../assets/icons/bvHeader.svg';
import { ReactComponent as MapfreHeaderSVG } from '../assets/icons/insurance/brands/mapfreHeader.svg';
import { ReactComponent as TokyoHeaderSVG } from '../assets/icons/insurance/brands/tokyoHeader.svg';
import { ReactComponent as ThumbsUpSVG } from '../assets/icons/thumbsUp.svg';
import { ReactComponent as ApoliceSVG } from '../assets/icons/apolice.svg';
import { pxToRem } from '../utils';
import { Button } from './Modal/styles';
import { ButtonInputCover } from './InputCover/styles';

export const FormTitle = styled.p<{ padding?: number }>`
  font-family: Nunito;
  font-style: normal;
  font-weight: 700;
  font-size: ${pxToRem(24)};
  line-height: ${pxToRem(32)};
  letter-spacing: 0;
  text-align: left;

  color: ${({ theme }) => theme.colors.blueTypo};
  margin: ${pxToRem(8)} 0;

  &:first-letter {
    text-transform: uppercase;
  }

  padding: 0 ${p => (p.padding ? pxToRem(p.padding) : '')};

  &.white {
    color: ${({ theme }) => theme.colors.white};
  }

  &.answer {
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(24)};
    margin-bottom: 0;
  }
`;

export const Content = styled.div<{ paddingY?: number }>`
  padding: ${p => (p.paddingY ? pxToRem(p.paddingY) : '0')} ${pxToRem(32)};

  ol,
  ul {
    margin: ${pxToRem(20)} 0 0;
    padding-left: ${pxToRem(16)};

    li {
      font-family: Nunito;
      font-style: normal;
      font-weight: 400;
      font-size: ${pxToRem(16)};
      line-height: ${pxToRem(24)};
      text-align: left;
      color: ${({ theme }) => theme.colors.blueTypo};

      &.right {
        font-weight: 700;
        text-align: right;
        margin-left: auto;
      }
    }
  }

  &.mb-24 {
    margin-bottom: ${pxToRem(24)};
  }
`;

export const ContentText = styled.p`
  font-weight: 400;
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(22)};
`;

export const FormSubtitle = styled.p`
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(22)};
  letter-spacing: 0;
  text-align: left;
  color: ${({ theme }) => theme.colors.blueTypo};

  &.bold {
    font-weight: 700;
  }

  &.right {
    font-weight: 700;
    text-align: right;
    margin-left: auto;
  }

  &.mt-32 {
    margin-top: ${pxToRem(32)};
  }

  &.question {
    font-weight: 600;
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(16)};

    color: ${({ theme }) => theme.colors.blue};
    mix-blend-mode: normal;
    opacity: 0.65;

    margin-bottom: ${pxToRem(5)};
  }

  &.size-259 {
    min-height: ${pxToRem(259)};
  }
`;

export const BottomButtonsContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  padding: ${pxToRem(32)};

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const PageBottom = styled.div<{ paddingX?: number; noMargin?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: ${p => (p.noMargin ? '0' : `${pxToRem(32)}`)};
  ${p => (p.paddingX ? `padding: 0 ${pxToRem(p.paddingX)}` : '')}
`;

export const Tag = styled.div`
  background: ${({ theme }) => theme.colors.yellowStars};
  border-radius: ${pxToRem(40)};
  width: fit-content;
  padding: ${pxToRem(3)} ${pxToRem(12)};
  font-family: Nunito;
  font-size: ${pxToRem(12)};
  font-weight: 600;
  line-height: ${pxToRem(16)};
  letter-spacing: ${pxToRem(-0.4375)};

  margin-top: ${pxToRem(16)};
`;

export const LayoutFormContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const AlignToBottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const AlignToLeft = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const BvHeader = styled(BvHeaderSVG).attrs({
  width: pxToRem(118),
  height: pxToRem(29),
})`
  margin: 0 ${pxToRem(32)} ${pxToRem(32)};

  .in-page {
    margin: 0 0 ${pxToRem(8)};
    width: ${pxToRem(49)};
    height: ${pxToRem(37)};
  }
`;

export const MapfreHeader = styled(MapfreHeaderSVG).attrs({
  width: pxToRem(118),
  height: pxToRem(29),
})`
  margin: 0 ${pxToRem(32)} ${pxToRem(32)};
`;

export const TokyoHeader = styled(TokyoHeaderSVG).attrs({
  width: pxToRem(118),
  height: pxToRem(29),
})`
  margin: 0 ${pxToRem(32)} ${pxToRem(32)};
`;

export const ThumbsUp = styled(ThumbsUpSVG).attrs({
  width: pxToRem(16),
  height: pxToRem(16),
})`
  margin-right: ${pxToRem(8)};

  &.negative {
    transform: rotate(180deg);
  }
`;

export const Divider = styled.div`
  height: ${pxToRem(1)};
  background: ${({ theme }) => theme.colors.blueLight};
`;

export const CarPlateButton = styled(Button)<{ isIPhone: boolean }>`
  border-radius: 0;
  margin-top: 0 !important;
`;

export const TitleModal = styled.h1`
  font-family: Nunito;
  font-weight: 700;
  font-size: ${pxToRem(24)};
  line-height: ${pxToRem(33)};
  color: ${({ theme }) => theme.colors.blueTypo};
`;

export const TextModal = styled.p`
  font-family: Nunito;
  font-weight: 400;
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(22)};
  color: ${({ theme }) => theme.colors.blueTypo};
  margin-top: ${pxToRem(20)};
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  -ms-flex-positive: 0;
  flex-grow: 0;

  &.mt-28 {
    margin-top: ${pxToRem(28)};
  }

  &.ta-justify {
    text-align: justify;
  }
`;

export const ContentModal = styled.div`
  width: 100%;
  padding: 0 ${pxToRem(32)};

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const BubbleButton = styled(ButtonInputCover)<{ selected?: boolean }>`
  font-weight: 600;
  text-align: center;

  color: ${({ theme, selected }) => theme.colors[selected ? 'white' : 'blueStrong']};
  background-color: ${({ theme, selected }) => theme.colors[selected ? 'blueStrong' : 'white']};

  min-width: ${pxToRem(67)};
  border-radius: ${pxToRem(29)};

  opacity: 0.9;

  &.choiceButton {
    min-width: ${pxToRem(90)};
    margin: ${pxToRem(16)} ${pxToRem(8)} ${pxToRem(16)} 0;
  }

  &.statusPaying {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    min-width: ${pxToRem(87)};
    min-height: ${pxToRem(43)};
    margin: 0 ${pxToRem(8)};
  }
`;

export const FixedBottomButtons = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  bottom: 0;
  width: 100%;
  background: white;
  justify-content: space-between;
  padding: ${pxToRem(16)} ${pxToRem(32)};
`;

export const TextBodyMedium = styled.p`
  font-family: Nunito;
  font-style: normal;
  font-weight: 400;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(24)};
  text-align: left;
  color: ${({ theme }) => theme.colors.blueTypo};

  &.right {
    font-weight: 700;
    text-align: right;
    margin-left: auto;
  }
`;

export const ContainerText = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueLight};
  padding: ${pxToRem(24)} ${pxToRem(32)};

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const ImgBrand = styled.img`
  width: ${pxToRem(24)};
  height: fit-content;
  margin-right: ${pxToRem(16)};
`;

export const HelperText = styled.span<{ small?: boolean; error?: boolean }>`
  font-family: Nunito;
  font-weight: 600;
  font-style: normal;
  font-size: ${({ small }) => pxToRem(small ? 12 : 14)};
  line-height: ${pxToRem(19)};

  color: ${({ theme, error }) => theme.colors[error ? 'red' : 'blue']};
  opacity: ${({ error }) => (error ? 1 : 0.5)};
  margin-top: ${pxToRem(8)};
`;

export const DescriptionModal = styled.div`
  margin-top: ${pxToRem(32)};
  font-weight: 400;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(21)};
  color: ${({ theme }) => theme.colors.blue};
  white-space: pre-wrap;

  a {
    border: none;
    background-color: transparent;
    padding: 0;
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.blueStrong};
  }
`;

export const Apolice = styled(ApoliceSVG).attrs({
  width: pxToRem(217),
  height: pxToRem(172),
})`
  width: 100%;
  margin: ${pxToRem(20)} auto 0;
`;

type SectionWrapperProps = {
  noPaddingX?: boolean;
  noBorder?: boolean;
};
export const SectionWrapper = styled.div<SectionWrapperProps>`
  ${p => (p.noBorder ? 'border: none' : 'border-top: 1px solid #EBEFF5;')};

  width: 100%;
  padding: ${pxToRem(32)} ${p => (p.noPaddingX ? '0' : pxToRem(32))} ${pxToRem(26)};
  display: flex;
  flex-direction: column;
`;

export const SectionHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    font-size: ${pxToRem(18)};
    line-height: 89%;
    color: ${({ theme }) => theme.colors.blueTypo};
    margin: 0;
  }

  h3 {
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    font-size: ${pxToRem(14)};
    line-height: 171%;
    color: ${({ theme }) => theme.colors.blueTypo};
    margin: 0;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${pxToRem(10)} ${pxToRem(16)};
    gap: ${pxToRem(8)};

    background-color: ${({ theme }) => theme.colors.white};
    border: 2px solid ${({ theme }) => theme.colors.blueStrong};
    border-radius: ${pxToRem(32)};

    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    font-size: ${pxToRem(14)};
    line-height: 140%;
    text-align: center;
    color: ${({ theme }) => theme.colors.blueStrong};
  }

  &.mb-16 {
    margin-bottom: ${pxToRem(16)};
  }

  div.iof {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
