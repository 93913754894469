import { memo } from 'react';

import { BoxInfo, Container, InfoIcon, InfoText, InputButton, Question, Answer } from './styles';

interface Props {
  action: () => void;
  buttonLabel: string;
  infoText?: string;
  question: string;
  answer?: string;
}

const InputCoverInfo = ({ action, buttonLabel, infoText, question, answer }: Props): JSX.Element => {
  const handleClick = e => {
    const ripples = document.createElement('span');
    const buttonPosition = e.target.getBoundingClientRect();
    const top = Math.abs(buttonPosition.top - e.clientY);
    const left = Math.abs(buttonPosition.left - e.clientX);

    ripples.style.top = `${top}px`;
    ripples.style.left = `${left}px`;

    e.target.appendChild(ripples);

    setTimeout(() => {
      ripples.remove();
      return action();
    }, 300);
  };

  return (
    <Container varyingHeight>
      <div>
        <Question>{question}</Question>
        <Answer>{answer}</Answer>
        {infoText && (
          <BoxInfo style={{ marginTop: '8px' }}>
            <InfoIcon aria-labelledby="info icon" />
            <InfoText>{infoText}</InfoText>
          </BoxInfo>
        )}
      </div>
      {buttonLabel && <InputButton onClick={handleClick}>{buttonLabel}</InputButton>}
    </Container>
  );
};

export default memo(InputCoverInfo);
