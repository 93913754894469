import styled from 'styled-components/macro';

import { pxToRem } from '../../utils';

export const ContainerTabView = styled.div<{ noMarginTop?: boolean }>`
  margin: ${({ noMarginTop }) => pxToRem(noMarginTop ? 0 : 38)} ${pxToRem(32)} 0;

  button.MuiTab-textColorInherit {
    text-transform: initial;
    opacity: 1;
    padding: 0 ${pxToRem(15)} ${pxToRem(12)};

    &.Mui-selected {
      color: #0069e7;
    }
  }
`;

export const OffersCards = styled.div`
  &:first-child {
    margin-left: 1rem;
  }
  &:last-child {
    margin-right: 1rem;
  }
`;

export const Filler = styled.div`
  min-width: ${pxToRem(32)};
`;
