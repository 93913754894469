/* eslint-disable no-plusplus */
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { formatDate } from './formatDate';

export const isManufactureValidYear = (year: number) => {
  const actualYear = new Date().getFullYear();
  const limitYear = actualYear - 10;

  return year >= limitYear && year <= actualYear;
};

export const isModelValidYear = (manufactureYear: number, year: number) => {
  const limitYear = manufactureYear + 1;

  return year >= manufactureYear && year <= limitYear;
};

export const isValidYear = (year: number, max?: number, min = 1900) => {
  if (max && year > max) {
    return false;
  }
  return year >= min;
};

export const isValidMonth = (value: number) => value > 0 && value <= 12;

export const isValidDate = value => {
  dayjs.extend(customParseFormat);
  const formattedValue = formatDate.toDateOnlyISO(value);
  if (formattedValue.trim().length !== 10) return false;

  return dayjs(formattedValue, 'YYYY-MM-DD', true).isValid();
};

export const isValidRenavam = (renavam: string) => {
  if (renavam.length < 9 || renavam.length > 11) return false;

  let completeRenavam;
  switch (renavam.length) {
    case 9:
      completeRenavam = `00${renavam}`;
      break;
    case 10:
      completeRenavam = `0${renavam}`;
      break;
    default:
      completeRenavam = renavam;
  }

  if (!completeRenavam.match('[0-9]{11}')) {
    return false;
  }

  const renavamWithoutDigit = completeRenavam.substring(0, 10);
  const renavamReverse = renavamWithoutDigit.split('').reverse().join('');
  let sum = 0;

  for (let i = 0; i < 8; i++) {
    const digit = Number(renavamReverse.substring(i, i + 1));
    const multiplier = i + 2;
    sum += digit * multiplier;
  }

  sum += +renavamReverse[8] * 2;
  sum += +renavamReverse[9] * 3;

  const mod11 = sum % 11;

  let lastDigitCalculated = 11 - mod11;
  lastDigitCalculated = lastDigitCalculated >= 10 ? 0 : lastDigitCalculated;

  const digitInformed = renavam.substring(renavam.length - 1, renavam.length);

  if (lastDigitCalculated === +digitInformed) {
    return true;
  }

  return false;
};

export const isValidCPF = (cpf: string) => {
  const regex = /\D/gm;
  const prepareCpf = cpf.replace(regex, '').split('').map(Number);

  if (prepareCpf.length !== 11 || !Array.from(cpf).filter(e => e !== cpf[0]).length) return false;

  let sum = 0;
  let rest = 0;
  let tmp = 0;
  for (let i = 0; i < 9; i++) sum += prepareCpf[i] * (10 - i);
  tmp = (sum * 10) % 11;
  rest = [10, 11].includes(tmp) ? 0 : tmp;
  if (rest !== prepareCpf[9]) return false;

  sum = 0;
  for (let i = 0; i < 10; i++) sum += prepareCpf[i] * (11 - i);
  tmp = (sum * 10) % 11;
  rest = [10, 11].includes(tmp) ? 0 : tmp;
  if (rest !== prepareCpf[10]) return false;

  return true;
};
