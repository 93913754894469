/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UnicoCheckScoreReponse } from '../models/UnicoCheckScoreReponse';
import type { UnicoCheckScoreRequest } from '../models/UnicoCheckScoreRequest';
import type { UnicoReponse } from '../models/UnicoReponse';
import type { UnicoRequest } from '../models/UnicoRequest';
import { request as __request } from '../core/request';

export class UnicoControllerService {

    /**
     * checkMessageScore
     * @param product product
     * @param quotePartnerId quotePartnerId
     * @param request request
     * @param xCustomerUuid x-customer-uuid
     * @param acceptLanguage Accept-Language
     * @returns UnicoCheckScoreReponse OK
     * @returns any Created
     * @throws ApiError
     */
    public static async checkMessageScoreUsingPost(
        product: string,
        quotePartnerId: string,
        request: UnicoCheckScoreRequest,
        xCustomerUuid: string,
        acceptLanguage?: string,
    ): Promise<UnicoCheckScoreReponse | any> {
        const result = await __request({
            method: 'POST',
            path: `/api/personal_loans/unico/check_score/${product}/${quotePartnerId}`,
            headers: {
                'x-customer-uuid': xCustomerUuid,
                'Accept-Language': acceptLanguage,
            },
            body: request,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * createUnicoMessage
     * @param product product
     * @param quotePartnerId quotePartnerId
     * @param request request
     * @param xCustomerUuid x-customer-uuid
     * @param acceptLanguage Accept-Language
     * @returns UnicoReponse OK
     * @returns any Created
     * @throws ApiError
     */
    public static async createUnicoMessageUsingPost(
        product: string,
        quotePartnerId: string,
        request: UnicoRequest,
        xCustomerUuid: string,
        acceptLanguage?: string,
    ): Promise<UnicoReponse | any> {
        const result = await __request({
            method: 'POST',
            path: `/api/personal_loans/unico/create/${product}/${quotePartnerId}`,
            headers: {
                'x-customer-uuid': xCustomerUuid,
                'Accept-Language': acceptLanguage,
            },
            body: request,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

}