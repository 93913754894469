/* eslint-disable no-underscore-dangle */
import { compose, createStore } from 'redux';

import rootReducer from '../reducers';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: typeof compose;
  }
}

const store = createStore(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export default store;

export type RootState = ReturnType<typeof rootReducer>;
