import styled, { keyframes } from 'styled-components/macro';

import { FormTitle } from '../styles';

import { Content } from '../Modal/SelectModal/styles';
import { pxToRem } from '../../utils';

const animate = keyframes`
  0% {
    width: 0px;
    height: 0px;
    opacity: 0.5;
  }
  100% {
    width: ${pxToRem(500)};
    height: ${pxToRem(500)};
    opacity: 0;
  }
`;

export const Container = styled.button`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0;
  align-items: center;
  height: ${pxToRem(70)};
  border: none;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueLight};
  padding: 0 ${pxToRem(32)};
  overflow: hidden;
  position: relative;

  div {
    div {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      pointer-events: none;

      img {
        height: ${pxToRem(20)};
        width: ${pxToRem(20)};
        margin-right: ${pxToRem(16)};
      }
    }
  }

  span {
    position: absolute;
    background-color: #000;
    transform: translate(-50%, -50%);
    pointer-events: none;
    border-radius: 50%;
    animation: ${animate} 1.5s linear infinite;
  }
`;

export const Label = styled.p<{ icon?: boolean; text?: boolean }>`
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: ${pxToRem(16)};
  line-height: ${({ icon }) => pxToRem(icon ? 24 : 16)};
  letter-spacing: ${({ icon }) => pxToRem(icon ? 0 : -0.4375)};
  text-align: left;
  color: ${({ theme, text }) => theme.colors[text ? 'blueStrong' : 'blueTypo']};
  margin-bottom: ${({ text }) => (text ? pxToRem(8) : 0)};
  pointer-events: none;
`;

export const Text = styled(Label)`
  font-weight: 400;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(19)};
  color: ${({ theme }) => theme.colors.blueTypo};
  pointer-events: none;
`;

export const ContentWhiteButtonModal = styled(Content)`
  min-height: ${pxToRem(250)};
  margin-top: ${pxToRem(32)};
`;

export const TitleModal = styled(FormTitle)`
  margin: ${pxToRem(16)} ${pxToRem(32)} ${pxToRem(20)};
  line-height: ${pxToRem(32)};
`;
