import styled from 'styled-components/macro';

import { pxToRem } from '../../utils';

export const Container = styled.div`
  width: 100%;
  height: 85vh;
  padding: ${pxToRem(80)} ${pxToRem(35)};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;

  img {
    width: 100%;
    height: ${pxToRem(126)};
    margin-bottom: ${pxToRem(31)};
  }
`;

export const Title = styled.p`
  font-family: Nunito;
  font-weight: 700;
  font-size: ${pxToRem(20)};
  line-height: ${pxToRem(27)};
  text-align: center;

  color: ${({ theme }) => theme.colors.blueTypo};
  margin-bottom: ${pxToRem(15)};
`;

export const Text = styled(Title)`
  font-weight: 400;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(19)};

  margin-bottom: ${pxToRem(8)};
`;
