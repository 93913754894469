import { rootPalette } from './palette';
import { sizes } from './sizes';

const theme = {
  colors: {
    ...rootPalette,
  },
  sizes,
};

export default theme;
