/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { request as __request } from '../core/request';

export class DomainReadControllerService {

    /**
     * @param key
     * @param acceptLanguage
     * @returns any OK
     * @throws ApiError
     */
    public static async domain(
        key: string,
        acceptLanguage?: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/insurance/domain/${key}`,
            headers: {
                'Accept-Language': acceptLanguage,
            },
        });
        return result.body;
    }

}