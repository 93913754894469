import styled from 'styled-components/macro';
import { pxToRem } from '../../utils';

export const BarStyled = styled.div`
  height: ${pxToRem(8)};
  border-radius: ${pxToRem(6)};
  background-color: #b3c4d0;
`;

export const ProgressBarStyled = styled(BarStyled)`
  display: flex;
  position: relative;
`;

export const ProgressStyled = styled(BarStyled)<{ widthValue?: number }>`
  position: absolute;
  width: ${({ widthValue }) => `${widthValue}%`};
  background-color: #ffd000;
`;
