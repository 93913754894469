import { memo, useCallback, useState } from 'react';
import { CarouselItemProps } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import { Currency, LayoutForm, LayoutFormContent, MapfreHeader } from '../index';
import { Offer } from '../../models/insurance/offer';
import { brandChange, selectInsurance } from '../../store';

import Button from '../Button';
import { SlideOfferLink, SlideOfferContainer, SlideOfferLogoContainer, SlideOfferInfo, SlideOfferDetails, Check } from './styles';
import { ErrorApi, createContract, getBalance, handleException } from '../../api';
import { changeInsuranceAction } from '../../actions';

import './slide-offer.css';

type SlideProps = CarouselItemProps<HTMLDivElement> & {
  offer: Offer;
  onSelect?: () => void;
  secondaryClick?: () => void;
  // onAllCoverages?: () => void;
};

const brand = {
  bv: 'bvHeader',
  mapfre: 'mapfreHeader',
  TokyoMarine: 'tokyoHeader',
  question: 'questionCircle',
};

const SlideOffer = ({ offer, onSelect, secondaryClick }: SlideProps): JSX.Element => {
  const dispatch = useDispatch();
  const { currentQuote } = useSelector(selectInsurance);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ErrorApi>();

  const installments = `${offer.paymentOptions[0]?.installments ?? offer?.installmentsNumber}x `;
  const valueInstallments = offer.paymentOptions[0]?.valueInstallments ?? offer.installmentsValue;

  const saveBranch = useCallback((company: string) => dispatch(brandChange(brand[company])), []);

  const saveToRedux = useCallback(
    (contractId: string, balance: number) => {
      if (!contractId) return;

      dispatch(
        changeInsuranceAction({
          currentQuote: {
            ...currentQuote,
            insurance: {
              ...currentQuote?.insurance,
              contract: { contractId },
              balance,
            },
          },
        }),
      );
    },
    [currentQuote],
  );

  const saveBalanceInRedux = useCallback((contractId: string) => {
    setLoading(true);
    getBalance()
      .then(async ({ current }) => {
        // const newBalance = (current + unavailable) / 100;
        const newBalance = current / 100;
        saveToRedux(contractId, newBalance);
      })
      .catch(reason => setError(handleException(reason)))
      .finally(() => setLoading(false));
  }, []);

  const handleClick = useCallback(() => {
    if (!currentQuote?.insurance?.offer?.contractUuid) return;

    saveBranch(offer.company);
    onSelect(offer.company);

    const { contractUuid } = currentQuote?.insurance?.offer;

    createContract(contractUuid)
      .then(({ id = '' }) => saveBalanceInRedux(id))
      .catch(reason => setError(handleException(reason)))
      .finally(() => setLoading(false));
  }, [offer.company, onSelect]);

  const handleSecondaryClick = useCallback(() => {
    saveBranch(offer.company);
    if (secondaryClick) secondaryClick(offer.company);
  }, [offer.company, secondaryClick]);

  // const handleAllCoverages = useCallback(() => {
  //   sendTap({ ...tag, value: 'plandet' });

  //   saveBranch(offer.company);
  //   if (onAllCoverages) onAllCoverages();
  // }, [offer.company, onAllCoverages]);

  // const renderDetails = useCallback(() => {
  //   if (!offer || !offer?.coverages || offer.coverages.length === 0) return <></>;

  //   const basicCoverages = (offer?.coverages).filter(({ basic }) => basic);
  //   const filteredCoverages = sortArray(
  //     basicCoverages.filter((_, index) => index < 4),
  //     'description',
  //     'ASC',
  //   );

  //   return filteredCoverages.map(({ description, uuid }) => (
  //     <p className="coverage" key={`coverage-${uuid}`}>
  //       <Check />
  //       <span>{description.replace(/^\w{2,}\s-\s/gm, '')}</span>
  //     </p>
  //   ));
  // }, [offer]);

  return (
    <LayoutForm
      noPaddingX
      isLoading={loading}
      isError={error}
      actionPrimaryButton={() => setError(undefined)}
      hideBackButton
      className="slide-offer-form-content"
      id="slide-offer-form-content"
    >
      <LayoutFormContent>
        <SlideOfferContainer>
          <SlideOfferInfo>
            <SlideOfferLogoContainer>
              {offer.company === 'mapfre' ? <MapfreHeader style={{ margin: 0 }} /> : offer.company}
            </SlideOfferLogoContainer>
            <div className="texts">
              <div>
                <p>Corretora Protenseg</p>
                <p className="currentlabel">A partir de</p>
                <p className="current">
                  {installments} <Currency value={valueInstallments} />
                </p>

                {offer.cupom ? <p>Cupom de {offer.cupom} aplicado</p> : null}

                <p>
                  Total de <Currency value={offer.paymentOptions[0]?.valueTotal ?? offer.offerValue - offer.cupom} />
                </p>
              </div>
            </div>
            <div className="buttons">
              <Button noMargin background="blueStrong" color="white" onClick={handleClick} fullWidth>
                Escolher plano
              </Button>
              <Button background="transparent" noMargin color="blueStrong" onClick={handleSecondaryClick}>
                Visualizar Coberturas
              </Button>
            </div>
          </SlideOfferInfo>

          <SlideOfferDetails>
            {/* <div>{renderDetails()}</div> */}

            <div className="coverage-additional-info-block">
              <Check />
              <p>
                100% da Tabela Fipe:
                <span> Incêndio, colisão ou roubo e furto </span>
              </p>
            </div>

            <div className="coverage-additional-info-block">
              <Check />
              <p>
                Assistência 24hs
                <span> Guincho, chaveiro e outros serviços </span>
              </p>
            </div>

            <div className="coverage-additional-info-block">
              <Check />
              <p>Assistência residencial</p>
            </div>

            <p>
              <em>Proposta válida por 7 dias corridos</em>
            </p>
            <p>
              {/* Confira <SlideOfferLink onClick={handleAllCoverages}>todas as coberturas</SlideOfferLink> do plano */}
              Confira <SlideOfferLink onClick={handleSecondaryClick}>todas as coberturas</SlideOfferLink> do plano
            </p>
          </SlideOfferDetails>
        </SlideOfferContainer>
      </LayoutFormContent>
    </LayoutForm>
  );
};

export default memo(SlideOffer);
