import { Domain } from '../models';

export const isEmpty = (values: any): boolean => {
  if (!values) return true;
  const objectProps = Object.values(values);
  if (objectProps.length === 0) return false;
  return objectProps.every(objProp => !objProp || objProp === '');
};

export const isFilled = (values: Domain | string | DateConstructor | undefined): boolean => {
  if (!values) return false;
  const objectProps = Object.values(values);
  if (objectProps.length === 0) return false;

  // verificando a prop disabled que várias vezes é undefined e estava atrapalhando a função
  const indexDisabled = Object.keys(values).indexOf('disabled');
  if (indexDisabled && objectProps[indexDisabled] === undefined) objectProps.splice(indexDisabled, 1);

  return objectProps.every(objProp => objProp && objProp !== '');
};
