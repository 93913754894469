/* eslint-disable react/jsx-props-no-spreading */
import { memo, useCallback, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import { CreditCard } from '../../api';
import { Container, Content } from './styles';
import { MasterCard, Wallet } from '../InputPayment/styles';
import { FormTitle, ImgBrand, TextBodyMedium } from '../styles';

type ItemProps = CreditCard & {
  checked: boolean;
};

type RadioBoxPaymentProps = {
  items: ItemProps[];
  action?: (event: any) => void;
};

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 24,
    height: 24,
    boxShadow: 'inset 0 0 0 1px #0069e7, inset 0 -1px 0 #0069e7',
    backgroundColor: '#fff',
    '$root.Mui-focusVisible &': {
      outline: '2px auto #0069E7',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#fff',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#0069E7',
    '&:before': {
      display: 'block',
      width: 24,
      height: 24,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#0069E7',
    },
  },
});

// Inspired by blueprintjs
const StyledRadio = (props: RadioProps) => {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
};

const RadioBoxPayment = ({ items, action }: RadioBoxPaymentProps) => {
  const [value, setValue] = useState(items.find(item => item.checked)?.id || '');

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => setValue((event.target as HTMLInputElement).value),
    [],
  );

  const renderContainer = useCallback(
    () =>
      items.map(({ id, brand, lastFourDigits, brandThumbUrl = '' }) => (
        <Container key={`option_#${id}`}>
          {id === 'wallet' && <Wallet key={`wallet_#${id}`} />}
          {id === 'masterCard' && <MasterCard key={`card_#${id}`} />}
          {brandThumbUrl && <ImgBrand src={brandThumbUrl} alt="icon from brand" loading="lazy" />}
          <Content>
            <div>
              <FormTitle className="answer">{brand}</FormTitle>
              <TextBodyMedium>{lastFourDigits}</TextBodyMedium>
            </div>

            <FormControlLabel value={id} control={<StyledRadio />} label="" onClick={action} />
          </Content>
        </Container>
      )),
    [items],
  );

  return (
    <FormControl component="fieldset">
      <RadioGroup value={value} aria-label="card options" name="customized-radios-card-options" onChange={handleChange}>
        {renderContainer()}
      </RadioGroup>
    </FormControl>
  );
};

export default memo(RadioBoxPayment);
