import { memo, useState } from 'react';
import { Switch, SwitchProps, withStyles } from '@material-ui/core';

import theme from '../../styles/theme';

import { Currency } from '../index';
import { Container, Price, TextContainer, Title } from './styles';

type Props = {
  title: string;
  price: number;
  onChange?: () => void;
};

const CoveragePayment = ({ title, price, onChange }: Props): JSX.Element => {
  const [checked, setChecked] = useState(true);

  const handleChange = event => {
    if (onChange) onChange();
    setChecked(event.target.checked);
  };

  const BlueSwitch = withStyles({
    switchBase: {},
    checked: {
      color: theme.colors.blueStrong,
      '&$checked': {
        color: theme.colors.blueStrong,
      },
      '&$checked + $track': {
        color: theme.colors.blueStrong,
      },
    },
    track: {
      // Controls default (unchecked) color for the track
      opacity: 0.3,
      backgroundColor: theme.colors.blueStrong,
      '$checked$checked + &': {
        // Controls checked color for the track
        backgroundColor: theme.colors.blueStrong,
      },
    },
  })(Switch) as (props: SwitchProps) => JSX.Element;

  return (
    <Container>
      <TextContainer>
        <Title>{title}</Title>

        <Price>
          <Currency value={price} /> de desconto
        </Price>
      </TextContainer>

      <BlueSwitch checked={checked} onChange={handleChange} />
    </Container>
  );
};

export default memo(CoveragePayment);
