import styled, { keyframes } from 'styled-components/macro';

import { pxToRem } from '../../utils';

const animate = keyframes`
  0% {
    width: 0px;
    height: 0px;
    opacity: 0.5;
  }
  100% {
    width: ${pxToRem(500)};
    height: ${pxToRem(500)};
    opacity: 0;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-height: ${pxToRem(80)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueLight};
  padding: ${pxToRem(16)} ${pxToRem(32)} ${pxToRem(24)};

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const Question = styled.p`
  font-family: Nunito;
  font-weight: 600;
  font-style: normal;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(24)};

  color: ${({ theme }) => theme.colors.tokenColorBlue200};
  mix-blend-mode: normal;
  opacity: 1;
`;

export const Answer = styled.p<{ place?: string }>`
  font-family: Nunito;
  font-weight: 700;
  font-style: normal;
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(24)};
  color: ${({ theme }) => theme.colors.blueTypo};
`;

export const Tel = styled.a`
  font-family: Nunito;
  font-weight: 700;
  font-style: normal;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(19)};
  text-align: center;
  text-decoration: none;

  color: ${({ theme }) => theme.colors.blueStrong};
  background: white;

  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${pxToRem(87)};
  height: ${pxToRem(40)};
  border: 2px solid ${({ theme }) => theme.colors.blueLight};
  box-sizing: border-box;
  border-radius: ${pxToRem(16)};
  overflow: hidden;
  position: relative;

  &:link {
    color: ${({ theme }) => theme.colors.blueStrong};
  }

  span {
    position: absolute;
    background-color: #000;
    transform: translate(-50%, -50%);
    pointer-events: none;
    border-radius: 50%;
    animation: ${animate} 1s linear infinite;
  }
`;
