import { ResultQuoteResponse } from '../../api/generated/personal-loans';
import { infoIcon } from '../../assets';
import { Loan, LoanReduxModel, LoanTypeEnum, QuoteOptions } from '../../models';
import store from '../../store/store';
import { formatNumber, percentOfValue } from '../../utils';

export type DetailSignProps = {
  text: string;
  value: number | string;
  valueType?: 'CURRENCY' | 'PERCENT' | 'NUMBER';
};

export type SimpleLineProps = {
  label: string;
  value: string;
  valueAlign?: string;
};

export type DoubleLineProps = {
  labelLeft?: string;
  valueLeft?: string;
  labelRight?: string;
  valueRight?: string;
  icon?: string;
  iconName?: string;
};

const getSimulationQuote = () => store.getState().personalCredit.currentQuote.loan || ({} as Loan);
const getContractedLoan = () => store.getState().personalCredit.loan || ({} as LoanReduxModel);
const getLoanType = () => getContractedLoan()?.loanType || getSimulationQuote()?.loanType;

const getQuoteOptions = () => {
  const { personalCredit } = store.getState();
  const { iofAD = '0,0082', iofRate = '0,38' } = personalCredit?.currentQuote?.quoteOptions || ({} as QuoteOptions);
  return { iofAD, iofRate };
};

export const numberOfInstallments = () => {
  const installmentsOnContractedLoan = getContractedLoan()?.installments?.total;
  return installmentsOnContractedLoan || Number(getSimulationQuote()?.installmentPlan?.id || '0');
};

export const getResultQuote = () => {
  const resultQuoteRaw =
    getContractedLoan()?.quote?.resultQuote || getSimulationQuote().resultQuote || ({} as ResultQuoteResponse);
  const {
    installmentWithoutInsurance = 0,
    valueTotalInstallmentWithoutInsurance = 0,
    iof = 0,
    tc = 0,
    taxAnnualFinance = 0,
    taxMonthFinance = 0,
    totalLoan = 0,
    costPrice = 0,
    valuePercentCetMonth = 0,
    valuePercentCetAnnual = 0,
    valueInsurance = 0,
    valueTotalInstallments = 0,
    propertyFee = 0,
    registrationFee = 0,
  } = resultQuoteRaw as ResultQuoteResponse;

  return {
    installmentWithoutInsurance,
    valueTotalInstallmentWithoutInsurance,
    iof,
    tc,
    taxAnnualFinance,
    taxMonthFinance,
    totalLoan,
    costPrice,
    valuePercentCetMonth,
    valuePercentCetAnnual,
    valueInsurance,
    valueTotalInstallments,
    propertyFee,
    registrationFee,
  };
};

export const OPERATION_DATA = (): DetailSignProps[] => {
  const {
    costPrice,
    installmentWithoutInsurance,
    iof,
    tc,
    totalLoan,
    valueInsurance,
    valueTotalInstallments,
    propertyFee,
    registrationFee,
  } = getResultQuote();
  return [
    {
      text: 'Valor da parcela',
      value: installmentWithoutInsurance,
      valueType: 'CURRENCY',
    },
    {
      text: 'Somatória das parcelas',
      value: totalLoan,
      valueType: 'CURRENCY',
    },
    {
      text: 'Valor total com imposto',
      value: valueTotalInstallments + iof,
      valueType: 'CURRENCY',
    },
    {
      text: 'Quantidade de parcelas',
      value: numberOfInstallments(),
      valueType: 'NUMBER',
    },
    {
      text: 'Valor total de pagamento autorizado',
      value: totalLoan + costPrice,
      valueType: 'CURRENCY',
    },
    {
      text: !tc ? 'Taxa de propriedade + Taxa de registro' : 'Tarifa de cadastro',
      value: !tc ? propertyFee + registrationFee : tc,
      valueType: 'CURRENCY',
    },
    {
      text: 'Impostos',
      value: iof,
      valueType: 'CURRENCY',
    },
    {
      text: 'Seguros',
      value: valueInsurance,
      valueType: 'CURRENCY',
    },
    {
      text: 'Capitalização',
      value: 0,
      valueType: 'CURRENCY',
    },
    {
      text: 'Assistência',
      value: 0,
      valueType: 'CURRENCY',
    },
    {
      text: 'Outras tarifas',
      value: costPrice,
      valueType: 'CURRENCY',
    },
  ];
};

export const OPERATION_DATA_LINES: DoubleLineProps[] = [
  { labelLeft: 'Em caso de refinanciamento', valueLeft: 'Nº [descrição]' },
  { labelLeft: 'Em caso de portabilidade', valueLeft: 'Nº [descrição]' },
];

export const INCUMBENCY_COST_EFFECTIVE = (): DetailSignProps[] => {
  const { taxAnnualFinance, taxMonthFinance, valuePercentCetAnnual, valuePercentCetMonth } = getResultQuote();
  const { loanType } = getContractedLoan() || getSimulationQuote();

  if (loanType === 'CP') {
    return [
      {
        text: 'Multa por atraso',
        value: 0,
        valueType: 'PERCENT',
      },
      {
        text: 'Juros Moratórios',
        value: 0,
        valueType: 'PERCENT',
      },
      {
        text: 'Taxa de juros mensal',
        value: taxMonthFinance,
        valueType: 'PERCENT',
      },
      {
        text: 'Taxa de juros anual',
        value: taxAnnualFinance,
        valueType: 'PERCENT',
      },
      {
        text: 'Custo efetivo mensal',
        value: valuePercentCetMonth,
        valueType: 'PERCENT',
      },
      {
        text: 'Custo efetivo anual',
        value: valuePercentCetAnnual,
        valueType: 'PERCENT',
      },
    ];
  }
  return [
    {
      text: 'Taxa de juros mensal',
      value: taxMonthFinance,
      valueType: 'PERCENT',
    },
    {
      text: 'Taxa de juros anual',
      value: taxAnnualFinance,
      valueType: 'PERCENT',
    },
    {
      text: 'Custo efetivo mensal',
      value: valuePercentCetMonth,
      valueType: 'PERCENT',
    },
    {
      text: 'Custo efetivo anual',
      value: valuePercentCetAnnual,
      valueType: 'PERCENT',
    },
  ];
};

export const INCUMBENCY_COST_EFFECTIVE_LINE: SimpleLineProps[] = [
  { label: '', value: 'Os percentuais foram baseados no valor total do crédito' },
];

export const CREDIT_INFORMATION_SIMPLE: SimpleLineProps[] = [
  {
    label: 'Tipo de operação',
    value: getLoanType() === LoanTypeEnum.CP ? 'Crédito pessoal' : 'Crédito com garantia',
    valueAlign: 'right',
  },
  { label: 'Tipo de contrato', value: 'Novo', valueAlign: 'right' },
  { label: 'Forma de emissão', value: 'Eletrônico', valueAlign: 'right' },
];

export const CREDIT_INFORMATION_DOUBLE = (): DoubleLineProps[] => {
  const { valueTotalInstallmentWithoutInsurance, iof, tc, totalLoan, propertyFee, registrationFee } = getResultQuote();
  return [
    {
      labelLeft: 'Valor total do crédito',
      valueLeft: `${formatNumber.currency(totalLoan)}`,
      labelRight: 'Indice (%)',
      valueRight: `${percentOfValue(totalLoan, totalLoan)}`,
    },
    {
      labelLeft: 'Valor líquido de crédito',
      valueLeft: `${formatNumber.currency(valueTotalInstallmentWithoutInsurance)}`,
      labelRight: `${percentOfValue(totalLoan, valueTotalInstallmentWithoutInsurance)}`,
    },
    {
      labelLeft: !tc ? 'Taxa de propriedade + Taxa de registro' : 'Tarifa de Cadastro',
      valueLeft: `${formatNumber.currency(!tc ? propertyFee + registrationFee : tc)}`,
      labelRight: `${percentOfValue(totalLoan, !tc ? propertyFee + registrationFee : tc)}`,
    },
    {
      labelLeft: 'IOF total',
      icon: infoIcon,
      iconName: 'infoIcon',
      valueLeft: `${formatNumber.currency(iof)}`,
      labelRight: `${percentOfValue(totalLoan, iof)}`,
    },
  ];
};

export const IOF = (): string[] => {
  const { iofAD, iofRate } = getQuoteOptions();
  return [`${iofAD}% a.d.`, 'alíquota adicional (Decreto 6.339/08)', `${iofRate}% única`];
};

export const BANK: string[] = [
  'Razão social: EAÍ CLUBE AUTOMOBILISTA S.A.',
  'CPNJ: 34.656.383/0001-72',
  'Endereço: Avenida Brigadeiro Luiz Antônio, 1.343, 3º Andar, Ala B, Bela Vista',
  'Cidade/UF: São Paulo/SP',
];
