import { memo, useCallback, useState } from 'react';

import Modal from '..';

import { ButtonInputCover } from '../../CoverageCover/styles';
import { Container } from '../../InputCover/styles';
import { AnswerStatic, QuestionStatic } from './styles';

interface Props {
  placeholder: string;
  question: string;
  onClick: () => void;
  tagAction?: () => void;
  children: JSX.Element | JSX.Element[];
}

const StaticModal = ({ placeholder, question, onClick, tagAction, children }: Props): JSX.Element => {
  const [isOpened, setIsOpened] = useState<boolean>(false);

  const openModal = useCallback(() => setIsOpened(prev => !prev), []);

  const handleClick = e => {
    if (tagAction) {
      tagAction();
    }
    const ripples = document.createElement('span');
    const buttonPosition = e.target.getBoundingClientRect();
    const top = Math.abs(buttonPosition.top - e.clientY);
    const left = Math.abs(buttonPosition.left - e.clientX);

    ripples.style.top = `${top}px`;
    ripples.style.left = `${left}px`;

    e.target.appendChild(ripples);

    setTimeout(() => ripples.remove(), 1000);

    return openModal();
  };

  return (
    <>
      <Container>
        <div>
          <QuestionStatic>{question}</QuestionStatic>
          <AnswerStatic>{placeholder}</AnswerStatic>
        </div>

        <ButtonInputCover onClick={handleClick}>Exemplo</ButtonInputCover>
      </Container>
      <Modal open={isOpened} onClose={setIsOpened} buttonDisabled={false} twoButtons onClick={onClick} confirmLabel="Começar">
        <>{children}</>
      </Modal>
    </>
  );
};

export default memo(StaticModal);
