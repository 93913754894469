import { ADD_CLIENT } from './actionTypes';
import { InsuranceModel } from '../reducers/insuranceReducer';

interface ActionProps {
  type: string;
  payload: InsuranceModel;
}

export const changeInsuranceAction = (client: InsuranceModel): ActionProps => {
  return {
    type: ADD_CLIENT,
    payload: client,
  };
};
