import { CoverageCoverProps } from '../../components/CoverageCover';

export const BasicCoverages: CoverageCoverProps[] = [
  {
    id: String(Date.now() + 1),
    title: 'Colisão, incêndio, roubo e furto',
    customizationHelperText:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    price: 30000,
    tagParam: 'basiccoverages',
  },
  {
    id: String(Date.now() + 2),
    title: 'Danos corporais',
    customizationHelperText:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    price: 50000,
    tagPrice: 16.4,
    tagParam: 'basiccoverages',
  },
  {
    id: String(Date.now() + 3),
    title: 'Danos materiais',
    customizationHelperText:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    price: 10000,
    tagPrice: 26.4,
    tagParam: 'basiccoverages',
  },
  {
    id: String(Date.now() + 1),
    title: 'Danos morais ou estético',
    customizationHelperText:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    price: 50000,
    tagParam: 'basiccoverages',
  },
  {
    id: String(Date.now() + 1),
    title: 'Objetos transportados',
    customizationHelperText:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    price: 10000,
    tagParam: 'basiccoverages',
  },
  {
    id: String(Date.now() + 1),
    title: 'Assistência AutoMais 250 Km',
    customizationHelperText:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    type: 'Atendimento personalizado',
    tagParam: 'basiccoverages',
  },
  {
    id: String(Date.now() + 1),
    title: 'Assistência básica',
    customizationHelperText:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    type: 'Atendimento das 8h às 18h',
    tagPrice: 16.5,
    tagParam: 'basiccoverages',
  },
  {
    id: String(Date.now() + 1),
    title: 'AutoMais casa',
    customizationHelperText:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    type: 'Você pode agendar das 8h às 18h',
    tagParam: 'basiccoverages',
  },
];

export const AdditionalCoverages: CoverageCoverProps[] = [
  {
    id: String(Date.now() + 1),
    title: 'Morte e invalidez',
    customizationHelperText:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    price: 50000,
    tagPrice: 1.92,
    tagParam: 'additionalcoverages',
  },
  {
    id: String(Date.now() + 1),
    title: 'DMH (por ocupante)',
    customizationHelperText:
      'Indenização dos prejuízo que possa ter em caso de acidente de trânsito com o veículo segurado e que cause prejuízos materiais ou físico a terceiros.',
    price: 10000,
    tagPrice: 16.4,
    tagParam: 'additionalcoverages',
  },
  {
    id: String(Date.now() + 1),
    title: 'Decessos',
    customizationHelperText:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    price: 20000,
    tagParam: 'additionalcoverages',
  },
  {
    id: String(Date.now() + 1),
    title: 'Carro reserva',
    customizationHelperText:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    price: 10000,
    tagParam: 'additionalcoverages',
  },
  {
    id: String(Date.now() + 1),
    title: 'Troca de vidros',
    customizationHelperText:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    type: 'Básica',
    // typeOptions: ['Básica', 'Completa', 'Premium'],
    tagParam: 'additionalcoverages',
  },
];
