import { UPDATE_PERSONAL_LOANS } from '../actions';
import { ResultQuoteResponse, SimulationQuoteResponse } from '../api/generated/personal-loans';
import { Domain, LoanReduxModel, QuoteReduxModel } from '../models';
import { CustomerData } from '../api/models/personal-loans';

export type PersonalLoansReduxModel = {
  customer?: CustomerData;
  quotes?: SimulationQuoteResponse[];
  currentQuote: QuoteReduxModel;
  loan?: LoanReduxModel;
  loans?: SimulationQuoteResponse[];
};

const INITIAL_STATE: PersonalLoansReduxModel = {
  quotes: [],
  currentQuote: {
    quoteId: '',
    loan: {
      installmentPlan: {} as Domain,
      resultQuote: {} as ResultQuoteResponse,
    },
  } as QuoteReduxModel,
};

const PersonalLoansReducer = (
  state = INITIAL_STATE,
  action: { type: string; payload: PersonalLoansReduxModel },
): PersonalLoansReduxModel => {
  switch (action.type) {
    case UPDATE_PERSONAL_LOANS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default PersonalLoansReducer;
