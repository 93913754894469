/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CalculateSimulationCVGResponse } from '../models/CalculateSimulationCVGResponse';
import type { ResultQuoteCPRequest } from '../models/ResultQuoteCPRequest';
import type { ResultQuoteCVGRequest } from '../models/ResultQuoteCVGRequest';
import type { ResultQuoteResponse } from '../models/ResultQuoteResponse';
import { request as __request } from '../core/request';

export class ResultQuoteControllerService {

    /**
     * simulationQuoteCP
     * @param request request
     * @param acceptLanguage Accept-Language
     * @returns ResultQuoteResponse OK
     * @returns any Created
     * @throws ApiError
     */
    public static async simulationQuoteCpUsingPost(
        request: ResultQuoteCPRequest,
        acceptLanguage?: string,
    ): Promise<ResultQuoteResponse | any> {
        const result = await __request({
            method: 'POST',
            path: `/api/personal_loans/create/simulation/product/CP`,
            headers: {
                'Accept-Language': acceptLanguage,
            },
            body: request,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * simulationQuoteCVG
     * @param request request
     * @param acceptLanguage Accept-Language
     * @returns ResultQuoteResponse OK
     * @returns any Created
     * @throws ApiError
     */
    public static async simulationQuoteCvgUsingPost(
        request: ResultQuoteCVGRequest,
        acceptLanguage?: string,
    ): Promise<ResultQuoteResponse | any> {
        const result = await __request({
            method: 'POST',
            path: `/api/personal_loans/create/simulation/product/CVG`,
            headers: {
                'Accept-Language': acceptLanguage,
            },
            body: request,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * limitSimulationQuoteCVG
     * @param request request
     * @param acceptLanguage Accept-Language
     * @returns CalculateSimulationCVGResponse OK
     * @returns any Created
     * @throws ApiError
     */
    public static async limitSimulationQuoteCvgUsingPost(
        request: ResultQuoteCVGRequest,
        acceptLanguage?: string,
    ): Promise<CalculateSimulationCVGResponse | any> {
        const result = await __request({
            method: 'POST',
            path: `/api/personal_loans/create/simulation/product/limit/CVG`,
            headers: {
                'Accept-Language': acceptLanguage,
            },
            body: request,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

}