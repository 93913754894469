// Common
export const UPDATE_INFO_USER = 'UPDATE_INFO_USER';

// insurance
export const ADD_CLIENT = 'ADD_CLIENT';
export const GET_CEP = 'GET_CEP';
export const CHANGE_STEP = 'CHANGE_STEP';
export const SUM_STEP = 'SUM_STEP';
export const ADD_CAR = 'ADD_CAR';

// Knowing The Customer
export const UPDATE_INFO = 'UPDATE_INFO';

// Personal Credit
export const UPDATE_PERSONAL_LOANS = 'UPDATE_PERSONAL_LOANS';
