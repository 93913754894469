/* eslint-disable react/jsx-props-no-spreading */
import { memo, useCallback, useState } from 'react';

import { ButtonProps } from '../Button';
import Modal from '../Modal';
import theme from '../../styles/theme';

import { StyledButton, Wrapper } from './styles';
import { Content, FormSubtitle, FormTitle } from '../styles';

interface Props extends ButtonProps {
  titleModal: string;
  subTitleModal: string;
  confirmLabel?: string;
  secondaryClick?: () => void;
  onOpen?: () => void;
}

const ButtonModal = ({
  children,
  color = 'white',
  background = 'blueStrong',
  fullWidth,
  maxWidth,
  disabled,
  paddingY,
  paddingX,
  noMargin,
  onClick,
  secondaryClick,
  onOpen,
  titleModal,
  subTitleModal,
  confirmLabel,
  ...rest
}: Props): JSX.Element => {
  const [isOpened, setIsOpened] = useState<boolean>(false);

  const openModal = useCallback(() => setIsOpened(prev => !prev), []);

  const handleClick = e => {
    const ripples = document.createElement('span');
    const buttonPosition = e.target.getBoundingClientRect();
    const top = Math.abs(buttonPosition.top - e.clientY);
    const left = Math.abs(buttonPosition.left - e.clientX);

    ripples.style.top = `${top}px`;
    ripples.style.left = `${left}px`;

    e.target.appendChild(ripples);

    setTimeout(() => {
      ripples.remove();

      if (isOpened) {
        setIsOpened(false);
        return onClick && onClick();
      }

      if (onOpen) onOpen();

      return openModal();
    }, 300);
  };

  return (
    <>
      <Wrapper noMargin={noMargin} fullWidth={fullWidth} paddingX={paddingX} paddingY={paddingY}>
        <StyledButton
          type="button"
          disabled={disabled}
          aria-label={children}
          aria-disabled={disabled}
          style={{
            color: disabled ? theme.colors.textDisable : theme.colors[color],
            backgroundColor: disabled ? theme.colors.greyBackground : theme.colors[background],
            maxWidth,
          }}
          onClick={handleClick}
          {...rest}
        >
          {children}
        </StyledButton>
        <Modal
          open={isOpened}
          onClose={setIsOpened}
          buttonDisabled={false}
          twoButtons
          confirmLabel={confirmLabel}
          onClick={onClick}
          secondaryClick={() => {
            if (secondaryClick) secondaryClick();
            setIsOpened(false);
          }}
        >
          <Content>
            <FormTitle>{titleModal}</FormTitle>
            <FormSubtitle style={{ marginTop: 20 }}>{subTitleModal}</FormSubtitle>
          </Content>
        </Modal>
      </Wrapper>
    </>
  );
};

export default memo(ButtonModal);
