import { StepProps } from './Steps.reducer';

interface ActionProps {
  type: string;
  payload: StepProps;
}

export const changeStep = (currentStep: number, totalSteps: number): ActionProps => {
  return {
    type: 'CHANGE_STEP',
    payload: { currentStep, totalSteps },
  };
};

export const sumStep = (quantity: number): { type: string; payload: number } => {
  return {
    type: 'SUM_STEP',
    payload: quantity,
  };
};

export const subtractsStep = (quantity: number): { type: string; payload: number } => {
  return {
    type: 'SUB_STEP',
    payload: quantity,
  };
};

export const currentStep = (step: number): { type: string; payload: number } => {
  return {
    type: 'CUR_STEP',
    payload: step,
  };
};

export const pageStep = (
  step: number,
  type: 'OCC_STEP' | 'WHO_STEP' | 'FUE_STEP' | 'RGN_STEP' | 'ICO_STEP' | 'OFF_STEP',
): { type: string; payload: number } => {
  return {
    type,
    payload: step,
  };
};

export const brandChange = (
  brand: 'bvHeader' | 'mapfreHeader' | 'tokyoHeader' | 'questionCircle' | 'wallet' | undefined,
): { type: string; payload: string | undefined } => {
  return {
    type: 'BRAND_STEP',
    payload: brand,
  };
};
