import { memo } from 'react';
import { LinearProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { bvHeader, logoDefault, mapfreHeader, questionCircle, tokyoHeader } from '../../assets';
import { NativeRoutes } from '../../data';
import { changeStep, subtractsStep, StepProps, RootState, selectInsurance } from '../../store';
import { formatNumber, normalize, returnToNativeWithNavigation } from '../../utils';

import {
  ArrowBackIcon,
  Brand,
  CloseIcon,
  Container,
  ContentBrand,
  FabButton,
  HelpBrand,
  Text,
  Wallet,
  WalletText,
} from './styles';

interface HeaderFormProps {
  stepper?: 'yellow' | 'top' | 'none';
  closeAction?: () => void;
  backAction?: () => void;
  helpAction?: () => void;
  hideBackButton?: boolean;
  onlyCustomBackAction?: boolean;
  colorbackground: 'blueTypo' | 'white';
}

const HeaderForm = ({
  stepper = 'yellow',
  closeAction,
  backAction,
  helpAction,
  hideBackButton = false,
  onlyCustomBackAction = false,
  colorbackground,
}: HeaderFormProps): JSX.Element => {
  const dispatch = useDispatch();
  const step: StepProps = useSelector((state: RootState) => state.step);
  const { currentQuote } = useSelector(selectInsurance);
  const logo = {
    bvHeader,
    mapfreHeader,
    tokyoHeader,
    logoDefault,
    questionCircle,
  };
  const { goBack } = useHistory();

  const leadingZeros = (number = 0) => number.toString().padStart(2, '0');

  console.log('step', step);
  console.log('closeAction', closeAction);
  console.log('stepper', stepper);

  const updateSteps = () => {
    if (step.currentStep <= 1) {
      return dispatch(changeStep(0, 0));
    }
    return dispatch(subtractsStep(1));
  };

  const goBackStep = () => {
    if (backAction) {
      backAction();
    }
    if (onlyCustomBackAction) return;
    updateSteps();
    goBack();
  };

  return (
    <>
      {stepper === 'top' && (
        <LinearProgress
          style={{ width: 97, borderRadius: '100px' }}
          variant="determinate"
          value={normalize(step.currentStep, step.totalSteps)}
        />
      )}
      <Container colorbackground={colorbackground} alignToRight={!!hideBackButton} stepper={stepper} className="header-form">
        {!hideBackButton && (
          <FabButton onClick={goBackStep} aria-label="back to the previous page" colorbackground={colorbackground}>
            <ArrowBackIcon colorbackground={colorbackground} />
          </FabButton>
        )}
        {closeAction && (
          <FabButton onClick={closeAction} aria-label="back to the previous page" colorbackground={colorbackground}>
            <CloseIcon />
          </FabButton>
        )}
        {step.brand && step.brand !== 'wallet' && (
          <ContentBrand
            onClick={() => {
              if (helpAction) {
                helpAction();
                returnToNativeWithNavigation(NativeRoutes.HELP);
              }
            }}
          >
            <Brand src={logo[step.brand]} alt="logo with insurance" question={step.brand === 'questionCircle'} loading="lazy" />
            {step.brand === 'questionCircle' && <HelpBrand>Ajuda</HelpBrand>}
          </ContentBrand>
        )}

        {step.brand === 'wallet' && (
          <ContentBrand>
            <Wallet colorbackground={colorbackground} />
            <WalletText colorbackground={colorbackground}>
              Saldo em conta <strong>{formatNumber.currency(currentQuote?.insurance?.balance)}</strong>
            </WalletText>
          </ContentBrand>
        )}

        {!closeAction && stepper === 'yellow' && (
          <div>
            <Text>{`Etapa ${leadingZeros(step.currentStep)} de ${leadingZeros(step.totalSteps)}`}</Text>
            <LinearProgress
              style={{ width: 97, borderRadius: '100px' }}
              variant="determinate"
              color="secondary"
              value={normalize(step.currentStep, step.totalSteps)}
            />
          </div>
        )}
      </Container>
    </>
  );
};

export default memo(HeaderForm);
