/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ModelPerYearRequestBody } from '../models/ModelPerYearRequestBody';
import type { ModelRequestBody } from '../models/ModelRequestBody';
import type { ValueRequestBody } from '../models/ValueRequestBody';
import type { VehicleRequestBody } from '../models/VehicleRequestBody';
import type { YearPearModelRequestBody } from '../models/YearPearModelRequestBody';
import { request as __request } from '../core/request';

export class FipeControllerService {

    /**
     * getBrands
     * @param requestBody requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static async getBrandsUsingPost(
        requestBody: VehicleRequestBody,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/fipe/brand`,
            body: requestBody,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * getModelPerYear
     * @param requestBody requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static async getModelPerYearUsingPost(
        requestBody: ModelPerYearRequestBody,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/fipe/by-model-year`,
            body: requestBody,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * getModels
     * @param requestBody requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static async getModelsUsingPost(
        requestBody: ModelRequestBody,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/fipe/models`,
            body: requestBody,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * getReferenceTable
     * @returns any OK
     * @throws ApiError
     */
    public static async getReferenceTableUsingGet(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/v1/reference-table`,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * getValue
     * @param requestBody requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static async getValueUsingPost(
        requestBody: ValueRequestBody,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/fipe/code`,
            body: requestBody,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * getYearModel
     * @param requestBody requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static async getYearModelUsingPost(
        requestBody: YearPearModelRequestBody,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/fipe/by-year-model`,
            body: requestBody,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

}