import { UPDATE_PERSONAL_LOANS } from './actionTypes';
import { PersonalLoansReduxModel } from '../reducers/personalLoansReducer';

interface ActionProps {
  type: string;
  payload: PersonalLoansReduxModel;
}
export const changePersonalCreditInfoAction = (personalCredit: PersonalLoansReduxModel): ActionProps => {
  return {
    type: UPDATE_PERSONAL_LOANS,
    payload: personalCredit,
  };
};
