import { memo } from 'react';
import { CircularProgress, CircularProgressProps, styled } from '@material-ui/core';
import { animated, useSpring } from 'react-spring';

import PortalModal from '../Modal/Portal';
import { Overlay } from '../Modal/styles';

export const CircularLoading = styled(CircularProgress)<CircularProgressProps>(() => ({
  width: 300,
  color: '#0069E7',
})) as (props: CircularProgressProps) => JSX.Element;

const Loading = () => {
  const animation = useSpring({
    config: {
      duration: 250,
    },
    width: '100vw',
    height: '100vh',
    transform: 'translateY(0%)',
  });

  return (
    <PortalModal>
      <Overlay style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
        <animated.div
          style={{
            ...animation,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularLoading thickness={3} size={80} />
        </animated.div>
      </Overlay>
    </PortalModal>
  );
};

export default memo(Loading);
