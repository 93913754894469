import styled from 'styled-components/macro';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ButtonComponent from '../Button';
import { pxToRem } from '../../utils';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  background-color: rgba(78, 89, 131, 0.5);
  backdrop-filter: blur(5px);

  z-index: 999;
`;

export const Dialog = styled.div`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;

  width: 100%;
  min-height: ${pxToRem(224)};
  max-height: 85vh;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.white};
  border-top-left-radius: ${pxToRem(16)};
  border-top-right-radius: ${pxToRem(16)};
`;

export const Icon = styled(ExpandMoreIcon)`
  color: ${({ theme }) => theme.colors.blueGrayLight};
  margin-top: ${pxToRem(22)};
  margin-bottom: ${pxToRem(16)};
`;

export const DialogContent = styled.div`
  overflow-y: auto;
  width: 100%;
`;

export const DialogContainer = styled.div`
  background: white;
  width: 100%;
`;

export const Button = styled(ButtonComponent)`
  border-radius: 0;
  margin-top: 0 !important;
`;

export const Primario = styled(ButtonComponent)`
  width: ${pxToRem(296)};
  border-radius: ${pxToRem(16)};
  @media (max-width: 320px), (max-height: 640px) {
    margin: ${pxToRem(16)} 0 ${pxToRem(16)};
  }

  button {
    margin: ${pxToRem(20)} 0 ${pxToRem(32)};
    padding: 0;
  }
`;

export const Phone = styled.a`
  font-family: Nunito;
  font-weight: 700;
  font-style: normal;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(19)};
  text-align: center;
  text-decoration: none;

  color: ${({ theme }) => theme.colors.white} !important;
  background-color: ${({ theme }) => theme.colors.blueStrong};

  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pxToRem(296)};
  height: ${pxToRem(56)};
  margin: ${pxToRem(32)} 0 ${pxToRem(32)};
  border: none;
  box-sizing: border-box;
  border-radius: ${pxToRem(16)};
  overflow: hidden;
  position: relative;

  &:link {
    color: ${({ theme }) => theme.colors.white} !important;
    background-color: ${({ theme }) => theme.colors.blueStrong};
  }

  &:hover {
    opacity: 0.63;
  }

  span {
    position: absolute;
    background-color: #000;
    transform: translate(-50%, -50%);
    pointer-events: none;
    border-radius: 50%;
  }
`;

export const Secundario = styled(Primario)`
  order: 2;

  mix-blend-mode: normal;

  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(22)};

  button {
    margin: ${pxToRem(-12)} 0 ${pxToRem(32)};
    padding: 0;
  }
`;
