export enum AfterSalesEnum {
  NO = 'No current insurance',
  OI = 'Only 1 insurance and no pending issues',
  PSAI = 'Pending Simulation and Active Insurance',
  PAC = 'Payment Already Completed',
  CP = 'Cash Payment',
  CCPE = 'Credit Card Payment Error',
  EPAD = 'Error in Payment on Account Debit',
  IC = 'Insurance Canceled',
}
