import styled from 'styled-components/macro';
import { pxToRem } from '../../utils/pxToRem';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${pxToRem(20)} ${pxToRem(0)};
`;

export const ValueLabel = styled.p`
  font-family: Nunito;
  font-size: ${pxToRem(24)};
  font-weight: 700;
  line-height: ${pxToRem(33)};
  color: ${({ theme }) => theme.colors.blueStrong};
  text-align: left;
`;

export const EditIcon = styled.img`
  margin-top: ${pxToRem(9)};
  margin-left: ${pxToRem(9)};
  height: ${pxToRem(13)} !important;
  width: ${pxToRem(13)} !important;
`;

export const SliderWrapper = styled.div`
  width: 100%;
`;

export const Description = styled.p`
  font-family: Nunito;
  font-weight: 600;
  font-style: normal;
  font-size: ${pxToRem(12)};
  line-height: ${pxToRem(16)};
  opacity: 0.65;
  color: ${({ theme }) => theme.colors.blueTypo};
  margin-bottom: 0;
`;

export const ValueWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin-top: ${pxToRem(16)};
`;

export const Title = styled.p`
  font-family: Nunito;
  font-weight: 400;
  font-style: normal;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(19)};
  color: ${({ theme }) => theme.colors.blueTypo};
`;
