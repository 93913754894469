export enum DomainTypeEnum {
  INSURANCE_COMPANY = 'insurance_company',
  GENDER = 'gender',
  MARITAL_STATUS = 'marital_status',
  COMMISSION = 'commission',
  ARMORED = 'armored',
  TAX_EXEMPTION_TYPE = 'tax_exemption_type',
  ISSUING_AGENCY = 'issuing_agency',
  INCOME_TIER = 'income_tier',
  COBERTURAS = 'coberturas',
  PROFESSION = 'profession',
  RELATION_HOLDER = 'relation_holder',
}
