/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AddressResponse } from './AddressResponse';
import type { BankAccountResponse } from './BankAccountResponse';
import type { ContactResponse } from './ContactResponse';
import type { CustomerDocumentResponse } from './CustomerDocumentResponse';
import type { ResponseError } from './ResponseError';

export type CustomerLoanResponse = {
    bankAccountResponse?: BankAccountResponse;
    birthDate?: string;
    contacts?: Array<ContactResponse>;
    currentSituation?: string;
    customerAddresses?: Array<AddressResponse>;
    customerDocuments?: Array<CustomerDocumentResponse>;
    document?: string;
    email?: string;
    errors?: Array<ResponseError>;
    firstName?: string;
    fullName?: string;
    gender?: string;
    id?: string;
    lastName?: string;
    maritalStatus?: CustomerLoanResponse.maritalStatus;
    monthlyIncome?: number;
    motherName?: string;
    nacionality?: string;
    occupationCode?: number;
    occupationType?: number;
    placeOfBirthCity?: string;
    placeOfBirthState?: string;
    totalPatrimony?: number;
    typePeople?: string;
}

export namespace CustomerLoanResponse {

    export enum maritalStatus {
        NAO_INFORMADO = 'NAO_INFORMADO',
        DIVORCIADO = 'DIVORCIADO',
        SEPARADO = 'SEPARADO',
        SOLTEIRO = 'SOLTEIRO',
        CASADO = 'CASADO',
        VIUVO = 'VIUVO',
        UNKNOWN = 'UNKNOWN',
    }


}
