import { memo } from 'react';

import { Answer, Container, Question, Tel } from './styles';
import { callDialer } from '../../utils';

interface Props {
  action?: () => void;
  buttonLabel: string;
  placeholder: string;
  question: string;
  tel: string;
}

const InputCoverTel = ({ action, buttonLabel, placeholder, question, tel }: Props): JSX.Element => {
  const handleClick = e => {
    callDialer(tel);

    const ripples = document.createElement('span');
    const buttonPosition = e.target.getBoundingClientRect();
    const top = Math.abs(buttonPosition.top - e.clientY);
    const left = Math.abs(buttonPosition.left - e.clientX);

    ripples.style.top = `${top}px`;
    ripples.style.left = `${left}px`;

    e.target.appendChild(ripples);

    setTimeout(() => {
      ripples.remove();
      if (action) action();
    }, 300);
  };

  return (
    <Container>
      <div>
        <Question>{question}</Question>
        <Answer>{placeholder}</Answer>
      </div>
      <Tel onClick={handleClick}>{buttonLabel}</Tel>
    </Container>
  );
};

export default memo(InputCoverTel);
