/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomerAllowListResponse } from '../models/CustomerAllowListResponse';
import { request as __request } from '../core/request';

export class CustomerAllowListReadControllerService {

    /**
     * findByCustomerDocument
     * @param customerDocument customerDocument
     * @param acceptLanguage Accept-Language
     * @returns CustomerAllowListResponse OK
     * @throws ApiError
     */
    public static async findByCustomerDocumentUsingGet(
        customerDocument: string,
        acceptLanguage?: string,
    ): Promise<CustomerAllowListResponse> {
        const result = await __request({
            method: 'GET',
            path: `/api/personal_loans/allow_list/mar_aberto`,
            headers: {
                'Accept-Language': acceptLanguage,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

}