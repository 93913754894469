import styled from 'styled-components/macro';

import { ReactComponent as MasterCardSVG } from '../../assets/icons/insurance/payment/masterCard.svg';
import { ReactComponent as WalletSVG } from '../../assets/icons/insurance/payment/wallet.svg';
import { pxToRem } from '../../utils';

export const Content = styled.section`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: calc(100% - ${pxToRem(87)});

  div {
    width: 100%;
  }
`;

export const Title = styled.h2`
  font-family: Nunito;
  font-weight: 600;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(24)};
  color: ${({ theme }) => theme.colors.tokenColorBlue200};

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Line = styled.span`
  font-family: Nunito;
  font-weight: 700;
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(24)};
  color: ${({ theme }) => theme.colors.blueTypo};

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Wallet = styled(WalletSVG).attrs({
  width: pxToRem(24),
  height: pxToRem(24),
})`
  margin-right: ${pxToRem(16)};

  path {
    fill: ${({ theme }) => theme.colors.blueTypo};
  }
`;

export const MasterCard = styled(MasterCardSVG).attrs({
  width: pxToRem(24),
  height: pxToRem(24),
})`
  margin-right: ${pxToRem(16)};
`;
