/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type BenefitRequest = {
    simulationUuid?: string;
    partner?: string;
    product?: string;
    benefitType?: BenefitRequest.benefitType;
}

export namespace BenefitRequest {

    export enum benefitType {
        KMV = 'KMV',
        CASHBACK = 'CASHBACK',
        UNKNOW = 'UNKNOW',
    }


}
