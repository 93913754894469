import { lazy } from 'react';

/* Survey */
export const HomeSurvey = lazy(() => import('../pages/Insurance/Survey/HomeSurvey'));
const StartSurvey = lazy(() => import('../pages/Insurance/Survey/StartSurvey'));
const FluxoParceiro = lazy(() => import('../pages/Insurance/Survey/FluxoParceiro'));

export const SURVEY = [
  { path: '/survey/home', component: HomeSurvey },
  { path: '/survey/start', component: StartSurvey },
  { path: '/survey/fluxo', component: FluxoParceiro },
];
