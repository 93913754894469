import styled from 'styled-components/macro';
import { CarouselIndicators } from 'reactstrap';
import { pxToRem } from '../../utils';

export const Container = styled.div``;

export const Indicators = styled(CarouselIndicators)`
  margin-top: 0.75rem;
  margin-bottom: ${pxToRem(16)};

  li {
    border-radius: 50%;
  }
`;
