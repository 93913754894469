/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CustomerLoanResponse } from './CustomerLoanResponse';
import type { FlowSimulationReponse } from './FlowSimulationReponse';
import type { ProposalLoanResponse } from './ProposalLoanResponse';
import type { ResponseError } from './ResponseError';
import type { ResultQuoteResponse } from './ResultQuoteResponse';
import type { VehicleResponse } from './VehicleResponse';

export type SimulationQuoteResponse = {
    acceptProtocol?: string;
    countInstallments?: number;
    createdAt?: string;
    customerId?: string;
    customerLoan?: CustomerLoanResponse;
    dueDateFirstInstallments?: string;
    errors?: Array<ResponseError>;
    firstInstalment?: number;
    flowSimulation?: FlowSimulationReponse;
    id?: string;
    installmentValue?: number;
    installmentsPaid?: number;
    partnerQuoteId?: string;
    paymentType?: string;
    productType?: SimulationQuoteResponse.productType;
    proposalLoan?: ProposalLoanResponse;
    resultQuote?: ResultQuoteResponse;
    status?: string;
    valueLoan?: number;
    vehicle?: VehicleResponse;
    waitingDay?: number;
    zeroKmVehicle?: boolean;
}

export namespace SimulationQuoteResponse {

    export enum productType {
        CP = 'CP',
        CVG = 'CVG',
    }


}
