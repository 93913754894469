/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { request as __request } from '../core/request';

export class QuoteControllerService {
  /**
   * @param xCustomerUuid
   * @param quoteId
   * @param requestBody
   * @param acceptLanguage
   * @returns any OK
   * @throws ApiError
   */
  public static async save(xCustomerUuid: string, quoteId: string, requestBody: any, acceptLanguage?: string): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/insurance/simulation/quote/${quoteId}`,
      headers: {
        'x-customer-uuid': xCustomerUuid,
        'Accept-Language': acceptLanguage,
      },
      body: requestBody,
    });
    return result.body;
  }

  /**
   * @param xCustomerUuid
   * @param acceptLanguage
   * @returns any OK
   * @throws ApiError
   */
  public static async quoteSimulation(xCustomerUuid: string, acceptLanguage?: string): Promise<any> {
    const result = await __request({
      method: 'POST',
      path: `/insurance/create/simulation/`,
      headers: {
        'x-customer-uuid': xCustomerUuid,
        'Accept-Language': acceptLanguage,
      },
    });
    return result.body;
  }

  /**
   * @param xCustomerUuid
   * @param quoteId
   * @param acceptLanguage
   * @returns any OK
   * @throws ApiError
   */
  public static async calculation(xCustomerUuid: string, quoteId: string, acceptLanguage?: string): Promise<any> {
    const result = await __request({
      method: 'POST',
      path: `/insurance/create/quote/${quoteId}`,
      headers: {
        'x-customer-uuid': xCustomerUuid,
        'Accept-Language': acceptLanguage,
      },
    });
    return result.body;
  }

  /**
   * @param xCustomerUuid
   * @param quoteId
   * @param acceptLanguage
   * @returns any OK
   * @throws ApiError
   */
  public static async closeQuoteSimulation(xCustomerUuid: string, acceptLanguage?: string): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: '/insurance/cancel/quote',
      headers: {
        'x-customer-uuid': xCustomerUuid,
        'Accept-Language': acceptLanguage,
      },
    });
    return result.body;
  }
}
