import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import theme from '../../styles/theme';

import { Container, Label } from './styles';

interface WhiteButtonProps {
  label: string;
  action: () => void;
  icon?: string;
}

const WhiteButton = ({ label, action, icon }: WhiteButtonProps): JSX.Element => {
  const handleClick = e => {
    const ripples = document.createElement('span');
    const buttonPosition = e.target.getBoundingClientRect();
    const top = Math.abs(buttonPosition.top - e.clientY);
    const left = Math.abs(buttonPosition.left - e.clientX);

    ripples.style.top = `${top}px`;
    ripples.style.left = `${left}px`;

    e.target.appendChild(ripples);

    setTimeout(() => {
      ripples.remove();

      return action();
    }, 300);
  };

  return (
    <Container aria-hidden="true" onClick={handleClick}>
      <div>
        {icon && <img src={icon} alt={`Ícone da opção ${label}`} loading="lazy" />}
        <Label icon={!!icon}>{label}</Label>
      </div>
      <KeyboardArrowRightIcon style={{ color: theme.colors.blueTypo }} />
    </Container>
  );
};

export default WhiteButton;
