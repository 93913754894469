import styled, { keyframes } from 'styled-components/macro';

import { ReactComponent as InfoSVG } from '../../assets/icons/info-icon-blue.svg';

import { pxToRem } from '../../utils';

const animate = keyframes`
  0% {
    width: 0px;
    height: 0px;
    opacity: 0.5;
  }
  100% {
    width: ${pxToRem(500)};
    height: ${pxToRem(500)};
    opacity: 0;
  }
`;

export const Container = styled.div<{ varyingHeight?: boolean }>`
  width: 100%;
  ${p => !p.varyingHeight && `height: ${pxToRem(110)};`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueLight};
  padding: ${pxToRem(16)} ${pxToRem(32)} ${pxToRem(24)};

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const Question = styled.p`
  font-family: Nunito;
  font-weight: 700;
  font-style: normal;
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(22)};

  color: ${({ theme }) => theme.colors.blue};
  mix-blend-mode: normal;
  margin: ${pxToRem(8)} 0;

  order: 0;
  flex-grow: 0;
`;

export const BoxInfo = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${pxToRem(8)} 0;
  background-color: rgba(255, 96, 0, 0.1);
  padding: ${pxToRem(8)} ${pxToRem(10)};
  border-radius: ${pxToRem(16)};

  order: 1;
  flex-grow: 0;
`;

export const InfoIcon = styled(InfoSVG).attrs({
  width: pxToRem(16),
  height: pxToRem(16),
})`
  margin-right: ${pxToRem(4)};
  order: 0;
  flex-grow: 0;

  path {
    fill: ${({ theme }) => theme.colors.orange};
  }
`;

export const Answer = styled.p<{ place?: string }>`
  font-family: Nunito;
  font-weight: 400;
  font-style: normal;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(19)};
  color: ${({ theme }) => theme.colors.blueTypo};
  margin-bottom: 0;
`;

export const InfoText = styled.span`
  font-family: Nunito;
  font-weight: 400;
  font-style: normal;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(19)};

  color: ${({ theme }) => theme.colors.orange};
  opacity: 1;
  order: 1;
  flex-grow: 0;
`;

export const InputButton = styled.button`
  font-family: Nunito;
  font-weight: 700;
  font-style: normal;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(19)};
  text-align: center;
  text-decoration: none;
  cursor: pointer;

  color: ${({ theme }) => theme.colors.blueStrong};
  background: white;

  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${pxToRem(87)};
  height: ${pxToRem(40)};
  border: 2px solid ${({ theme }) => theme.colors.blueStrong}1A;
  box-sizing: border-box;
  border-radius: ${pxToRem(16)};
  overflow: hidden;
  position: relative;

  &:link {
    color: ${({ theme }) => theme.colors.blueStrong};
  }

  span {
    position: absolute;
    background-color: #000;
    transform: translate(-50%, -50%);
    pointer-events: none;
    border-radius: 50%;
    animation: ${animate} 1s linear infinite;
  }
`;
