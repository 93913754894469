import styled from 'styled-components/macro';

import { ReactComponent as CloseIconSVG } from '../../assets/icons/close-icon.svg';
import { pxToRem } from '../../utils';

export const ContentIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  div {
    background-color: rgba(255, 255, 255, 0.1);
    position: absolute;
    top: ${pxToRem(32)};
    right: ${pxToRem(24)};
    width: ${pxToRem(48)};
    height: ${pxToRem(48)};
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const CloseIcon = styled(CloseIconSVG).attrs({
  width: pxToRem(12),
  height: pxToRem(12),
})`
  path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;
