import styled, { keyframes } from 'styled-components/macro';

import { Content } from '../Modal/SelectModal/styles';
import { pxToRem } from '../../utils';

const animate = keyframes`
  0% {
    width: 0px;
    height: 0px;
    opacity: 0.5;
  }
  100% {
    width: ${pxToRem(500)};
    height: ${pxToRem(500)};
    opacity: 0;
  }
`;

export const ContainerBarCodeModal = styled.button`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0;
  align-items: center;
  height: ${pxToRem(70)};
  border: none;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueLight};
  padding: 0 ${pxToRem(32)};
  overflow: hidden;
  position: relative;

  div,
  svg {
    pointer-events: none;
  }

  span {
    position: absolute;
    background-color: #000;
    transform: translate(-50%, -50%);
    pointer-events: none;
    border-radius: 50%;
    animation: ${animate} 1.5s linear infinite;
  }
`;

export const LabelBarCodeModal = styled.p`
  font-family: Nunito;
  font-weight: 700;
  font-style: normal;
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(16)};
  letter-spacing: ${pxToRem(-0.44)};
  text-align: left;
  color: ${({ theme }) => theme.colors.blueStrong};
  margin-bottom: ${pxToRem(8)};
  pointer-events: none;
`;

export const TextBarCodeModal = styled(LabelBarCodeModal)`
  font-weight: 400;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(19)};
  color: ${({ theme }) => theme.colors.blueTypo};
  pointer-events: none;
`;

export const ContentBarCodeModal = styled(Content)`
  min-height: ${pxToRem(250)};
  margin-top: ${pxToRem(32)};
`;

export const TitleBarCodeModal = styled.h2`
  font-family: Nunito;
  font-weight: 700;
  font-size: ${pxToRem(24)};
  line-height: ${pxToRem(33)};
  letter-spacing: ${pxToRem(-0.44)};
  text-align: center;
  color: ${({ theme }) => theme.colors.blueTypo};
  margin: ${pxToRem(16)} ${pxToRem(32)} ${pxToRem(20)};
`;

export const PBarCodeModal = styled.p`
  font-family: Nunito;
  font-weight: 400;
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(22)};
  text-align: center;
  color: ${({ theme }) => theme.colors.blueTypo};
  margin: 0 ${pxToRem(32)} ${pxToRem(20)};
  white-space: pre-wrap;
`;
