import styled from 'styled-components/macro';
import { pxToRem } from '../../utils';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.greyBackground};
  padding: ${pxToRem(16)};
  border-radius: ${pxToRem(16)};
`;

export const Subtitle = styled.p`
  font: normal ${pxToRem(14)} Nunito;
  color: ${({ theme }) => theme.colors.blueTypo};
  margin-bottom: ${pxToRem(3)};
  line-height: ${pxToRem(19)};
`;
