/* eslint-disable react/no-array-index-key */
import { memo, useState } from 'react';

import { ButtonInputCover, ContentInput, ContentModal, Input, Question, Title } from '../InputCover/styles';
import Modal from '../Modal';
import { Container, Line } from './styles';

type Props = {
  lines: string[];
  question: string;
  action: (value?: any) => void;
  buttonLabel?: string;
  titleModal?: string;
  placeholder?: string;
  placeholderModal?: string;
  strong?: boolean;
  noModal?: boolean;
};

const InputLines = ({
  buttonLabel,
  lines,
  question,
  action,
  titleModal,
  placeholder,
  placeholderModal,
  strong = false,
  noModal = false,
}: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const openModal = () => setShowModal(prev => !prev);

  const handleChange = value => setInputValue(value);

  const handleClick = e => {
    const ripples = document.createElement('span');
    const buttonPosition = e.target.getBoundingClientRect();
    const top = Math.abs(buttonPosition.top - e.clientY);
    const left = Math.abs(buttonPosition.left - e.clientX);

    ripples.style.top = `${top}px`;
    ripples.style.left = `${left}px`;

    e.target.appendChild(ripples);

    setTimeout(() => {
      ripples.remove();
      if (noModal) return action();

      return openModal();
    }, 300);
  };

  return (
    <>
      <Container>
        <div>
          <Question>{question}</Question>
          {lines.map((line, index) => (
            <Line key={String(index)}>{index === 0 || strong ? <strong>{line}</strong> : line}</Line>
          ))}
        </div>

        <ButtonInputCover onClick={handleClick}>{buttonLabel || 'Editar'}</ButtonInputCover>
      </Container>

      <Modal open={showModal} onClose={setShowModal} onClick={() => action(inputValue)}>
        <ContentModal>
          <Title>{titleModal || question}</Title>
          <ContentInput>
            <Input
              type="number"
              value={inputValue}
              placeholder={placeholderModal || placeholder}
              autoFocus
              onChange={e => handleChange(e.target.value)}
              onBlur={evt => evt.target.focus()}
            />
          </ContentInput>
        </ContentModal>
      </Modal>
    </>
  );
};

export default memo(InputLines);
