import styled from 'styled-components/macro';

import { pxToRem } from '../../utils';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: ${pxToRem(32)};
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueLight};
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  height: ${pxToRem(73)};

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
`;
