import styled from 'styled-components/macro';
import { pxToRem } from '../../utils';
import { ReactComponent as CheckSVG } from '../../assets/icons/check.svg';

export const SlideOfferLink = styled.a`
  font-family: Nunito;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  text-decoration: underline !important;
  color: ${({ theme }) => theme.colors.blueStrong}!important;
  margin-top: revert;
  margin-left: 0;
`;

export const Check = styled(CheckSVG)`
  width: ${pxToRem(16)};
  height: ${pxToRem(19)};
  margin-right: ${pxToRem(8)};
  position: relative;
  bottom: ${pxToRem(1)};

  path {
    fill: #00892e;
    stroke: #00892e;
  }
`;

export const SlideOfferContainer = styled.div`
  width: calc(100vw - ${pxToRem(64)});
`;

export const SlideOfferLogoContainer = styled.div`
  width: ${pxToRem(155)};
  height: ${pxToRem(51)};
  margin-left: auto;
  margin-right: auto;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${pxToRem(8)} ${pxToRem(16)};
  gap: ${pxToRem(8)};

  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);
  border-radius: ${pxToRem(30)};
  z-index: 1;
`;

export const SlideOfferInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  div.texts {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${pxToRem(51 - 5.5)} ${pxToRem(16)} ${pxToRem(16)};

    width: 100%;

    background-color: ${({ theme }) => theme.colors.greyBackground};
    border-radius: 16px 16px 0px 0px;
    margin-top: calc(-51px / 2);
    padding-bottom: ${pxToRem(24)};

    div > p {
      font-family: 'Nunito', sans-serif;
      font-weight: 600;
      font-size: ${pxToRem(14)};
      line-height: 140%;
      text-align: center;
      color: ${({ theme }) => theme.colors.tokenColorBlue200};
    }

    div > p.current {
      font-weight: 700;
      font-size: ${pxToRem(24)};
      color: ${({ theme }) => theme.colors.blueTypo};
      margin: ${pxToRem(5)} 0 ${pxToRem(8)};
    }

    div > p.currentlabel {
      font-weight: 700;
      font-size: ${pxToRem(18)};
      color: ${({ theme }) => theme.colors.blueTypo};
      margin: ${pxToRem(5)} 0 ${pxToRem(8)};
    }
  }

  div.buttons {
    width: 100%;
    background-color: ${({ theme }) => theme.colors.greyBackground};
    padding: 0 ${pxToRem(22)} ${pxToRem(16)};
  }
`;

export const SlideOfferDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: ${pxToRem(16)} ${pxToRem(22)};

  border: 1px solid ${({ theme }) => theme.colors.greyBackground};
  border-radius: 0px 0px 16px 16px;

  p {
    font-family: 'Nunito', sans-serif;
    font-size: ${pxToRem(14)};
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0px;
    text-align: left;
    color: ${({ theme }) => theme.colors.blueTypo};
  }

  div {
    width: calc(100%);
  }

  p.coverage {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    font-weight: 600;
    margin-bottom: ${pxToRem(8)};
  }

  div + p {
    margin-top: ${pxToRem(8)};
  }

  div.coverage-additional-info-block {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;

    & > p {
      font-weight: bold;

      & > span {
        display: inline-block;
      }
    }
  }
`;
