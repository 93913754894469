/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProposalLoanRequest } from '../models/ProposalLoanRequest';
import type { ProposalLoanResponse } from '../models/ProposalLoanResponse';
import { request as __request } from '../core/request';

export class ProposalLoanControllerService {

    /**
     * updateCallbackProposal
     * @param request request
     * @param acceptLanguage Accept-Language
     * @returns ProposalLoanResponse OK
     * @returns any Created
     * @throws ApiError
     */
    public static async updateCallbackProposalUsingPut(
        request: ProposalLoanRequest,
        acceptLanguage?: string,
    ): Promise<ProposalLoanResponse | any> {
        const result = await __request({
            method: 'PUT',
            path: `/api/personal_loans/contract_credit/callback/status-proposal`,
            headers: {
                'Accept-Language': acceptLanguage,
            },
            body: request,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * proposalLoan
     * @param product product
     * @param simulationQuoteId simulationQuoteId
     * @param xCustomerUuid x-customer-uuid
     * @param acceptLanguage Accept-Language
     * @returns ProposalLoanResponse OK
     * @returns any Created
     * @throws ApiError
     */
    public static async proposalLoanUsingPost(
        product: string,
        simulationQuoteId: string,
        xCustomerUuid: string,
        acceptLanguage?: string,
    ): Promise<ProposalLoanResponse | any> {
        const result = await __request({
            method: 'POST',
            path: `/api/personal_loans/create/proposal-loan/product/${product}/${simulationQuoteId}`,
            headers: {
                'x-customer-uuid': xCustomerUuid,
                'Accept-Language': acceptLanguage,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

}