import {
  CoverageListRequest,
  CoverageQuoteControllerService,
  CoverageRiskItemListResponse,
  CustomerProspectControllerService,
  CustomerProspectRequest,
  DocumentHolderControllerService,
  DocumentHolderRequest,
  DomainListResponse,
  DomainReadControllerService,
  DriverControllerService,
  DriverRequest,
  DriverResponse,
  InsuranceHolderControllerService,
  InsuranceHolderRequest,
  InsuranceHolderResponse,
  OpenAPI,
  PartnerListResponse,
  PartnerReadControllerService,
  PaymentInstrumentControllerService,
  PaymentInstrumentRequest,
  PaymentInstrumentResponse,
  PaymentOptionListResponse,
  QuoteControllerService,
  QuoteListResponse,
  QuoteReadControllerService,
  QuoteRequest,
  QuoteResponse,
  ResultQuoteControllerService,
  ResultQuoteDetailResponse,
  ResultQuoteListResponse,
  ResultQuoteReadControllerService,
  RiskAddressControllerService,
  RiskAddressRequest,
  RiskItemReadControllerService,
  VehicleControllerService,
  VehicleRequest,
  VehicleResponse,
  ProposalReadControllerService,
  ProposalResponse,
} from './generated/insurance';
import CustomAPI from './CustomAPI';
import { DomainTypeEnum } from './enums';
import { Domain } from '../models';
import store from '../store/store';
import { capitalizeEveryFirstLetter, getAuthCookies } from '../utils';
import { Balance, CepResponse, CreditCard, ListCreditCard, RiskAddressResponse } from './models';
import { handleAxiosException } from './error';
import { SendProposalResponse } from './generated/insurance/models/ProposalResponse';
import { ProposalLeadRequest } from './generated/insurance/models/ProposalRequest';

const { save500Km, save1000Km } = CustomerProspectControllerService;
const { calculation, quoteSimulation, save, closeQuoteSimulation } = QuoteControllerService;
const { findCurrentProposal, sendProposalLead } = ProposalReadControllerService;
const { findByCustomerWithStatus, findByCustomerId } = QuoteReadControllerService;
const { domain } = DomainReadControllerService;
const { saveVechicle } = VehicleControllerService;
const { saveDriver: saveDriverUsingPost, updateDriver } = DriverControllerService;
const { save5, update2 } = InsuranceHolderControllerService;
const { save1 } = RiskAddressControllerService;
const { findCoveragesRiskItem, findVehicleByVLicense } = RiskItemReadControllerService;
const { save6, update3 } = DocumentHolderControllerService;
const { findByPartnerId } = PartnerReadControllerService;
const { saveCoverage } = CoverageQuoteControllerService;
const { save2 } = ResultQuoteControllerService;
const { findByUuid2, findByPaymentOption } = ResultQuoteReadControllerService;
const { save4, update1 } = PaymentInstrumentControllerService;

OpenAPI.BASE = process.env.REACT_APP_API_BASE_URL as string;
OpenAPI.HEADERS = getAuthCookies();
const { customerId = '' } = store?.getState()?.user;

const getQuoteId = () => {
  return store?.getState()?.client?.currentQuote?.quoteId || '';
};
const getContractId = () => store.getState().client.currentQuote?.insurance?.contract?.id || '';

const getInsuranceId = () => {
  return store?.getState()?.client?.currentQuote?.client?.id || '';
};

const convertSuthubDomain = (listDomain: DomainListResponse): Domain[] => {
  return (
    listDomain?.domains?.map(value => ({
      id: String(value.code),
      name: capitalizeEveryFirstLetter(value.description),
    })) || []
  );
};

export const create500km = async (body: CustomerProspectRequest): Promise<any> => {
  return save500Km(customerId, body, '*');
};

export const create1000km = async (body: CustomerProspectRequest): Promise<any> => {
  return save1000Km(customerId, body, '*');
};

export const getDomain = async (key: DomainTypeEnum): Promise<Domain[]> => {
  const response = await domain(key);
  return convertSuthubDomain(response);
};

export const createQuoteSimulation = async (): Promise<QuoteResponse> => {
  return quoteSimulation(customerId, '*');
};

export const closeOpenedSimulations = async (): Promise<any> => {
  return closeQuoteSimulation(customerId, '*');
};

export const createOffer = async (): Promise<ResultQuoteListResponse | any> => {
  return calculation(customerId, getQuoteId(), '*');
};

export const getQuote = async (status: 'CREATED' | 'OPENED'): Promise<QuoteResponse> => {
  return findByCustomerWithStatus(customerId, status, '*');
};

export const getQuotes = async (): Promise<QuoteResponse[]> => {
  const response: QuoteListResponse = await findByCustomerId(customerId, '*');
  return response?.quotes || [];
};

export const getProposals = async (): Promise<ProposalResponse> => {
  const response: ProposalResponse = await findCurrentProposal(customerId, '*');
  return response || {};
};

export const saveProposalLead = async (body: ProposalLeadRequest): Promise<SendProposalResponse> => {
  return sendProposalLead(customerId, body, '*');
};

export const saveVehicle = async (body: VehicleRequest): Promise<any> => {
  return saveVechicle(customerId, getQuoteId(), body, '*');
};

export const saveDriver = async (body: DriverRequest, id?: string): Promise<DriverResponse> => {
  if (id) {
    return updateDriver(customerId, getQuoteId(), id, body, '*');
  }

  return saveDriverUsingPost(customerId, getQuoteId(), body, '*');
};

export const saveQuote = async (body: QuoteRequest): Promise<QuoteResponse[]> => {
  return save(customerId, getQuoteId(), body, '*');
};

export const saveInsuranceHolder = async (body: InsuranceHolderRequest, id?: string): Promise<InsuranceHolderResponse> => {
  if (id) {
    return update2(customerId, getQuoteId(), id, body, '*');
  }
  return save5(customerId, getQuoteId(), body, '');
};

export const findAddress = async (cep: string): Promise<CepResponse> => {
  return CustomAPI.get<CepResponse>(`viacep/${cep}`)
    .then(response => Promise.resolve(response.data))
    .catch(reason => Promise.reject(handleAxiosException(reason.response)));
};

export const saveRiskAddress = async (body: RiskAddressRequest): Promise<RiskAddressResponse> => {
  return save1(customerId, getQuoteId(), body, '*');
};

export const getVehicleVLicense = async (licensePlate: string): Promise<VehicleResponse> => {
  return findVehicleByVLicense(customerId, licensePlate, '*');
};

export const saveDocument = async (body: DocumentHolderRequest, documentId?: string) => {
  if (documentId) {
    return update3(customerId, documentId, body, '*');
  }

  return save6(customerId, getInsuranceId(), body, '*');
};

export const getCoverages = async (insuranceCompany = '', licensePlate = ''): Promise<CoverageRiskItemListResponse> => {
  return findCoveragesRiskItem(customerId, licensePlate, insuranceCompany, '*');
};

export const getPartners = async (): Promise<PartnerListResponse> => {
  return findByPartnerId(customerId, '*');
};

export const saveAdditionalCoverages = async (body: CoverageListRequest): Promise<ResultQuoteListResponse> => {
  return saveCoverage(customerId, getQuoteId(), body, '*');
};

export const createContract = async (contractUuid: string): Promise<ResultQuoteDetailResponse> => {
  return save2(customerId, contractUuid, getQuoteId(), '*');
};

export const getContract = async (contractUuid: string): Promise<ResultQuoteDetailResponse> => {
  return findByUuid2(customerId, contractUuid, '*');
};

export const getPayments = async (contractUuid: string): Promise<PaymentOptionListResponse> => {
  return findByPaymentOption(customerId, contractUuid, '*');
};

export const getPrincipalCreditCard = async (): Promise<CreditCard> => {
  return CustomAPI.get<CreditCard>('/credit_card/principal')
    .then(response => Promise.resolve(response.data))
    .catch(reason => Promise.reject(handleAxiosException(reason.response)));
};

export const getBalance = async (): Promise<Balance> => {
  return CustomAPI.get<Balance>('/balance')
    .then(response => Promise.resolve(response.data))
    .catch(reason => Promise.reject(handleAxiosException(reason.response)));
};

export const getListCreditCards = async (): Promise<ListCreditCard> =>
  CustomAPI.get<ListCreditCard>('/credit_card')
    .then(response => Promise.resolve(response.data))
    .catch(reason => Promise.reject(handleAxiosException(reason.response)));

export const getCreditCard = async (cardId: string): Promise<CreditCard> =>
  CustomAPI.get<CreditCard>(`/credit_card/${cardId}`)
    .then(response => Promise.resolve(response.data))
    .catch(reason => Promise.reject(handleAxiosException(reason.response)));

export const saveCheckup = async (body: PaymentInstrumentRequest, id?: string): Promise<PaymentInstrumentResponse> => {
  if (id) return update1(customerId, getContractId(), body, '*');

  return save4(customerId, getContractId(), body, '*');
};
