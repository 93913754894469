import { store } from '../store';
import { changeUserAction } from '../actions';
import { isDevEnv, isLocalMachine } from './environment';

export const getCookie = (key: 'x-authorization' | 'customer-id'): string => {
  const b = document.cookie.match(`(^|;)\\s*${key}\\s*=\\s*([^;]+)`);
  return b ? b.pop() || '' : '';
};

export const getAuthCookies = () => {
  const isDevelopment: boolean = isDevEnv();
  const xAuthorization = getCookie('x-authorization');
  let customerId = getCookie('customer-id');
  if (!customerId && isDevelopment) customerId = process.env.REACT_APP_API_CUSTOMERID_MOCK_CYGNUS as string;
  store.dispatch(changeUserAction({ xAuthorization, customerId }));
  return {
    'x-authorization': xAuthorization,
    'x-customer-uuid': customerId,
    'x-verify-auth': `${isDevelopment || isLocalMachine()}`,
  };
};
