/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { request as __request } from '../core/request';

export class PartnerReadControllerService {

    /**
     * @param xCustomerUuid
     * @param acceptLanguage
     * @returns any OK
     * @throws ApiError
     */
    public static async findByPartnerId(
        xCustomerUuid: string,
        acceptLanguage?: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/insurance/partner/contact`,
            headers: {
                'x-customer-uuid': xCustomerUuid,
                'Accept-Language': acceptLanguage,
            },
        });
        return result.body;
    }

}