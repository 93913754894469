import { DomainTypeEnum, ErrorApi, getDomain, handleException } from '../api';
import { Domain } from '../models';
import { sortArrayWithNumbers } from './arrayOperations';

type GetListProps = {
  listDomainSettable: Domain[];
  setError: (value: ErrorApi | undefined) => void;
  setListDomain: (value: Domain[]) => void;
  setLoading: (value: boolean) => void;
  type:
    | 'armored'
    | 'coberturas'
    | 'commission'
    | 'gender'
    | 'incomeTier'
    | 'insuranceCompany'
    | 'issuingAgency'
    | 'maritalStatus'
    | 'profession'
    | 'relationHolder'
    | 'taxExemptionType';
  comparative?: string;
  hasCapitalize?: boolean;
  needToSortNumber?: boolean;
  setValueDomain?: (value: Domain) => void;
};

const replacedText = (value?: string) => {
  if (!value) return '';

  return value.replaceAll(' Da ', ' da ').replaceAll(' De ', ' de ').replaceAll(' Do ', ' do ').replaceAll(' E ', ' e ');
};

export const getListDomain = (props: GetListProps) => {
  const { listDomainSettable } = props;
  if (listDomainSettable?.length > 0) return;

  const {
    setError,
    setListDomain,
    setLoading,
    type,
    comparative,
    hasCapitalize = false,
    needToSortNumber = false,
    setValueDomain,
  } = props;

  const KEY = {
    armored: DomainTypeEnum.ARMORED,
    coberturas: DomainTypeEnum.COBERTURAS,
    commission: DomainTypeEnum.COMMISSION,
    gender: DomainTypeEnum.GENDER,
    incomeTier: DomainTypeEnum.INCOME_TIER,
    insuranceCompany: DomainTypeEnum.INSURANCE_COMPANY,
    issuingAgency: DomainTypeEnum.ISSUING_AGENCY,
    maritalStatus: DomainTypeEnum.MARITAL_STATUS,
    profession: DomainTypeEnum.PROFESSION,
    relationHolder: DomainTypeEnum.RELATION_HOLDER,
    taxExemptionType: DomainTypeEnum.TAX_EXEMPTION_TYPE,
  };

  setLoading(true);
  getDomain(KEY[type])
    .then(response => {
      const capitalizeResponse = response.map(({ id, name, disabled }) => ({
        id,
        name: !hasCapitalize ? replacedText(name) : name?.toUpperCase(),
        disabled,
      })) as Domain[];

      const sortedResponse = !needToSortNumber
        ? capitalizeResponse
        : (sortArrayWithNumbers(capitalizeResponse, 'name', 7) as Domain[]);

      setListDomain(sortedResponse);

      if (comparative && setValueDomain) {
        const newValue = sortedResponse.find(({ id }) => id === comparative);
        if (!newValue) return;
        setValueDomain(newValue);
      }
    })
    .catch(reason => setError(handleException(reason)))
    .finally(() => setLoading(false));
};

export const findInsuredHimself = (searchList: Domain[]) =>
  searchList.find(({ name }) => name?.toUpperCase() === 'O PRÓPRIO SEGURADO') || ({ id: '919' } as Domain);
