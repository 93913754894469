import styled, { keyframes } from 'styled-components/macro';
import { Button } from 'react-bootstrap';

import { pxToRem } from '../../utils';

const animate = keyframes`
  0% {
    width: 0px;
    height: 0px;
    opacity: 0.5;
  }
  100% {
    width: ${pxToRem(500)};
    height: ${pxToRem(500)};
    opacity: 0;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueLight};
  padding: ${pxToRem(24)} ${pxToRem(32)};

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  div:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      margin-right: ${pxToRem(16)};
      background-color: ${({ theme }) => theme.colors.greyBackground};
      border: 1px solid ${({ theme }) => theme.colors.greyBackground};
      border-radius: ${pxToRem(8)};

      img {
        width: ${pxToRem(40)};
        height: ${pxToRem(40)};
      }
    }
  }
`;

export const QuestionIcon = styled.p`
  font-family: Nunito;
  font-weight: 700;
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(22)};
  color: ${({ theme }) => theme.colors.blueTypo};
  opacity: 1;
  mix-blend-mode: normal;
  margin-bottom: ${pxToRem(5)};
`;

export const AnswerIcon = styled.p`
  font-weight: 400;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(19)};
  color: ${({ theme }) => theme.colors.blue};
  opacity: 1;
`;

export const ButtonInputCover = styled(Button).attrs({
  bsPrefix: 'semBtn',
})`
  font-family: Nunito;
  font-weight: 700;
  font-style: normal;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(19)};

  color: ${({ theme }) => theme.colors.blueStrong};
  background: white;

  min-width: ${pxToRem(87)};
  height: ${pxToRem(40)};
  border: 2px solid ${({ theme }) => theme.colors.blueLight};
  box-sizing: border-box;
  border-radius: ${pxToRem(16)};
  overflow: hidden;
  position: relative;

  span {
    position: absolute;
    background-color: #000;
    transform: translate(-50%, -50%);
    pointer-events: none;
    border-radius: 50%;
    animation: ${animate} 1s linear infinite;
  }
`;
