import dayjs from 'dayjs';

type FormatPhone = {
  phone?: string | number;
  typePhone: 'cell' | 'other';
  hideMiddleNumbers: boolean;
};

const months = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

const addZero = (value: number) => (value <= 9 ? `0${value}` : value);

export const formatNumber = {
  cpf: (value: string | number | undefined, hideMiddleNumbers?: boolean): string => {
    if (!value) return '';
    let formatted = String(value).replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');

    if (hideMiddleNumbers) {
      formatted = `${formatted.substring(0, 4)}***.***${formatted.substring(11)}`;
    }

    return formatted;
  },
  rg: (value: string | number | undefined): string => {
    if (!value) return '';

    const formatted = String(value)
      .replace(/(\d{2})(\d{3})(\d{3})([0-9A-Za-z]{0,1})/, '$1.$2.$3-$4')
      .toUpperCase();
    return formatted;
  },
  currency: (value: string | number | undefined, onlyThousands?: boolean): string => {
    if (!value && value !== 0) return '';

    if (onlyThousands) {
      const currencyThousands = +value / 1000;
      return `R$ ${currencyThousands > 1 && currencyThousands} mil`;
    }

    if (Number.isNaN(value)) return '';

    const formatted = Number(value).toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
    return String(formatted);
  },
  date: (value: Date | undefined) => {
    if (!value) return '';
    return dayjs(value).format('DD/MM/YYYY');
  },
  dateInFull: (value: string | Date | undefined) => {
    if (!value) return '';
    const data = new Date(value);
    const day = addZero(data.getDate());
    const month = months[data.getMonth()];
    const year = data.getFullYear();

    return `${day} de ${month} ${year}`;
  },
  percent: (value = 0, numberFixed = 0) => {
    const regex = /\./g;
    const subs = ',';
    if (numberFixed) {
      return `${(value * 100).toFixed(numberFixed).replace(regex, subs)}%`;
    }

    return `${(value * 100).toFixed(2).replace(regex, subs).replace(',00', '')}%`;
  },
  phone: ({ phone, typePhone, hideMiddleNumbers = false }: FormatPhone): string => {
    if (!phone) return '';

    const isCellphone = typePhone === 'cell';
    if (hideMiddleNumbers) {
      const regex = isCellphone ? /(\d{2})(\d{1})(\d{4})(\d{4})/ : /(\d{2})(\d{1})(\d{3})(\d{4})/;
      return String(phone).replace(regex, '($1) $2***-$4');
    }

    const regex = isCellphone ? /(\d{2})(\d{5})(\d{4})/ : /(\d{2})(\d{4})(\d{4})/;
    return String(phone).replace(regex, '($1) $2-$3');
  },
  roundDownHundred: (value = 0) => {
    return Math.floor(value / 100) * 100;
  },
  zip: (value?: string | number) => {
    if (!value) return '';

    const prepareValue = String(value).replace(/\D+/g, '').padStart(8, '0');
    const formattedZip = prepareValue.replace(/^(\d{5})(\d{3})/, '$1-$2');
    return formattedZip;
  },
};
