import { Domain } from '../../models/domain';

export const HasInsuranceOptions: Domain[] = [
  { name: 'Sim, seguro completo', id: '1' },
  { name: 'Sim, mas apenas contra terceiro', id: '2' },
  { name: 'Sim, mas apenas contra colisão', id: '3' },
  { name: 'Não, eu uso rastreador ', id: '4' },
  { name: 'Não tenho seguro', id: '5' },
];

export const WhyNotHasInsuranceOptions: Domain[] = [
  { name: 'Não tenho carro', id: '1' },
  { name: 'Tenho moto e não tenho carro', id: '2' },
  { name: 'Não vale a pena contratar seguro', id: '3' },
  { name: 'Contrato produto com rastreador', id: '4' },
  {
    name: 'Contrato assistência auto', // #_(guincho, troca de pneu e pane seca)
    id: '5',
  },
];
