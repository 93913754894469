import styled from 'styled-components/macro';

import { pxToRem } from '../../utils';

export const ContainerAccordion = styled.div<{ show?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueStrong}1A;
  padding: ${pxToRem(24)} ${pxToRem(32)} ${({ show }) => show && pxToRem(8)};
`;

export const ContentTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.span`
  font-family: Nunito;
  font-weight: 700;
  font-style: normal;
  font-size: ${pxToRem(18)};
  line-height: ${pxToRem(25)};
  color: ${({ theme }) => theme.colors.blueStrong};
`;

export const ContentValues = styled(ContentTitle)`
  height: ${pxToRem(45)};
`;

export const Text = styled(Title)`
  width: ${pxToRem(160)};

  font-weight: 400;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(19)};
  color: ${({ theme }) => theme.colors.blueTypo};
`;

export const TextTotal = styled(Title)`
  font-weight: 700;
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(22)};
  color: ${({ theme }) => theme.colors.blueTypo};
`;

export const Value = styled(Text)<{ deduction?: boolean }>`
  width: calc(100% - ${pxToRem(160)});

  font-weight: 600;
  text-align: right;
  ${({ theme, deduction }) => deduction && `color: ${theme.colors.success}`}
`;

export const ValueTotal = styled(Value)`
  font-weight: 700;
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(22)};
`;
