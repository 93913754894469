import { memo } from 'react';
import { useHistory } from 'react-router-dom';

import { returnToNative } from '../../utils/nativeBridge';

import { CloseIcon, ContentIcon } from './styles';

interface CloseProps {
  onClose?: () => void;
  overrideFn?: () => void;
  backgroundBlue?: boolean;
  url?: string;
  option?: string;
}

const Close = ({ backgroundBlue = false, overrideFn, url = '', option = '', onClose }: CloseProps) => {
  const history = useHistory();

  const returnTo = () => {
    if (overrideFn) {
      overrideFn();
      return;
    }
    if (onClose) onClose();
    returnToNative();
    history.push(url ? `${url}` : '/', { pushTo: url, option });
  };

  return (
    <ContentIcon onClick={returnTo} aria-label="Close button icon" backgroundBlue={backgroundBlue}>
      <CloseIcon />
    </ContentIcon>
  );
};

export default memo(Close);
