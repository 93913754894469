import styled from 'styled-components/macro';

import { pxToRem } from '../../utils';

export const Container = styled.div<{ borderBottom?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${p => p.borderBottom && `border-bottom: 1px solid #ccd2e0;`}
  padding: ${pxToRem(20)} ${pxToRem(32)};
`;

export const Title = styled.span`
  font-family: Nunito;
  font-weight: 700;
  font-style: normal;
  font-size: ${pxToRem(18)};
  line-height: ${pxToRem(25)};
  color: ${({ theme }) => theme.colors.blueStrong};
`;
