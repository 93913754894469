import styled from 'styled-components/macro';
import { Fab } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Close from '@material-ui/icons/Close';

import { ReactComponent as WalletSVG } from '../../assets/icons/insurance/payment/wallet.svg';
import { pxToRem } from '../../utils';

export const Container = styled.div<{
  alignToRight?: boolean;
  stepper?: 'yellow' | 'top' | 'none';
  colorbackground: 'blueTypo' | 'white';
}>`
  background: ${({ theme, colorbackground }) => (colorbackground === 'blueTypo' ? 'transparent' : theme.colors.white)};
  width: 100%;
  height: ${({ stepper }) => (stepper === 'top' ? pxToRem(106) : pxToRem(110))};
  position: sticky;
  top: 0;
  padding: 0 ${pxToRem(32)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  ${p => p.alignToRight && `flex-direction: row-reverse;`};
`;

export const FabButton = styled(Fab)<{ colorbackground: 'blueTypo' | 'white' }>`
  height: ${pxToRem(48)} !important;
  width: ${pxToRem(48)} !important;
  background: ${({ theme, colorbackground }) =>
    colorbackground === 'white' ? theme.colors.blueLight : theme.colors.white10percent} !important;
  box-shadow: none !important;
`;

export const ArrowBackIcon = styled(ArrowBack)<{ colorbackground: 'blueTypo' | 'white' }>`
  path {
    fill: ${({ theme, colorbackground }) => (colorbackground === 'white' ? theme.colors.blueStrong : theme.colors.white)};
  }
`;

export const CloseIcon = styled(Close)`
  color: ${({ theme }) => theme.colors.blueStrong};
`;

export const Text = styled.p`
  font-family: Nunito;
  font-weight: 700;
  font-style: normal;
  font-size: ${pxToRem(14)};
  color: ${({ theme }) => theme.colors.blueTypo};
  margin-bottom: ${pxToRem(8)};
`;

export const ContentBrand = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Brand = styled.img<{ question?: boolean }>`
  max-width: ${pxToRem(151)};
  height: ${({ question }) => pxToRem(question ? 24 : 36)};
  cursor: ${({ question }) => question && 'pointer'};
`;

export const HelpBrand = styled(Text)`
  margin-bottom: 0;
  margin-left: ${pxToRem(10)};

  line-height: ${pxToRem(19)};
  text-align: right;
  color: ${({ theme }) => theme.colors.blueStrong};
`;

export const Wallet = styled(WalletSVG).attrs({
  width: pxToRem(16),
  height: pxToRem(16),
})<{ colorbackground: 'blueTypo' | 'white' }>`
  path {
    fill: ${({ theme, colorbackground }) =>
      colorbackground === 'white' ? theme.colors.tokenColorBlue200 : theme.colors.blueGrayLight};
  }
`;

export const WalletText = styled(Text)<{ colorbackground: 'blueTypo' | 'white' }>`
  font-weight: 600;
  line-height: ${pxToRem(19)};
  color: ${({ theme, colorbackground }) =>
    colorbackground === 'white' ? theme.colors.tokenColorBlue200 : theme.colors.blueGrayLight};
  margin-left: ${pxToRem(8)};
  margin-bottom: 0;

  strong {
    font-weight: 700;
  }
`;
