import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { MouseEvent } from 'react';
import { ButtonLink, Container, ContainerWithInfo, DescriptionLine, DescriptionLineAlert, Label } from './styles';
import theme from '../../styles/theme';
import InfoPanel from '../InfoPanel';

type Props = {
  label: string;
  description: string[];
  secondaryColor?: boolean;
  action: () => void;
  buttonLinkLabel?: string;
  icon?: string;
  noPaddingX?: boolean;
  className?: string;
  iconWhite?: boolean;
  children?: JSX.Element | JSX.Element[];
  actionInfoPanel?: () => void;
};

const WhiteButtonDescription = ({
  label,
  description,
  secondaryColor = false,
  action,
  buttonLinkLabel,
  icon,
  noPaddingX = false,
  className,
  iconWhite = false,
  children,
  actionInfoPanel,
}: Props): JSX.Element => {
  const handleClick = (e: MouseEvent) => {
    const ripples = document.createElement('span');
    const buttonPosition = e.currentTarget.getBoundingClientRect();
    // const buttonPosition = e.target.getBoundingClientRect();
    const top = Math.abs(buttonPosition.top - e.clientY);
    const left = Math.abs(buttonPosition.left - e.clientX);

    ripples.style.top = `${top}px`;
    ripples.style.left = `${left}px`;

    e.currentTarget.appendChild(ripples);
    // e.target.appendChild(ripples);

    setTimeout(() => {
      ripples.remove();

      return action();
    }, 300);
  };

  const lines = description.map((line, index) => {
    if (line.includes('Parcela ')) {
      return (
        <DescriptionLineAlert key={`line-${String(index)}-${line.substring(0, 5)}`} icon={!!icon}>
          {line}
        </DescriptionLineAlert>
      );
    }

    return (
      <DescriptionLine key={`line-${String(index)}-${line.substring(0, 5)}`} icon={!!icon} className={className}>
        {line}
      </DescriptionLine>
    );
  });

  if (actionInfoPanel) {
    return (
      <ContainerWithInfo>
        <Container aria-hidden="true" onClick={handleClick} icon={!!icon} noPaddingX={noPaddingX} className={className}>
          {icon && <img className="imgBackgroud" src={icon} alt="carregando..." loading="lazy" />}
          <div>
            <Label secondaryColor={secondaryColor || !!icon} className={className}>
              {label}
            </Label>
            {lines}
            {buttonLinkLabel && <ButtonLink>{buttonLinkLabel}</ButtonLink>}
            {children}
          </div>
          <KeyboardArrowRightIcon style={{ color: iconWhite ? theme.colors.white : theme.colors.blueTypo }} />
        </Container>
        <InfoPanel content="Atualizar dados da proposta" type="UPDATE" onClick={actionInfoPanel} />
      </ContainerWithInfo>
    );
  }

  return (
    <Container aria-hidden="true" onClick={handleClick} icon={!!icon} noPaddingX={noPaddingX} className={className}>
      {icon && <img className="imgBackgroud" src={icon} alt="carregando..." loading="lazy" />}
      <div>
        <Label secondaryColor={secondaryColor || !!icon} className={className}>
          {label}
        </Label>
        {lines}
        {buttonLinkLabel && <ButtonLink>{buttonLinkLabel}</ButtonLink>}
        {children}
      </div>
      <KeyboardArrowRightIcon style={{ color: iconWhite ? theme.colors.white : theme.colors.blueTypo }} />
    </Container>
  );
};

export default WhiteButtonDescription;
