/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useState } from 'react';
import { BubbleButton } from '../styles';
import { Domain } from '../../models';

interface Props {
  items: Domain[];
  action: (newValue) => void;
  value?: string;
  defaultValue?: string;
}

const BubbleButtonChoice = ({ items, action, value, defaultValue }: Props) => {
  const [inputValue, setInputValue] = useState(value);

  const handleChange = useCallback(
    event => {
      const [id, name] = event.target.id.split('#_');

      if (inputValue === name) {
        setInputValue(defaultValue || '');
        if (defaultValue) {
          const actionValue = items.find(item => item.name === defaultValue) || ({} as Domain);
          return action(actionValue);
        }
        return action({
          id: '',
          name: '',
        });
      }

      setInputValue(name);

      return action({
        id,
        name,
      });
    },
    [inputValue, defaultValue],
  );

  return (
    <>
      {items.map(({ id, name }) => (
        <BubbleButton
          className="choiceButton"
          key={id}
          id={`${id}#_${name}`}
          onClick={handleChange}
          selected={inputValue === name}
        >
          {name}
        </BubbleButton>
      ))}
    </>
  );
};

export default BubbleButtonChoice;
