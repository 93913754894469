import styled from 'styled-components/macro';

import { pxToRem } from '../../utils';

export const Info = styled.p<{ bold?: boolean; grey?: boolean; align?: string }>`
  font-family: Nunito;
  font-size: ${({ theme }) => theme.sizes.size16};
  font-weight: ${p => (p.bold ? '700' : '400')};
  line-height: ${({ theme }) => theme.sizes.size24};
  color: ${({ theme, grey }) => (grey ? theme.colors.tokenColorBlue200 : theme.colors.blueTypo)};
  text-align: ${p => (p.align ? p.align : 'unset')};
`;

export const InfoInvert = styled.p`
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  font-size: ${pxToRem(14)};
  line-height: 140%;
  color: ${({ theme }) => theme.colors.blueTypo};

  &.sm {
    font-weight: 400;

    span {
      font-size: ${pxToRem(12)};
    }
  }
`;

export const Line = styled.div<{ minimalHeight?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${p => (p.minimalHeight ? pxToRem(3) : pxToRem(12))} 0;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.text-overflow {
    gap: ${pxToRem(8)};
    div {
      overflow: hidden;
      max-width: calc(100%);
    }
    div p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    div + p {
      min-width: max-content;
      text-align: right;
    }
  }

  &.detail-proposal {
    p {
      font-size: ${pxToRem(14)};
      line-height: 140%;
    }

    div + p {
      text-align: right;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${pxToRem(8)} 0;
`;

export const Icon = styled.img.attrs({
  loading: 'lazy',
})`
  width: ${pxToRem(16)};
  height: ${pxToRem(16)};
  margin-left: ${pxToRem(4)};
`;
