import Modal from '../index';

interface Props {
  open: boolean;
  children: JSX.Element | JSX.Element[];
  onClose: () => void;
  onClick?: () => void;
  primaryButton?: string;
  secondButton?: string;
  secondaryClick?: () => void;
  overlay?: () => void;
}

const BasicModal = ({
  open,
  onClick,
  onClose,
  children,
  primaryButton,
  secondButton,
  secondaryClick,
  overlay,
}: Props): JSX.Element => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      buttonDisabled={false}
      onClick={onClick}
      confirmLabel={primaryButton}
      closeLabel={secondButton}
      oneButton={!secondButton}
      twoButtons={!!secondButton}
      secondaryClick={secondaryClick}
      overlay={overlay}
    >
      <>{children}</>
    </Modal>
  );
};

export default BasicModal;
