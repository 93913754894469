/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CollectionTicketRequest } from '../models/CollectionTicketRequest';
import type { ColletcionTicketGenerateResponse } from '../models/ColletcionTicketGenerateResponse';
import type { ListColletcionTicketAntecipationCalcResponse } from '../models/ListColletcionTicketAntecipationCalcResponse';
import { request as __request } from '../core/request';

export class CollectionTicketControllerService {

    /**
     * anticipationCalc
     * @param product product
     * @param request request
     * @param xCustomerUuid x-customer-uuid
     * @param acceptLanguage Accept-Language
     * @returns ListColletcionTicketAntecipationCalcResponse OK
     * @returns any Created
     * @throws ApiError
     */
    public static async anticipationCalcUsingPost(
        product: string,
        request: CollectionTicketRequest,
        xCustomerUuid: string,
        acceptLanguage?: string,
    ): Promise<ListColletcionTicketAntecipationCalcResponse | any> {
        const result = await __request({
            method: 'POST',
            path: `/api/personal_loans/collection_ticket/anticipation/product/${product}`,
            headers: {
                'x-customer-uuid': xCustomerUuid,
                'Accept-Language': acceptLanguage,
            },
            body: request,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * calcFeesMoraInstalmment
     * @param product product
     * @param request request
     * @param xCustomerUuid x-customer-uuid
     * @param acceptLanguage Accept-Language
     * @returns ColletcionTicketGenerateResponse OK
     * @returns any Created
     * @throws ApiError
     */
    public static async calcFeesMoraInstalmmentUsingPost(
        product: string,
        request: CollectionTicketRequest,
        xCustomerUuid: string,
        acceptLanguage?: string,
    ): Promise<ColletcionTicketGenerateResponse | any> {
        const result = await __request({
            method: 'POST',
            path: `/api/personal_loans/collection_ticket/calc_fees/product/${product}`,
            headers: {
                'x-customer-uuid': xCustomerUuid,
                'Accept-Language': acceptLanguage,
            },
            body: request,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * colletcionTicketGenerate
     * @param product product
     * @param request request
     * @param xCustomerUuid x-customer-uuid
     * @param acceptLanguage Accept-Language
     * @returns ColletcionTicketGenerateResponse OK
     * @returns any Created
     * @throws ApiError
     */
    public static async colletcionTicketGenerateUsingPost(
        product: string,
        request: CollectionTicketRequest,
        xCustomerUuid: string,
        acceptLanguage?: string,
    ): Promise<ColletcionTicketGenerateResponse | any> {
        const result = await __request({
            method: 'POST',
            path: `/api/personal_loans/collection_ticket/generate/product/${product}`,
            headers: {
                'x-customer-uuid': xCustomerUuid,
                'Accept-Language': acceptLanguage,
            },
            body: request,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

}