/* eslint-disable no-restricted-syntax */
export const pxToRem = (px: number, oneRemPx = 16): string => `${px / oneRemPx}rem`;

export const ptToPx = (pt: number) => (pt * 4) / 3;

export const convertPtOrPxToRem = (text: string, type: 'pt' | 'px') => {
  const regex = new RegExp(`(\\d+.\\d+${type}|\\d+${type})`, 'gim'); // /(\d+.\d+px|\d+px)/gim
  let newText = '';

  const list = text.match(regex) || [];
  const listValues = [...new Set(list)];
  const listObj = listValues.map(item => {
    const removedPT = Number(item.substring(0, item.length - 2));
    const convertToPx = type === 'px' ? removedPT : ptToPx(removedPT);
    return { oldValue: item, newValue: pxToRem(convertToPx) };
  });

  for (const iterator of listObj) {
    if (newText) {
      newText = newText.replaceAll(iterator.oldValue, iterator.newValue);
    } else {
      newText = text.replaceAll(iterator.oldValue, iterator.newValue);
    }
  }

  return newText;
};
