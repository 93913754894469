import { memo, useEffect, useState } from 'react';
import { LinearProgress } from '@material-ui/core';

import { useHistory } from 'react-router-dom';
import { LoadingProps } from '../../data';
import { normalize } from '../../utils';

import Close from '../Close';
import PortalModal from '../Modal/Portal';
import { Overlay } from '../Modal/styles';
import { FormTitle } from '../styles';
import { Bottom, Container, Content, IconSvg } from './styles';

const LoadingObj = ({ props, totalProgress }: LoadingProps) => {
  const history = useHistory();
  const [counter, setCounter] = useState(0);
  const [progress, setProgress] = useState(0);

  const maximumValue = props.length;
  const timerProgress = (totalProgress / maximumValue) * 1000;

  useEffect(() => {
    let counterTimeout: NodeJS.Timeout | null = setTimeout(
      () => setCounter(prev => (counter >= maximumValue - 1 ? 0 : prev + 1)),
      timerProgress,
    );

    if (progress === timerProgress) {
      clearTimeout(counterTimeout);
      counterTimeout = null;
    }

    return () => {
      if (counterTimeout) clearTimeout(counterTimeout);
      counterTimeout = null;
    };
  }, [counter]);

  useEffect(() => {
    let progressTimeout: NodeJS.Timeout | null = setTimeout(
      () => setProgress(prev => (progress >= totalProgress ? 0 : prev + 1)),
      1000,
    );

    if (progress === timerProgress) {
      clearTimeout(progressTimeout);
      progressTimeout = null;
    }

    return () => {
      if (progressTimeout) clearTimeout(progressTimeout);
      progressTimeout = null;
    };
  }, [progress]);

  useEffect(() => {
    let isSubscribed = true;
    if (!isSubscribed) console.log('oi');
    return () => {
      isSubscribed = false;
    };
  }, []);

  return (
    <PortalModal>
      <Overlay style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Container>
          <Close overrideFn={() => history.push('/')} />
          <Content>
            <div>
              <IconSvg alt={`ícone referente à frase "${props[counter].text}"`} src={props[counter].icon} loading="lazy" />
            </div>
            <FormTitle className="white">{props[counter].text}</FormTitle>
          </Content>
          <Bottom>
            <LinearProgress
              style={{ borderRadius: '100px' }}
              variant="determinate"
              color="secondary"
              value={normalize(progress, totalProgress)}
            />
          </Bottom>
        </Container>
      </Overlay>
    </PortalModal>
  );
};

export default memo(LoadingObj);
