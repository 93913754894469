/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { request as __request } from '../core/request';

export class ResultQuoteControllerService {

    /**
     * @param xCustomerUuid
     * @param quoteUuid
     * @param resultQuoteId
     * @param paymentOptionId
     * @param acceptLanguage
     * @returns any OK
     * @throws ApiError
     */
    public static async savePaymentOption(
        xCustomerUuid: string,
        quoteUuid: string,
        resultQuoteId: string,
        paymentOptionId: string,
        acceptLanguage?: string,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/insurance/result_quote/save/${resultQuoteId}/payment_option/${paymentOptionId}`,
            headers: {
                'x-customer-uuid': xCustomerUuid,
                'quote-uuid': quoteUuid,
                'Accept-Language': acceptLanguage,
            },
        });
        return result.body;
    }

    /**
     * @param xCustomerUuid
     * @param contractUuid
     * @param quoteId
     * @param acceptLanguage
     * @returns any OK
     * @throws ApiError
     */
    public static async save2(
        xCustomerUuid: string,
        contractUuid: string,
        quoteId: string,
        acceptLanguage?: string,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/insurance/create/contract_quote/${contractUuid}/quote/${quoteId}`,
            headers: {
                'x-customer-uuid': xCustomerUuid,
                'Accept-Language': acceptLanguage,
            },
        });
        return result.body;
    }

}