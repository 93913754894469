/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { request as __request } from '../core/request';

export class DocumentHolderControllerService {

    /**
     * @param xCustomerUuid
     * @param documentId
     * @param requestBody
     * @param acceptLanguage
     * @returns any OK
     * @throws ApiError
     */
    public static async update3(
        xCustomerUuid: string,
        documentId: string,
        requestBody: any,
        acceptLanguage?: string,
    ): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/insurance/holder/document/${documentId}`,
            headers: {
                'x-customer-uuid': xCustomerUuid,
                'Accept-Language': acceptLanguage,
            },
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param xCustomerUuid
     * @param documentId
     * @param acceptLanguage
     * @returns any OK
     * @throws ApiError
     */
    public static async delete2(
        xCustomerUuid: string,
        documentId: string,
        acceptLanguage?: string,
    ): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/insurance/holder/document/${documentId}`,
            headers: {
                'x-customer-uuid': xCustomerUuid,
                'Accept-Language': acceptLanguage,
            },
        });
        return result.body;
    }

    /**
     * @param xCustomerUuid
     * @param holderId
     * @param requestBody
     * @param acceptLanguage
     * @returns any OK
     * @throws ApiError
     */
    public static async saveList(
        xCustomerUuid: string,
        holderId: string,
        requestBody: any,
        acceptLanguage?: string,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/insurance/document/list/holder/${holderId}`,
            headers: {
                'x-customer-uuid': xCustomerUuid,
                'Accept-Language': acceptLanguage,
            },
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param xCustomerUuid
     * @param holderId
     * @param requestBody
     * @param acceptLanguage
     * @returns any OK
     * @throws ApiError
     */
    public static async save6(
        xCustomerUuid: string,
        holderId: string,
        requestBody: any,
        acceptLanguage?: string,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/insurance/create/document/holder/${holderId}`,
            headers: {
                'x-customer-uuid': xCustomerUuid,
                'Accept-Language': acceptLanguage,
            },
            body: requestBody,
        });
        return result.body;
    }

}