import { parser } from '../../utils';
import { Icon, Info, InfoInvert, Line, Wrapper } from './styles';

type SimpleLineItem = {
  label: string;
  value?: string | number;
  valueAlign?: string;
  icon?: string;
  iconName?: string;
};

type DoubleLineItem = {
  labelLeft?: string;
  valueLeft?: string | number;
  labelRight?: string;
  valueRight?: string | number;
  icon?: string;
  iconName?: string;
};

type InfoLineProps = {
  simpleLines?: SimpleLineItem[];
  doubleLines?: DoubleLineItem[];
  iconClick?: (string) => void;
  className?: string;
  invert?: boolean;
};

const InfoLine = ({ simpleLines, doubleLines, iconClick, className, invert = false }: InfoLineProps) => {
  const renderSimpleLines = () => {
    if (!simpleLines) return null;

    return simpleLines?.map((line, indexLine) => (
      <Line key={String(`${indexLine}-${Date.now()}`)} className={className}>
        <div>
          <Info>{line.label}</Info>
          {line.icon && (
            <Icon src={line.icon} alt={line.label} onClick={iconClick ? () => iconClick(line.iconName) : undefined} />
          )}
        </div>
        <Info bold align={line.valueAlign}>
          {line.value}
        </Info>
      </Line>
    ));
  };

  const renderDoubleLines = () => {
    if (!doubleLines) return null;

    if (invert) {
      return doubleLines?.map((line, indexLine) => (
        <Wrapper key={String(`${indexLine}-${Date.now()}`)}>
          <Line minimalHeight className={className}>
            <div>
              <Info>{line.labelLeft}</Info>
              {line.icon && (
                <Icon src={line.icon} alt={line.labelLeft} onClick={iconClick ? () => iconClick(line.iconName) : undefined} />
              )}
            </div>
            <InfoInvert>{parser(String(line.labelRight))}</InfoInvert>
          </Line>
          <Line minimalHeight className={className}>
            <Info>{parser(String(line.valueLeft || ''))}</Info>
            <InfoInvert className="sm">{parser(String(line.valueRight || ''))}</InfoInvert>
          </Line>
        </Wrapper>
      ));
    }

    return doubleLines?.map((line, indexLine) => (
      <Wrapper key={String(`${indexLine}-${Date.now()}`)}>
        <Line minimalHeight className={className}>
          <div>
            <Info>{line.labelLeft}</Info>
            {line.icon && (
              <Icon src={line.icon} alt={line.labelLeft} onClick={iconClick ? () => iconClick(line.iconName) : undefined} />
            )}
          </div>
          <Info grey={indexLine === 0}>{line.labelRight}</Info>
        </Line>
        <Line minimalHeight className={className}>
          <Info bold>{parser(String(line.valueLeft || ''))}</Info>
          <Info>{line.valueRight}</Info>
        </Line>
      </Wrapper>
    ));
  };

  if (invert) {
    return (
      <>
        {renderDoubleLines()}
        {renderSimpleLines()}
      </>
    );
  }

  return (
    <>
      {renderSimpleLines()}
      {renderDoubleLines()}
    </>
  );
};

export default InfoLine;
