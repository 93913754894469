/* eslint-disable no-param-reassign */
import axios from 'axios';
import store from '../store/store';
import { isDevEnv, isLocalMachine } from '../utils';

const CustomAPI = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL as string,
});

CustomAPI.interceptors.request.use(async config => {
  config.headers['x-verify-auth'] = isDevEnv() || isLocalMachine();
  config.headers['X-Customer-Uuid'] = store?.getState()?.user?.customerId as string;
  config.headers['x-authorization'] = store?.getState()?.user?.xAuthorization as string;
  return config;
});

CustomAPI.interceptors.response.use(undefined, error => {
  if (error.message === 'Network Error' && !error.response) {
    console.error('Network error. ', error);
  }

  const { status } = error.response;
  if (status === 500) {
    console.error('Server error. ', error);
  }
  return Promise.reject(error);
});

export default CustomAPI;
