import styled from 'styled-components/macro';
import { Checkbox } from '@material-ui/core';
import { pxToRem } from '../../utils';

export const Container = styled.button`
  width: 104%;
  display: flex;
  text-align: left;
  margin-left: -16px;
  border: none;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const Label = styled.p`
  font-family: Nunito;
  font-size: ${({ theme }) => theme.sizes.size16};
  font-style: normal;
  font-weight: 600;
  text-align: left;
  color: ${({ theme }) => theme.colors.blueStrong};
  margin-top: revert;
  margin-left: 0;
  margin-bottom: ${pxToRem(8)};
`;

export const CustomCheckbox = styled(Checkbox)`
  top: ${pxToRem(6)};
  width: ${pxToRem(40)};
`;

export const Link = styled.a`
  font-family: Nunito;
  font-size: ${({ theme }) => theme.sizes.size16};
  font-style: normal;
  font-weight: 600;
  text-align: left;
  color: ${({ theme }) => theme.colors.blueStrong};
  margin-top: revert;
  margin-left: 0;
`;
